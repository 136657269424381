import { useNavigate, useParams } from 'react-router-dom';
import "user/register/component/Success.css";
import { useMember } from 'user/register/RegisterPage';
import { useEffect } from 'react';

function Success() {
    const {userInfo} = useMember(); 
    const navi = useNavigate();
    const {courseType, sessionId} = useParams();
    useEffect(()=>{
        if(!userInfo.employName && !userInfo.jobName){
            navi(`/${courseType}/detail/${sessionId}`);
        }
    },[])

    return (
        <div className='success-container'>
            <div className='success-text'>
                <div className="success-icon-container">
                    <span className="success-icon" />
                </div> 
                <p className='success-message'>수강신청 성공</p>
                
                {courseType==="training"?
                <>
                <div className='training-wrap'>
                    <p className='info-training-title'>기본 정보</p>
                    <div className='info-training-wrap'>
                       {/* Profile Image */}
                        {userInfo.employProfileImage && (
                    
                        <div className="photo-file-wrap" >
                        
                            <img src={URL.createObjectURL(userInfo.employProfileImage)} alt="Profile" className="photo-file" />
                        </div>
                 
                        )}
                        <div className='info-training'><label>이름</label><p className='info-text'>{userInfo.employName}</p></div> 
                        <div className='info-training'><label>생년월일</label><p className='info-text'>{userInfo.employBirth}</p></div> 
                        <div className='info-training'><label>연락처</label><p className='info-text'>{userInfo.employPhonenumber}</p></div> 
                        <div className='info-training'><label>성별</label><p className='info-text'>{userInfo.employGender}</p></div> 
                        <div className='info-training'><label>추가 연락처</label><p className='info-text'>{userInfo.employAddPhonenumber}</p></div> 
                        <div className='info-training'><label>이메일</label><p className='info-text'>{userInfo.employEmail}</p></div> 
                        <div className='info-training'><label>현주소</label><p className='info-text'>{userInfo.employAddress}</p></div> 
                    </div>

                </div>
                </>:
                <div className='training-wrap'>
                    <div className='info-training'><label>이름</label><p className='info-text'>{userInfo.jobName}</p></div>
                    <div className='info-training'><label>연락처</label><p className='info-text'>{userInfo.jobPhoneNumber}</p></div> 
                    <div className='info-training'><label>이메일</label><p className='info-text'>{userInfo.jobEamil}</p></div> 
                    <div className='info-training'><label>회사명</label><p className='info-text'>{userInfo.jobCompany}</p></div> 
                    <div className='info-training'><label>요구사항</label><p className='info-text'>{userInfo.jobRequire}</p></div> 
                </div>}
                
           
            </div>
            <div className='goMain-btn-wrap'>
                <button onClick={()=>navi('/firstzone')} className='goMain-btn'>메인으로 이동하기</button> 
            </div>
            
        </div>
    );
}

export default Success;

