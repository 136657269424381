import React, { useState, useEffect } from "react";
import { call } from "service/ApiService";

function Firstzone(props) {
  const [content, setContent] = useState("");
  const [introImage, setIntroImage] = useState({});
  const [thumbnailImage, setThumbnailImage] = useState({});
  const [infoImage, setInfoImage] = useState({});
  const [clientImage, setClientImage] = useState({});

  useEffect(() => {
    call("/api/v1/admin/metadata/first-zone", "GET")
      .then((response) => {
        setContent(response.content)
        setIntroImage({
          web: { preview: `/uploads/${response.introImage.webImage}`, file: new File([], "") },
          mobile: { preview: `/uploads/${response.introImage.mobileImage}`, file: new File([], "") }
        })
        setThumbnailImage({
          web: { preview: `/uploads/${response.thumbnailImage.webImage}`, file: new File([], "") },
          mobile: { preview: `/uploads/${response.thumbnailImage.mobileImage}`, file: new File([], "") }
        })
        setInfoImage({
          web: { preview: `/uploads/${response.infoImage.webImage}`, file: new File([], "") },
          mobile: { preview: `/uploads/${response.infoImage.mobileImage}`, file: new File([], "") }
        })
        setClientImage({
          web: { preview: `/uploads/${response.clientImage.webImage}`, file: new File([], "") },
          mobile: { preview: `/uploads/${response.clientImage.mobileImage}`, file: new File([], "") }
        })
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  const handleImageChange = (e, type, setImages) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages((prevImages) => ({
          ...prevImages, // 이전 상태를 유지
          [type]: { preview: reader.result, file: file } // type에 따라 업데이트
        }));
      }
    };
  }

  const handleContentChange = (e) => {
    setContent(e.target.value); // 텍스트 박스의 내용을 상태에 업데이트
  };

  const handleSave = () => {
    const formData = new FormData();
    
    formData.append('content', content);

    if (introImage.web && introImage.web.file) {
      formData.append('introImage.webImage', introImage.web.file);
    }
    if (introImage.mobile && introImage.mobile.file) {
      formData.append('introImage.mobileImage', introImage.mobile.file);
    }

    if (thumbnailImage.web && thumbnailImage.web.file) {
      formData.append('thumbnailImage.webImage', thumbnailImage.web.file);
    }
    if (thumbnailImage.mobile && thumbnailImage.mobile.file) {
      formData.append('thumbnailImage.mobileImage', thumbnailImage.mobile.file);
    }

    if (infoImage.web && infoImage.web.file) {
      formData.append('infoImage.webImage', infoImage.web.file);
    }
    if (infoImage.mobile && infoImage.mobile.file) {
      formData.append('infoImage.mobileImage', infoImage.mobile.file);
    }

    if (clientImage.web && clientImage.web.file) {
      formData.append('clientImage.webImage', clientImage.web.file);
    }
    if (clientImage.mobile && clientImage.mobile.file) {
      formData.append('clientImage.mobileImage', clientImage.mobile.file);
    }

    call("/api/v1/admin/metadata/first-zone", "PUT", formData, "multipart/form-data")
      .then((response) => {
        alert('이미지가 성공적으로 저장되었습니다.');
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };


  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">사이트 컨텐츠 {" > "} 퍼스트존</div>
        <div className="main-content">
          <div className="text-zone">
            <div className="content-header">컨텐츠 작성</div>
            <textarea
              className="textbox"
              placeholder="문구를 작성해주세요."
              value={content}
              onChange={handleContentChange}
            ></textarea>
          </div>
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">회사소개<br />이미지</div>
              <div className="image-container">
                <div className="img-tag">웹이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleImageChange(e, "web", setIntroImage)}
                />
                {introImage.web && (
                  <div className="image-preview-box">
                    <img
                      src={introImage.web.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
                <div className="img-tag">모바일 이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-mobile-image"
                  onChange={(e) => handleImageChange(e, "mobile", setIntroImage)}
                />
                {introImage.mobile && (
                  <div className="image-preview-box">
                    <img
                      src={introImage.mobile.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">회사 썸네일<br />이미지</div>
              <div className="image-container">
                <div className="img-tag">웹이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleImageChange(e, "web", setThumbnailImage)}
                />
                {thumbnailImage.web && (
                  <div className="image-preview-box">
                    <img
                      src={thumbnailImage.web.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
                <div className="img-tag">모바일 이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-mobile-image"
                  onChange={(e) => handleImageChange(e, "mobile", setThumbnailImage)}
                />
                {thumbnailImage.mobile && (
                  <div className="image-preview-box">
                    <img
                      src={thumbnailImage.mobile.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">회사정보<br />이미지</div>
              <div className="image-container">
                <div className="img-tag">웹이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleImageChange(e, "web", setInfoImage)}
                />
                {infoImage.web && (
                  <div className="image-preview-box">
                    <img
                      src={infoImage.web.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
                <div className="img-tag">모바일 이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-mobile-image"
                  onChange={(e) => handleImageChange(e, "mobile", setInfoImage)}
                />
                {infoImage.mobile && (
                  <div className="image-preview-box">
                    <img
                      src={infoImage.mobile.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">고객사<br />이미지</div>
              <div className="image-container">
                <div className="img-tag">웹이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleImageChange(e, "web", setClientImage)}
                />
                {clientImage.web && (
                  <div className="image-preview-box">
                    <img
                      src={clientImage.web.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
                <div className="img-tag">모바일 이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-mobile-image"
                  onChange={(e) => handleImageChange(e, "mobile", setClientImage)}
                />
                {clientImage.mobile && (
                  <div className="image-preview-box">
                    <img
                      src={clientImage.mobile.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSave}>
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Firstzone;
