import { useEffect, useState, useCallback, memo } from 'react';
import { useParams } from 'react-router-dom';
import { call } from 'service/ApiService';
import Banner from 'user/commons/Banner';
import "user/commons/DetailMenu.css";
import Loading from 'user/commons/Loading';
import "user/commons/Loading.css";
import CourseCard from 'user/course/component/CourseCard';
import "user/course/CoursePage.css";
import PageLayout from 'user/PageLayout';
import ErrorPageApi from "user/commons/ErrorPageApi";

const SIZE = 8; // 불러오는 컨텐츠 개수
const CATEGORY_TYPES = {
    TRAINING: 'training', //취업
    JOB: 'job' //직무
};

function CoursePage() {
    const [menu, setMenu] = useState([]); // 직무커리어과정 메뉴 목록
    const [selectedMenu, setSelectedMenu] = useState(''); // 현재 선택된 메뉴 이름
    const [course, setCourse] = useState([]); // 선택된 메뉴의 과정 목록
    const [pageState, setPageState] = useState({
        page: 0, // 현재 페이지 번호
        totalPages: 0, // 전체 페이지 수
        isLastPage: false // 마지막 페이지 여부
    });
    const [isLoading, setIsLoading] = useState(true); // 로딩 상태
    const [message, setMessage] = useState(''); // 사용자에게 보여줄 에러 메시지
    const [isError, setIsError] = useState(false); // 에러 상태

    // URL 파라미터에서 과정 타입 추출 (training 또는 job)
    const {courseType} = useParams();   
    
    // 메뉴 정보 가져오기
    useEffect(()=>{
        // 과정 타입에 따라 카테고리명 설정
        const categoryName = courseType==="training"?"취업":"직무";  
        setIsLoading(true);

        // 카테고리 정보 가져오기
        call(`/api/v1/category/select?categoryName=${categoryName}`,'GET')
        .then((response)=>{
            if (response && response.length > 0) {
                setMenu(response);
                 // 첫 번째 메뉴 자동 선택
                DetailMenuClick(response[0].categoryDetailName, 0, response[0].categoryDetailId);
            } else {
                setMessage('카테고리 정보가 없습니다');
            }
        })
        .catch(() => {
            setIsError(true);
        })
        .finally(() => setIsLoading(false));
    },[courseType]);
    
    // 과정 목록 가져오기
    const fetchCourses = async (page, id, isLoadMore = false) => {
        try {
            setIsLoading(true);
            setMessage('');
            const category = courseType === CATEGORY_TYPES.TRAINING ? "employ" : "job";
            const response = await call(`/api/v1/training-session/${category}?page=${page}&size=${SIZE}&categoryId=${id}`, 'GET');
            
            if (!response) {
                throw new Error('데이터를 불러오는데 실패했습니다');
            }
            
            if (response && response.content) {
                // 더보기인 경우 기존 목록에 추가, 아닌 경우 새로운 목록으로 설정
                setCourse(prevCourses => isLoadMore ? [...prevCourses, ...response.content] : response.content);
                 // 페이지 상태 업데이트
                setPageState(prevState => ({ ...prevState, page: response.number }));
                setPageState(prevState => ({ ...prevState, totalPages: response.totalPages }));
                setPageState(prevState => ({ ...prevState, isLastPage: response.last }));

                 // 검색 결과가 없는 경우 메시지 표시
                if(response.totalElements === 0){
                    setMessage("해당 과정이 없습니다");
                }
            }
        } catch (error) {
            setMessage(error.message || `${selectedMenu} 조회에 실패했습니다`);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };
   
    // 메뉴 클릭 시 과정 목록 새로 불러오기
    const DetailMenuClick = (menu, newPage, id, isLoadMore = false) => {
        // 새로운 메뉴 선택 시 또는 더보기 클릭 시에만 데이터 로드
        if (selectedMenu !== menu || isLoadMore) {
            setSelectedMenu(menu);
            fetchCourses(newPage, id, isLoadMore);
        }
    };

    // 날짜 포맷 변환 함수 (YYYY-MM-DD형식을 YYYY.MM.DD 형식으로 변환
    const memoizedFormatDate = useCallback((date) => {
        return date.replace(/(\d{4})-(\d{2})-(\d{2})/, (_, y, m, d) => `${y.slice(2)}.${m}.${d}`);
    }, []);

    // 과정 목록 표시 컴포넌트 
    const CourseList = memo(({ courses, formatDate, courseType }) => {
        return courses.map((course) => (
            <CourseCard 
                key={course.sessionId}
                imageSrc={course.courseThumbnail}
                title={course.sessionName}
                time={formatDate(course.courseStartDate) + " ~ " + formatDate(course.courseEndDate)}
                label={course.sessionDeadline}
                id={course.sessionId}
                courseType={courseType}
                sessionAplicationStartDate={course.sessionAplicationStartDate}
            />
        ));
    });

    // 페이지 타이틀 설정
    useEffect(() => {
        const title = courseType === CATEGORY_TYPES.TRAINING ? "취업트레이닝" : "직무커리어";
        document.title = `${title} | 퍼스트존 아카데미`;
    }, [courseType]);

    // useEffect 추가
    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isLoading]);

    return (
        <PageLayout setIsError={setIsError}>
            {/* 로딩 상태인 경우 로딩 표시 */}
            {isLoading && (
                <div className='loading-overlay'>
                    <Loading/>
                </div>
            )}
            
            {/* 에러 발생 시 에러 페이지 표시 */}
            {isError ? (
                <ErrorPageApi/>
            ) : (
                <>
                    <Banner category="training"/>
                    <div className='course-page-container'>

                        {/* 카테고리 메뉴 */}
                        <div className='detailmenu-container' >
                            <div className='detailmenu-list-container'>
                                {menu.map((menu) => (
                                    <span 
                                        key={menu.categoryDetailId}
                                        className={selectedMenu === menu.categoryDetailName ? "active" : ""}
                                        onClick={() => DetailMenuClick(menu.categoryDetailName, 0, menu.categoryDetailId)}>
                                        {menu.categoryDetailName}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {/* 과정 목록 */}
                        <div className="course-list">
                            <CourseList courses={course} formatDate={memoizedFormatDate} courseType={courseType} />
                            {message && <div className="no-course-message">{message}</div>}
                        </div>

                        {/* 더보기 버튼 */}
                        {!isLoading&&!pageState.isLastPage && ( 
                        <div className="show-more-btn-wrap">
                                <span 
                                    className="show-more-btn" 
                                    onClick={() => DetailMenuClick(
                                        selectedMenu, 
                                        pageState.page + 1, 
                                        menu.find(m => m.categoryDetailName === selectedMenu).categoryDetailId,
                                        true // 더보기 클릭 시 isLoadMore를 true로 전달
                                    )}
                                >
                                    더보기 ({pageState.page + 1}/{pageState.totalPages})
                                </span>
                            </div>
                        )} 
                    </div>
                </>
            )}
        </PageLayout>
    );
}

export default CoursePage;