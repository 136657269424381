import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.tz.setDefault("Asia/Seoul");

function DdayTimer({ targetTime, onReady, timerType }) {
    const [deadlineTime, setDeadlineTime] = useState('');

    useEffect(() => {
        // 목표 시간을 timerType에 따라 다르게 설정
        const adjustedTargetTime = dayjs(targetTime)
            .tz("Asia/Seoul")
            .set('hour', timerType === 'start' ? 0 : 23)
            .set('minute', timerType === 'start' ? 0 : 59)
            .set('second', timerType === 'start' ? 0 : 59);

        const calculateRemainingTime = () => {
            const currentTime = dayjs().tz("Asia/Seoul");
            const timeLeft = dayjs.duration(adjustedTargetTime.diff(currentTime));

            if (timeLeft.asMilliseconds() > 0) {
                const totalDays = Math.floor(timeLeft.asDays());
                const hours = String(timeLeft.hours()).padStart(2, '0');
                const minutes = String(timeLeft.minutes()).padStart(2, '0');
                
                const displayTime = `${totalDays}일 ${hours}시간 ${minutes}분`;
                setDeadlineTime(displayTime);
            } else {
                setDeadlineTime(timerType === 'start' ? '모집이 시작되었습니다' : '수강신청 마감');
                clearInterval(intervalId);
                if (onReady) onReady();
            }
        };

        const intervalId = setInterval(calculateRemainingTime, 1000);
        calculateRemainingTime();

        return () => clearInterval(intervalId);
    }, [targetTime, onReady, timerType]);

    return deadlineTime;
}

export default DdayTimer;
