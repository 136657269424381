import React, { useRef } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import story from "images/wow.png";
import "user/main/swiper/MobileStoryComponent.css";
import { useNavigate } from "react-router-dom";
import prev from "images/icon_prev.png";
import next from "images/icon_next.png";

function MobileStoryComponent({ mainITech, mainVideoStory, mainBlog }) {
  // const images = props.images || [
  //     story,
  //     story,
  //     story,
  //   ];
  //   const titles = props.titles || [];
  const images = [
    mainITech?.eyetechImage || story,
    mainVideoStory?.videoStoryImage || story,
    mainBlog?.blogImage || story,
  ];

  const titles = [
    mainITech?.eyetechTitle || "제목 1",
    mainVideoStory?.videoStoryTitle || "제목 2",
    mainBlog?.blogTitle || "제목 3",
  ];

  const navi = useNavigate();

  const handleMoreStory = () => {
    navi("/story");
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="swiper-container">
      <div ref={prevRef} className="custom-swiper-prev">
        <img src={prev} alt="이전" className="prev-icon" />
      </div>
      <div ref={nextRef} className="custom-swiper-next">
        <img src={next} alt="다음" className="next-icon" />
      </div>
      <div className="mobile-stroy">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          pagination={{ clickable: true }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {titles.map((title, index) => (
            <SwiperSlide key={index}>
            <div className="post-title">{title}</div>
            <div className="mobile-story-card">
              <div className="mobile-story-img-zone">
                {index === 1 && mainVideoStory.videoStoryYoutubeId ? (
                  // mainVideoStory.videoStoryImage이거나 썸네일을 표시
                  <img
                    src={
                      mainVideoStory.videoStoryImage
                        ? `/uploads/${mainVideoStory.videoStoryImage}`
                        : `https://img.youtube.com/vi/${mainVideoStory.videoStoryYoutubeId}/mqdefault.jpg`
                    }
                    alt="미리보기"
                  />
                ) : (
                  // mainBlog.blogImage, mainITech.eyetechTitle 이미지를 표시
                  <img src={`/uploads/${images[index]}`} alt={`이미지 ${index}`} />
                )}
              </div>
            </div>
            <div className="mobile-add-story" onClick={handleMoreStory}>
              더 알아보기
            </div>
          </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default MobileStoryComponent;
