import moment from "moment";
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import calendar from "images/calendar.png";
import { useParams, useNavigate } from "react-router-dom";
import { call } from "service/ApiService";

function ModifyEmpPracticeTraining() {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState({});
  const [visible, setVisible] = useState(true);
  const [isMain, setIsMain] = useState(false);
  const [procedure, setProcedure] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [startEdu, setStartEdu] = useState(null);
  const [endEdu, setEndEdu] = useState(null);
  const [showStartEduCalendar, setShowStartEduCalendar] = useState(false);
  const [showEndEduCalendar, setShowEndEduCalendar] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const navigate = useNavigate();

  // 세션 데이터 조회
  useEffect(() => {
    call(`/api/v1/admin/training-session/${sessionId}`, "GET")
      .then((response) => {
        // 날짜 데이터 설정
        setStartDate(new Date(response.sessionApplicationStartDate));
        setEndDate(new Date(response.sessionApplicationEndDate));
        setStartEdu(new Date(response.sessioncourseStartDate));
        setEndEdu(new Date(response.sessioncourseEndDate));
        
        // 기본 데이터 설정
        setVisible(response.sessionIsPublic);
        setIsMain(response.sessionIsMain);
        setProcedure(response.courseName);
        
        // sessionData 설정
        setSessionData({
          ...response,
          courseId: {
            courseId: response.courseId
          }
        });
      })
      .catch((error) => {
        alert("과정 정보 조회에 실패했습니다.");
      });
  }, [sessionId]);

  // 과정 목록 조회
  useEffect(() => {
    call("/api/v1/admin/training-course/summary", "GET")
      .then((response) => {
        setCourseList(response);
      })
      .catch((error) => {
        alert("과정 목록 조회에 실패했습니다.");
      });
  }, []);

  // 기존의 이벤트 핸들러들...
  const handleVisibieChange = (e) => {
    setVisible(e.target.value === "true");
  };

  const handleMainChange = (e) => {
    setIsMain(e.target.value === "true");
  };

  // 과정 선택 핸들러 수정
  const handleProcedureChange = (e) => {
    const selectedCourseName = e.target.value;
    const selectedCourse = courseList.find(course => course.courseName === selectedCourseName);
    
    if (selectedCourse) {
      setProcedure(selectedCourseName);
      setSessionData(prev => ({
        ...prev,
        courseId: {
          courseId: selectedCourse.courseId
        },
        courseName: selectedCourse.courseName
      }));
    }
  };

  // 시작일 캘린더 표시/숨김
  const toggleStartCalendar = () => {
    setShowStartCalendar(!showStartCalendar);
  };
  const toggleStartEduCalendar = () => {
    setShowStartEduCalendar(!showStartEduCalendar);
  };

  // 종료일 캘린더 표시/숨김
  const toggleEndCalendar = () => {
    setShowEndCalendar(!showEndCalendar);
  };
  const toggleEndEduCalendar = () => {
    setShowEndEduCalendar(!showEndEduCalendar);
  };

  // 시작일 선택 핸들러
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setShowStartCalendar(false); // 선택 후 캘린더 닫기
  };
  const handleStartEduChange = (date) => {
    setStartEdu(date);
    setShowStartEduCalendar(false); // 선택 후 캘린더 닫기
  };

  // 종료일 선택 핸들러
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setShowEndCalendar(false); // 선택 후 캘린더 닫기
  };
  const handleEndEduChange = (date) => {
    setEndEdu(date);
    setShowEndEduCalendar(false); // 선택 후 캘린더 닫기
  };

  // textarea 변경 핸들러 추가
  const handleTextAreaChange = (field) => (e) => {
    setSessionData({
      ...sessionData,
      [field]: e.target.value
    });
  };

  // 수정 요청 처리 함수
  const handleSubmit = () => {
    const modifiedData = {
      courseId: {
        courseId: sessionData.courseId.courseId
      },
      sessionName: sessionData.sessionName,
      sessionIsPublic: visible,
      sessionIsMain: isMain,
      sessionRecruitNumber: sessionData.sessionRecruitNumber,
      sessionApplicationStartDate: moment(startDate).format("YYYY-MM-DD"),
      sessionApplicationEndDate: moment(endDate).format("YYYY-MM-DD"),
      sessioncourseStartDate: moment(startEdu).format("YYYY-MM-DD"),
      sessioncourseEndDate: moment(endEdu).format("YYYY-MM-DD"),
      sessionCondition: sessionData.sessionCondition,
      sessionInformation: sessionData.sessionInformation,
      sessionProvide: sessionData.sessionProvide,
      sessionAdvertise: sessionData.sessionAdvertise
    };

    call(`/api/v1/admin/training-session/${sessionId}`, "PUT", modifiedData)
      .then((response) => {
        navigate("/admin/employtraining/emppracticelist");
      })
      .catch((error) => {
        alert("수정에 실패했습니다.");
      });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
          취업 훈련 과정{" > "}취업 훈련 실시 목록{" > "}취업 훈련 과정 등록
        </div>
        <div className="main-content">
          <div className="title">훈련과정 등록</div>
          <div className="subtitle">
            새로 등록할 취업 훈련 실시에 대한 정보를 입력하세요
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">실시여부</div>
              <div className="radio-button">
                <label>
                  <input
                    type="radio"
                    value="true"
                    checked={visible === true}
                    onChange={handleVisibieChange}
                  />
                  실시
                </label>
                <label>
                  <input
                    type="radio"
                    value="false"
                    checked={visible === false}
                    onChange={handleVisibieChange}
                  />
                  미실시
                </label>
              </div>
            </div>
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">메인공개</div>
              <div className="radio-button">
                <label>
                  <input
                    type="radio"
                    value="true"
                    checked={isMain === true}
                    onChange={handleMainChange}
                  />
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    value="false"
                    checked={isMain === false}
                    onChange={handleMainChange}
                  />
                  비공개
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">과정 선택</div>
              <select
                className="long-drop-down-menu"
                value={procedure}
                onChange={handleProcedureChange}
              >
                <option value="">과정을 선택하세요</option>
                {courseList && courseList.map((course) => (
                  <option 
                    key={course.courseId} 
                    value={course.courseName}
                  >
                    {course.courseName}
                  </option>
                ))}
              </select>
            </div>
            <div className="option-zone">
              <div className="content-header">과정 이름</div>
              <input 
                type="text" 
                className="text-input"
                maxLength={255}
                value={sessionData?.sessionName || ''}
                onChange={(e) => setSessionData({...sessionData, sessionName: e.target.value})}
              />
            </div>
          </div>
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">신청 시작일</div>
              <button
                className="date-input-button"
                onClick={toggleStartCalendar}
              >
                {startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showStartCalendar && (
                <Calendar onChange={handleStartDateChange} value={startDate} />
              )}
            </div>
            <div className="option-zone">
              <div className="content-header">신청 종료일</div>
              <button className="date-input-button" onClick={toggleEndCalendar}>
                {endDate ? moment(endDate).format("YYYY-MM-DD") : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showEndCalendar && (
                <Calendar onChange={handleEndDateChange} value={endDate} />
              )}
            </div>
          </div>
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">교육 시작일</div>
              <button
                className="date-input-button"
                onClick={toggleStartEduCalendar}
              >
                {startEdu ? moment(startEdu).format("YYYY-MM-DD") : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showStartEduCalendar && (
                <Calendar onChange={handleStartEduChange} value={startEdu} />
              )}
            </div>
            <div className="option-zone">
              <div className="content-header">교육 종료일</div>
              <button
                className="date-input-button"
                onClick={toggleEndEduCalendar}
              >
                {endEdu ? moment(endEdu).format("YYYY-MM-DD") : "날짜 택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showEndEduCalendar && (
                <Calendar onChange={handleEndEduChange} value={endEdu} />
              )}
            </div>
            <div className="option-zone">
              <div className="content-header">모집 인원</div>
              <input 
                type="text" 
                className="calc-input" 
                value={sessionData?.sessionRecruitNumber || ''}
                onChange={(e) => setSessionData({...sessionData, sessionRecruitNumber: e.target.value})}
              />
            </div>
          </div>
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">사용자 동의</div>
              <div className="agreement-zone">
                <div className="agreement-content">
                  <div className="agreement-header">
                    서비스 이용약관 <span className="essential">(필수)</span>
                  </div>
                  <textarea 
                    className="textbox"
                    value={sessionData?.sessionCondition || ''}
                    onChange={handleTextAreaChange('sessionCondition')}
                  ></textarea>
                </div>
                <div className="agreement-content">
                  <div className="agreement-header">
                    개인정보 수집 및 이용 동의{" "}
                    <span className="essential">(필수)</span>
                  </div>
                  <textarea 
                    className="textbox"
                    value={sessionData?.sessionInformation || ''}
                    onChange={handleTextAreaChange('sessionInformation')}
                  ></textarea>
                </div>
                <div className="agreement-content">
                  <div className="agreement-header">
                    개인정보 제 3자 제공 동의{" "}
                    <span className="essential">(필수)</span>
                  </div>
                  <textarea 
                    className="textbox"
                    value={sessionData?.sessionProvide || ''}
                    onChange={handleTextAreaChange('sessionProvide')}
                  ></textarea>
                </div>
                <div className="agreement-content">
                  <div className="agreement-header">마케팅 활용 동의</div>
                  <textarea 
                    className="textbox"
                    value={sessionData?.sessionAdvertise || ''}
                    onChange={handleTextAreaChange('sessionAdvertise')}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyEmpPracticeTraining;
