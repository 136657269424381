import React, { useEffect, useState } from "react";
import "admin/jobTraining/JobTraining.css";
import "admin/setting/Setting.css";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import drag from "images/drag.png";
import { call } from "service/ApiService";

function CategoryDetail(props) {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    call('/api/v1/category/'+categoryId, 'GET')
    .then(response=>{
      const formattedCourses = response.map(course => {
                const formattedCreateDate = course.categoryDetailCreatetime ? course.categoryDetailCreatetime.split('T')[0] : ''; // 'YYYY-MM-DD' 형식으로 변환
                const formattedModifiedDate = course.categoryDetailUpdatetime ? course.categoryDetailUpdatetime.split('T')[0] : ''; // 'YYYY-MM-DD' 형식으로 변환

                return {
                    ...course,
                    categoryDetailCreatetime: formattedCreateDate,   // 날짜 필드 포맷
                    categoryDetailUpdatetime: formattedModifiedDate, // 수정일도 같은 방식으로 처리
                };
      });
      setCourses(formattedCourses);
    })
}, []);

useEffect(() => {

}, [courses]);

  //추가 함수
  const handleAddChange = () => {
    const newCourse = {
      categoryDetailId: `new-${Date.now()}-${courses.length}`,  // 임시 id 생성
      categoryDetailName: "",
      categoryDetailOrder: courses.length + 1,
      categoryDetailIsHide: false
    };
    setCourses([...courses, newCourse]);
  };

  //삭제함수
  const handleDeleteChange=(index)=>{
    const newCourse = [...courses];
    newCourse.splice(index, 1);
    setCourses(newCourse);
  }

  const handleSaveChange = async () => {
    // 중복 체크
    const isDuplicate = courses.some(
      (course, index) => 
        courses.findIndex(c => c.categoryDetailName === course.categoryDetailName) !== index
    );

    if (isDuplicate) {
      alert("중복된 카테고리 이름은 저장할 수 없습니다.");
      return;
    }

    const filteredCourses = courses.map(course => {
      if (!course.categoryDetailId || course.categoryDetailId.toString().startsWith('new-')) {
        // 새로 추가된 카테고리일 경우 categoryDetailId 제외
        const { categoryDetailId, ...rest } = course;
        return rest;
      }
      return course;
    });
  
    // `filteredCourses`를 백엔드로 전송
    await call('/api/v1/admin/category/'+categoryId, 'PUT', filteredCourses)
    .then(response => {
      alert(response.message);
      window.location.reload();
    })
    .catch(error => {
      alert(error.message);
      navigate("/admin/setting/categorylist");
    });
  };

  const handleCategoryChange = (e, id) => {
    //지금은 값이 들어가있어서 현재 course state에서 변경하지만
    //받아서 쓸 때는 변경해야함.
    const newCourse = courses.map((course) =>
      course.categoryDetailId === id ? { ...course, categoryDetailName: e.target.value } : course
    );
    setCourses(newCourse);
  };

  //드래그앤 드롭 함수
  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // 이동하지 않으면 원래대로
  
  const items = Array.from(courses);
  const [renderedItem] = items.splice(result.source.index, 1); // 드래그한 항목을 배열에서 제거
  items.splice(result.destination.index, 0, renderedItem); // 새로운 위치에 드래그 항목 추가
  
  // 드래그 완료 후 각 항목의 categoryDetailOrder를 업데이트
  const reorderedCourses = items.map((course, index) => ({
    ...course,
    categoryDetailOrder: index + 1 // 새로운 순서로 설정
  }));
  
  setCourses(reorderedCourses); // 업데이트된 순서를 상태에 반영
  };

  // 개별 course의 체크박스 핸들러
const handleCheckboxChange = (id, checked) => {
  const updatedCourses = courses.map(course =>
    course.categoryDetailId === id
      ? { ...course, categoryDetailIsHide: checked }
      : course
  );
  setCourses(updatedCourses);
};

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">시스템설정 {" > "} 카테고리</div>
        <div className="main-content">
          <div className="title">이메일 목록</div> {/* 유동적으로 변경되도록 */}
          <div className="subtitle">
            순서 바를 누른 상태로 드래그 하여 표기 순서를 변경할 수 있습니다
          </div>
          <hr />
          <div className="list-zone">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="courses">
                {(provided) => (
                  <table
                    className="list-table"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <thead>
                      <tr>
                        <th className="short-zone">순서</th>
                        <th className="short-zone">숨김</th>
                        <th>카테고리 이름</th>
                        <th className="option-name-zone">수정일</th>
                        <th className="option-name-zone">삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {courses.map((course, index) => (
                        <Draggable
                          key={course.categoryDetailId}
                          draggableId={course.categoryDetailId.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <td>
                                <img src={drag} alt="드래그"></img>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={(e) => handleCheckboxChange(course.categoryDetailId, e.target.checked)}
                                  checked={course.categoryDetailIsHide || false}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  value={course.categoryDetailName}
                                  onChange={(e) =>
                                    handleCategoryChange(e, course.categoryDetailId)
                                  }
                                  className="category-input"
                                />
                              </td>
                              <td>{course.categoryDetailUpdatetime}</td>
                              <td onClick={()=>handleDeleteChange(index)}>삭제</td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="button-container">
            <button type="submit" className="addBtn" onClick={handleAddChange}>
              추가
            </button>
            <button
              type="submit"
              className="submitBtn"
              onClick={handleSaveChange}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryDetail;
