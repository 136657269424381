import copy from "images/icon_copy.svg";
import shareImgGray from "images/icon_share.svg";
import instagram from "images/logo_instagram.svg";
import kakao from "images/logo_kakao.svg";
import naver from "images/logo_naver.svg";
import { useRef, useState } from 'react';
import "user/courseDetail/component/ShareModal.css";

// 카카오 SDK 전역 객체
const { Kakao } = window;

function ShareModal({ content }) {
    // 모달 상태 관리
    const [isOpen, setIsOpen] = useState(false);
    const scrollPositionRef = useRef(window.scrollY);
    const pageUrl = window.location.href;
    const sessionName = content.sessionName;
    const img = content.course?.courseThumbnailImage || 'default-image-url.png';

    // 모달 열기/닫기 핸들러
    const handleModal = (open) => {
        if (open) {
            scrollPositionRef.current = window.scrollY; // 현재 스크롤 위치 저장
            document.body.style.overflow = 'hidden';
        } else {
            window.scrollTo(0, scrollPositionRef.current); // 저장된 위치로 복원
            document.body.style.overflow = 'auto';
        }
        setIsOpen(open);
    };

    // SNS 공유 핸들러
    const handleShareKakao = () => {
        if (!Kakao) return;
        
        // 카카오 SDK 초기화
        if (!Kakao.isInitialized()) {
            Kakao.init("9b6cdfc3b6065294d3230279d68a5b1f");
        }

        // 카카오 공유하기 실행
        Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: sessionName,
                description: '과정 내용을 확인해보세요!',
                imageUrl: `https://first-zone.duckdns.org/uploads/${img}`,
                link: { mobileWebUrl: pageUrl, webUrl: pageUrl },
            },
            buttons: [{
                title: '자세히 보기',
                link: { mobileWebUrl: pageUrl, webUrl: pageUrl },
            }],
        });
    };

    // 네이버 공유하기
    const handleShareNaver = () => {
        const naverShareUrl = `http://blog.naver.com/openapi/share?url=${encodeURIComponent(pageUrl)}`;
        window.open(naverShareUrl, '_blank');
    };

    // 인스타그램 공유하기
    const handleShareInstagram = () => {
        const url = `https://www.instagram.com/direct/new/?text=${encodeURIComponent(pageUrl)}`;
        window.open(url, '_blank');
    };

    // 링크 복사하기
    const handleCopyLink = () => {
        navigator.clipboard.writeText(pageUrl)
            .then(() => alert('링크가 복사되었습니다.'));
    };

    return (
        <>
            {/* 공유하기 버튼 */}
            <div className="course-detail-share" onClick={() => handleModal(true)}>
                <span className="share-text-square">
                    <img src={shareImgGray} alt="과정 공유하기" className="share-img"/>
                </span>
            </div>

            {/* 공유하기 모달 */}
            {isOpen && (
                <div className='share-modal-overlay'>
                    <div className="share-modal">
                        <span 
                            className='share-modal-btn-close' 
                            onClick={() => handleModal(false)}
                            aria-label="모달 닫기"
                        />
                        <p className="share-modal-title">공유하기</p>
                        
                        {/* SNS 공유 버튼 */}
                        <div className="share-modal-sns">
                            <div onClick={handleShareKakao} className="sns_icon">
                                <img src={kakao} alt="카카오톡으로 공유하기" />
                                <p>카카오톡</p> 
                            </div>
                            <div onClick={handleShareNaver} className="sns_icon">
                                <img src={naver} alt="네이버로 공유하기" />
                                <p>네이버</p>
                            </div>
                            <div onClick={handleShareInstagram} className="sns_icon">
                                <img src={instagram} alt="인스타그램으로 공유하기" />
                                <p>인스타그램</p>
                            </div>
                        </div>

                        {/* 링크 복사 */}
                        <div className="link_copy">
                            <input 
                                className="link_copy_link" 
                                value={pageUrl} 
                                readOnly 
                                aria-label="공유 링크"
                            />
                            <div 
                                className="link_copy_icon"
                                onClick={handleCopyLink}
                                role="button"
                                aria-label="링크 복사하기"
                            >
                                <img src={copy} alt="링크 복사" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ShareModal;
