import logo from "images/fzAcademy_logo_white.svg";
import logo_blue from "images/fzAcademy_logo_blue.svg";
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "user/header/Header.css";
function Header({isScrolled, isMain }) {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    // 햄버거 모달 열기/닫기
    const toggleModal = () => {
        setIsOpen(!isOpen);
        document.querySelector('.toggle').classList.toggle('active');
        // body에 모달 상태 클래스 추가/제거
        document.body.classList.toggle('modal-open');
    };
    // header 메뉴 리스트
    const menuItems = [
        { path: "/training/course", label: "취업트레이닝" },
        { path: "/career/course", label: "직무커리어" },
        { path: "/story", label: "STORY" },
        { path: "/withfirstzone", label: "With 퍼스트존" }
    ];
    
    // 현재 경로가 메뉴 아이템의 경로를 포함하는지 확인하는 함수
    const isActiveMenu = (path) => {

        // training 경로 체크
        if (path === '/training/course') {
            return location.pathname.includes('/training/');
        }
        
        // career 경로 체크
        if (path === '/career/course') {
            return location.pathname.includes('/career/');
        }
        
        // 그 외 메뉴는 정확한 경로 매칭
        return location.pathname === path;
    };
    
    const renderMenuItems = (isModal = false) => (
        menuItems.map(item => (
            <li 
                key={item.path} 
                onClick={() => {
                    navigate(item.path);
                    if (isModal) toggleModal();
                }} 
                className={isActiveMenu(item.path) ? 'active' : ''}
            >
                {item.label}
            </li>
        ))
    );
    return (
        <header className={`${isScrolled ? 'scrolled' : ''} ${isMain ? 'main-header' : ''}`}>
            <div className='header-container'>
                <img className='main-logo' src={logo} alt='퍼스트존 아카데미 로고' onClick={()=>navigate("/firstzone")}/>
                
                <nav>
                    <ul className='header-menulist'>
                        {renderMenuItems()}
                    </ul>
                </nav>
                {/* 햄버거 메뉴 */}
                <div className='toggle' onClick={toggleModal}>
                        <span></span>
                        <span></span>
                        <span></span> 
                </div>
            </div>
            {/* 햄버거 메뉴 클릭 시 나오는 모달 */}
            {isOpen && (
                <div className='header-modal'>
                    <div className='header-modal-content'>
                        <div className="header-modal-top">
                            <img className='main-logo_blue' src={logo_blue} alt='퍼스트존 아카데미 로고' onClick={()=>navigate("/firstzone")}/>
                        </div>
                        <ul className='modal-menulist'>
                            {renderMenuItems(true)}
                        </ul>
                    </div>  
                </div>
            )}
        </header>
    );
}
export default Header;