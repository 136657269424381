import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "user/story/commons/StoryContents.css";
import VideoModal from "user/story/videoStory/VideoModal";
import Loading from "user/commons/Loading";
import { call } from 'service/ApiService';
import defaultImg from "images/defaultImg.png";
import { marked } from 'marked';

function StoryContents({ selectedCategory, selectedMenu, categoryDetails, keyword, setIsError }) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);  // 현재 페이지
  const [size] = useState(6);  // 한 페이지에 보여줄 컨텐츠 개수
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentlist, setContentlist] = useState([]); // 글 목록
  const [totalPages, setTotalPages] = useState(); // 총 페이지 수
  const [pageNumber, setPageNumber] = useState(); // 현재 페이지 번호
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  const scrollPositionRef = useRef(0);
  

  const id = location.state;


  // 마크다운 태그 없애주기 코드
  const stripHtml = (content) => {
    if (!content) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(marked(content), 'text/html');
    return doc.body.innerText;
};

  const onErrorImg = (e) => {
    e.target.src = defaultImg;
  }

  // 창 넓이 확인
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);

  // 메뉴 변경 시 컨텐츠 리스트 초기화
  useEffect(() => {
    setIsLoading(false);  // 기존 로딩 상태 초기화
    setContentlist([]);  
    setPage(0); 
  }, [selectedMenu, selectedCategory, keyword]);

  useEffect(() => {
    const selectedCategoryDetail = categoryDetails.find(detail => detail.categoryDetailName === selectedCategory);
    const categoryDetailId = selectedCategoryDetail ? selectedCategoryDetail.categoryDetailId : null;

    let apiUrl = '';
    if (selectedMenu === 'itech') {
        apiUrl = '/api/v1/eyetech';
    } else if (selectedMenu === 'videostory') {
        apiUrl = '/api/v1/video-story';
    } else if (selectedMenu === 'blog') {
        apiUrl = '/api/v1/blog';
    }

    const categoryParam = categoryDetailId ? `${categoryDetailId}` : '';

    setIsLoading(true); // 로딩 상태 시작

    call(`${apiUrl}?&category=${categoryParam}&keyword=${encodeURIComponent(keyword)}&page=${page}&size=${size}`, 'GET')
      .then((response) => {
        // console.log(`StoryContents에서 ${selectedMenu} ${categoryParam} 목록:`, response);

        // `더보기` 버튼 클릭 시, 기존 목록에 추가하고, 초기 조회시에는 목록 초기화
        setContentlist(prevContents => page > 0 ? [...prevContents, ...response.content] : response.content);
        
        setTotalPages(response.totalPages);  
        setPageNumber(response.pageNumber);  
      })
      .catch((error) => {
        // console.error(`${selectedMenu} 조회 실패:`, error);
        setIsError(true); // 오류 발생 시 에러 값 true됨
      })
      .finally(() => {
        setIsLoading(false);
      });
}, [selectedCategory, selectedMenu, keyword, page]);


  const filteredContents = contentlist && contentlist.length > 0
    ? (selectedCategory === '전체'
      ? contentlist
      : contentlist.filter(content => {
          if (selectedMenu === 'itech') {
            return content.eyetechCategoryName === selectedCategory;
          } else if (selectedMenu === 'videostory') {
            return content.videoStoryCategoryName === selectedCategory;
          } else if (selectedMenu === 'blog') {
            return content.blogCategoryName === selectedCategory;
          }
          return false;
        }))
    : [];

    const handleSeeMore = () => {
      scrollPositionRef.current = window.scrollY; // 현재 스크롤 위치 저장
      setPage((prevPage) => prevPage + 1); // 페이지 증가
    };

    useEffect(() => {
      window.scrollTo(0, scrollPositionRef.current); // 스크롤 위치 복원
    }, [page]);

  const handleContentClick = (content) => {
    // 영상스토리 모달띄우기
    // if (selectedMenu === 'videostory') {
    //   setModalContent(content);
    //   setIsModalOpen(true);
    // }

    const contentId = selectedMenu === 'itech' ? content.eyetechId : selectedMenu === 'blog' ? content.blogId : content.videoStoryId;
    
    const url = `/content/${contentId}?menu=${selectedMenu}`;
    navigate(url);
    // window.open(url, '_blank'); // 새 탭으로 열기
  };

  // 이미지와 줄바꿈 태그 제거
  marked.use({
    renderer: {
      image() {
        return ''; // 이미지 태그 비활성화
      },
      br() {
        return ''; // <br> 태그를 제거하여 줄바꿈을 무시
      },
    },
  });

  const cleanMarkdown = (content) => {
    if (!content) return ''; // 내용이 없으면 빈 문자열 반환
    
    // 줄바꿈(\r\n, \n)을 공백으로 대체
    let cleanedContent = content.replace(/(\r\n|\n|\r)/g, ' '); 
  
    // 마크다운 태그 제거
    cleanedContent = cleanedContent
      .replace(/(\*\*|__)(.*?)\1/g, '$2')  // 굵은 글씨 제거
      .replace(/#{1,6} /g, '')             // 헤딩 제거
      .replace(/!\[.*?\]\(.*?\)/g, '')     // 이미지 태그 제거
      .replace(/\[.*?\]\(.*?\)/g, '')      // 링크 태그 제거
      .replace(/`{1,3}.*?`{1,3}/g, '')     // 코드 블럭 제거
      .replace(/(\|.*\|[\r\n]*)+/g, '')    // 테이블 제거
      .replace(/\*/g, '')                   // '*' 제거
      .replace(/</g, '')                    // '<' 제거
      .replace(/>/g, '')                    // '>' 제거
      .replace(/\[/g, '')                    // '[' 제거
      .replace(/\]/g, '')                    // ']' 제거
      .trim();                              // 앞뒤 공백 제거
  
    return cleanedContent;
  };

  return (
    <div className='contents-container'>
      <hr className='contents-container-hr'></hr>
      <div className="story-contents-wrapper">
      {isLoading ? (
        <Loading/>
      ) : filteredContents.length === 0 ? ( // 컨텐츠가 없을 때 메시지 표시
          <p className='no-contents-message'>컨텐츠가 없습니다.</p>
      ): (
        <div className={selectedMenu === 'videostory' ? 'v-contents-list-container' : 'contents-list-container'}>
          {filteredContents.map(content => (
            <div>
              <div 
                key={selectedMenu === 'itech' ? content.eyetechId : selectedMenu === 'videostory' ? content.videoStoryId : content.blogId} 
                className={selectedMenu === 'videostory' ? 'v-contents-list' : 'contents-list'}
                onClick={() => handleContentClick(content)} >
                  <div>
                <img 
                    src={
                      selectedMenu === 'itech'
                        ? (isMobile && content.eyetechMobileImage 
                            ? `/uploads/${content.eyetechMobileImage}` 
                            : `/uploads/${content.eyetechImage || defaultImg}`
                          )
                        : selectedMenu === 'videostory'
                        ? (isMobile && content.videoStoryMobileImage 
                            ? "https://img.youtube.com/vi/"+ content.videoStoryYoutubeId +"/mqdefault.jpg"
                            : "https://img.youtube.com/vi/"+ content.videoStoryYoutubeId +"/mqdefault.jpg"
                          )
                        : (isMobile && content.blogMobileImage 
                            ? `/uploads/${content.blogMobileImage}` 
                            : `/uploads/${content.blogImage || defaultImg}`
                          )
                    }

                    alt='콘텐츠 이미지'
                    onError={onErrorImg}
                />
                </div>
                <div className={selectedMenu === 'videostory' ? 'v-contents-text-container' : 'contents-text-container'}>
                  <div className={selectedMenu === 'videostory' ? 'v-contents-title-container' : 'contents-title-container'}>
                    <span className={selectedMenu === 'videostory' ? 'v-contents-category' : 'contents-category'}>
                      {content.eyetechCategoryName || content.videoStoryCategoryName || content.blogCategoryName}
                    </span>
                    <span className={selectedMenu === 'videostory' ? 'v-contents-title' : 'contents-title'}>
                      {content.eyetechTitle || content.videoStoryTitle || content.blogTitle}
                    </span>
                  </div>
                  <p className={selectedMenu === 'videostory' ? 'v-contents-introduce' : 'contents-introduce'}>
                    {stripHtml(content.eyetechContent || content.videoStoryContent || content.blogContent)}
                  </p>
                  <p className={selectedMenu === 'videostory' ? 'v-contents-regi-date' : 'contents-regi-date'}>
                    {selectedMenu === 'itech' && content.eyetechCreateDate && content.eyetechCreateDate.split('T')[0]}
                    {selectedMenu === 'videostory' && content.videoStoryCreateDate && content.videoStoryCreateDate.split('T')[0]}
                    {selectedMenu === 'blog' && content.blogCreationDate && content.blogCreationDate.split('T')[0]}
                  </p>
                </div>
              </div>
              <hr className={selectedMenu === 'videostory' ? 'v-contents-list-hr' : 'contents-list-hr'} ></hr>
            </div>
          ))}
        </div>
      )}
      </div>

      {filteredContents.length > 0 && pageNumber < totalPages - 1 && (
        <div className='see-more-btn-container'>
          <span className='see-more-btn' onClick={handleSeeMore}>
            더보기 ({pageNumber + 1}/{totalPages})
          </span>
        </div>
      )}

      {/* 영상스토리 모달 (Unused) */}
      {/* {isModalOpen && (
        <VideoModal content={modalContent} closeModal={() => setIsModalOpen(false)} id={id}/>
      )} */}
      
    </div>
  );
}

export default StoryContents;