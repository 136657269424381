import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { call } from "service/ApiService";
import close from "images/close.png";

function Volunteer(props) {
  const {sessionId} = useParams();
  const [elementCount, setElementCount] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [courses, setCourses] = useState([]);
  const [checkItem, setCheckItem] = useState([]);
  const modalBackground = useRef();
  const navigete = useNavigate();

  // 정렬 상태를 관리하기 위한 state 수정
  const [sortState, setSortState] = useState({
    number: '',      // '' | 'asc' | 'desc'
    name: '',        // '' | 'asc' | 'desc'
    date: '',        // '' | 'asc' | 'desc'
    state: ''
  });

  // 전체 정렬된 데이터를 저장할 state 추가
  const [allSortedData, setAllSortedData] = useState(null);
  const [isLocalSort, setIsLocalSort] = useState(false);

  // 상태 목록을 저장할 state 추가
  const [stateOptions, setStateOptions] = useState([]);

  //수정페이지 이동
  const handleModifyChange = () => {
    navigete("/admin/jobtraining/modifypractice");
  };

  // 훈련 리스트 불러오기
  const fetchCourses = (page = 0, size = elementCount) => {
    const validSize = size || 10;
    
    // API URL 기본 구성
    let url = `/api/v1/admin/job-training/applicant/${sessionId}?page=${page}&size=${validSize}`;
    
    // 정렬 상태가 있는 경우 추가
    if (sortState.name) {
      url += `&nameOrder=${sortState.name}`;
    }
    if (sortState.date) {
      url += `&dateOrder=${sortState.date}`;
    }
    if (sortState.state) {
      url += `&jobStateId=${sortState.state}`;
    }
    
    call(url, 'GET')
      .then((response) => {
        const formattedCourses = response.content.map((course) => ({
          ...course,
          courseCreateTime: course.jobCreateTime.split('T')[0]
        }));
        
        setCourses(formattedCourses);
        setTotalPage(response.totalPages);
      })
      .catch((error) => {
        console.error('코스 불러오기 실패:', error);
        alert('코스 불러오기에 실패했습니다.');
      });
  };

    // 상태 목록을 불러오는 함수
    const fetchStateOptions = () => {
      call(`/api/v1/category/select?categoryName=직무상태`, 'GET')
        .then((response) => {
          setStateOptions(response || []);
        })
        .catch((error) => {
          console.error('상태 목록 조회 실패:', error);
          alert('상태 목록을 불러오는데 실패했습니다.');
        });
    };

    // 컴포넌트 마운트 시 상태 목록 불러오기
  useEffect(() => {
    fetchStateOptions();
  }, []);
  
  //단일 체크
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItem((prev) => [...prev, id]); //체크 아이템을 배열에 추가
    } else {
      setCheckItem(checkItem.filter((el) => el !== id)); //선택해제시 요소 제외
    }
  };

  //전체체크
  const handleAllCheck = (checked) => {
    if (checked) {
      //전체선택시 전체 아이템을 담은 배열로 업데이트
      const idArray = courses.map((course) => course.id);
      setCheckItem(idArray);
    } else {
      setCheckItem([]); //새로운 빈 배열로 업데이트
    }
  };

  const handleElementCountChange = (e) => {
    setElementCount(e.target.value);
  };

  useEffect(() => {
    if (sessionId) {
      fetchCourses(currentPage, elementCount);
    }
  }, [currentPage, elementCount, sortState, sessionId]);

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return; // 유효성 검사
    setCurrentPage(page); // 재 페이지를 업데이트합니다.
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수
  
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);
    // 계산된 페이지 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  // 선택된 지원자 정보를 저장할 state 추가
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [selectedApplicantDetail, setSelectedApplicantDetail] = useState(null);

  // 상세 정보를 가져오는 함수
  const fetchApplicantDetail = async (jobId) => {
    try {
      const response = await call(`/api/v1/admin/job-training/${jobId}`, 'GET');
      setSelectedApplicantDetail(response);
    } catch (error) {
      console.error('상세 정보 조회 실패:', error);
      alert('상세 정보를 불러오는데 실패했습니다.');
    }
  };

  // 모달 열기 함수 수정
  const handleOpenModal = async (applicant) => {
    setSelectedApplicant(applicant);
    await fetchApplicantDetail(applicant.jobId);
    setModalOpen(true);
  };

  // 수정된 데이터를 추적하기 위한 state 추가
  const [modifiedData, setModifiedData] = useState({});

  // 상태 변경 핸들러 수정
  const handleStateChange = (jobId, newStateId) => {
    const course = courses.find(c => c.jobId === jobId);
    setModifiedData(prev => ({
      ...prev,
      [jobId]: {
        jobStateId: parseInt(newStateId),
        jobNote: course.jobNote
      }
    }));

    // courses 업데이트
    setCourses(prevCourses => 
      prevCourses.map(c => 
        c.jobId === jobId 
          ? { ...c, jobStateId: parseInt(newStateId) }
          : c
      )
    );

    // 현재 모달이 열려있고, 변경된 항목이 선택된 지원자인 경우 상세 정보도 업데이트
    if (modalOpen && selectedApplicantDetail?.jobId === jobId) {
      setSelectedApplicantDetail(prev => ({
        ...prev,
        jobStateId: parseInt(newStateId),
        jobStateName: stateOptions.find(opt => opt.categoryDetailId === parseInt(newStateId))?.categoryDetailName
      }));
    }
  };

  // 비고 변경 핸들러 수정
  const handleNoteChange = (jobId, note) => {
    // modifiedData 업데이트
    const course = courses.find(c => c.jobId === jobId);
    setModifiedData(prev => ({
      ...prev,
      [jobId]: {
        jobStateId: prev[jobId]?.jobStateId ?? course.jobStateId,
        jobNote: note
      }
    }));

    // courses 상태도 함께 업데이트
    setCourses(prevCourses => 
      prevCourses.map(c => 
        c.jobId === jobId 
          ? { ...c, jobNote: note }
          : c
      )
    );

    // 모달이 열려있고, 현재 수정 중인 항목이 선택된 지원자인 경우 상세 정보도 업데이트
    if (modalOpen && selectedApplicantDetail?.jobId === jobId) {
      setSelectedApplicantDetail(prev => ({
        ...prev,
        jobNote: note
      }));
    }
  };

  // 저장 버튼 핸들러 수정
  const handleSave = async () => {
    try {
      if (Object.keys(modifiedData).length === 0) {
        alert('수정된 내용이 없습니다.');
        return;
      }

      if (!window.confirm('수정된 내용을 저장하시겠습니까?')) {
        return;
      }

      // 각 수정된 항목에 대해 API 호출
      for (const [jobId, data] of Object.entries(modifiedData)) {
        await call(
          `/api/v1/admin/job-training/${jobId}`, 
          'PUT', 
          {
            jobStateId: data.jobStateId,
            jobNote: data.jobNote
          }
        );
      }
      
      alert('저장되었습니다.');
      setModifiedData({});

      // 현재 URL 가져오기
      const url = new URL(window.location.href);
      // 현재 페이지 정보를 URL에 추가
      url.searchParams.set('page', currentPage);
      // URL 업데이트
      window.history.pushState({}, '', url);
      // 페이지 새로고침
      window.location.reload();

    } catch (error) {
      console.error('저장 실패:', error);
      alert('저장에 실패했습니다.');
    }
  };

  // 이미지 URL을 처리하는 헬퍼 함수 추가
  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `/uploads/${imagePath}`;
  };

  // 삭제 처리 함수 추가
  
  const handleDelete = async (jobId) => {
    try {
      // 삭제 확인
      if (!window.confirm('정말 삭제하시겠습니까?')) {
        return;
      }

      await call(`/api/v1/admin/job-training/${jobId}`, 'DELETE');
      
      // 삭제 성공 시
      alert('지원자가 삭제되었습니다.');
      setModalOpen(false); // 모달 닫기
      fetchCourses(currentPage, elementCount); // 목록 새로고침
    } catch (error) {
      console.error('삭제 실패:', error);
      alert('삭제에 실패했습니다.');
    }
  };

  // 정렬 상태 변경 핸들러 추가
  const handleSort = async (field) => {
    try {
      // 새로운 정렬 상태 설정
      const newState = {
        number: '',
        name: '',
        date: '',
        state: sortState.state
      };

      // 현재 필드의 정렬 상태 변경
      if (!sortState[field]) newState[field] = 'asc';
      else if (sortState[field] === 'asc') newState[field] = 'desc';
      else newState[field] = '';

      // 정렬 상태 즉시 업데이트
      setSortState(newState);
      setCurrentPage(0);

      // URL 파라미터 초기화
      const url = new URL(window.location.href);
      url.searchParams.delete('nameOrder');
      url.searchParams.delete('dateOrder');

      // 번호 정렬
      if (field === 'number') {
        setIsLocalSort(true);
        setAllSortedData(null); // 기존 정렬 데이터 초기화
        
        const response = await call(`/api/v1/admin/job-training/applicant/${sessionId}?page=0&size=99999`, 'GET');
        
        if (response && response.content) {
          let allCourses = response.content.map((course) => ({
            ...course,
            courseCreateTime: course.jobCreateTime.split('T')[0]
          }));

          // 번호 기준 정렬
          allCourses.sort((a, b) => {
            const aNum = parseInt(a.jobNumber);
            const bNum = parseInt(b.jobNumber);
            return newState.number === 'asc' ? aNum - bNum : bNum - aNum;
          });

          // 전체 데이터 저장
          setAllSortedData(allCourses);
          
          // 현재 페이지 데이터 설정
          const pageSize = elementCount || 10;
          setCourses(allCourses.slice(0, pageSize));
          setTotalPage(Math.ceil(allCourses.length / pageSize));
        }
      } else {
        setIsLocalSort(false);
        setAllSortedData(null);

        if (newState[field]) {
          switch(field) {
            case 'name':
              url.searchParams.set('nameOrder', newState.name);
              break;
            case 'date':
              url.searchParams.set('dateOrder', newState.date);
              break;
          }
        }
        
        window.history.pushState({}, '', url);
        await fetchCourses(0, elementCount || 10);
      }
    } catch (error) {
      console.error('정렬 실패:', error);
      alert('정렬에 실패했습니다. 다시 시도해주세요.');
    }
  };

  const [courseName, setCourseName] = useState(""); // 과정명을 저장할 state 추가

  // 과정명을 가져오는 함수 추가
  const fetchCourseName = async () => {
    try {
      const response = await call(`/api/v1/admin/training-session/${sessionId}`, 'GET');
      if (response && response.sessionName) {
        setCourseName(response.sessionName);
      } else {
        setCourseName('과정명 없음');
      }
    } catch (error) {
      console.error('과정명 조회 실패:', error);
      setCourseName('과정명 조회 실패');
    }
  };

  // sessionId가 있을 때 과정명 조회하는 useEffect 추가
  useEffect(() => {
    if (sessionId) {
      fetchCourseName();
    }
  }, [sessionId]);

  // 상태 필터링을 위한 핸들러 수정
  const handleFilterStateChange = (value) => {
    // sortState 업데이트
    setSortState(prev => ({
      ...prev,
      state: value
    }));
    
    // 현재 페이지를 0으로 리셋
    setCurrentPage(0);
  };

  // 초기 URL 파라미터 처리를 위한 useEffect 추가
  useEffect(() => {
    const url = new URL(window.location.href);
    
    // 정렬 파라미터 중 하나만 적용
    const nameOrder = url.searchParams.get('nameOrder');
    const dateOrder = url.searchParams.get('dateOrder');
    
    // 정렬 상태 복원 (하나만 적용)
    const newSortState = {
      number: '',
      name: '',
      date: '',
      state: sortState.state
    };

    if (nameOrder) newSortState.name = nameOrder;
    else if (dateOrder) newSortState.date = dateOrder;
    
    setSortState(newSortState);
  }, []);

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
          직무 훈련 과정 {" > "} 직무 훈련 지원자 관리
        </div>
        <div className="main-content">
          <div className="title">{courseName} 지원자</div>
          <div className="subtitle">해당 과정에 지원한 지원자입니다 (- 표기가 있는 항목을 클릭하면 정렬 순서를 변경할 수 있습니다)</div>
          <div className="subtitle">지원자별 상태는 변경 즉시 반영되어 저장 버튼을 누를 필요 없습니다</div>
          <hr />
          <div className="down-option-zone">
            <div className="option-content">
              <div className="option-subtitle">보기</div>
              <select
                className="drop-down-menu"
                value={elementCount}
                onChange={handleElementCountChange}
              >
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="50">50개씩 보기</option>
                <option value="100">100개씩 보기</option>
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">상태</div>
              <select 
                className="drop-down-menu"
                value={sortState.state}
                onChange={(e) => handleFilterStateChange(e.target.value)}
              >
                <option value="">전체</option>
                {stateOptions.map((option) => (
                  <option key={option.categoryDetailId} value={option.categoryDetailId}>
                    {option.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('number')} style={{cursor: 'pointer'}}>
                    번호 {
                      sortState.number === 'asc' ? '↑' : 
                      sortState.number === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th onClick={() => handleSort('name')} style={{cursor: 'pointer'}}>
                    성명 {
                      sortState.name === 'asc' ? '↑' : 
                      sortState.name === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th>회사명</th>
                  <th onClick={() => handleSort('date')} style={{cursor: 'pointer'}}>
                    지원일 {
                      sortState.date === 'asc' ? '↑' : 
                      sortState.date === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th>상태</th>
                  <th>비고</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.jobId}>
                    <td className="empText" onClick={() => handleOpenModal(course)}>{course.jobNumber}</td>
                    <td className="empText" onClick={() => handleOpenModal(course)}>{course.jobName}</td>
                    <td>{course.jobCompany}</td>
                    <td>{course.jobCreateTime.split('T')[0]}</td>
                    <td>
                      <select 
                        className="drop-down-menu"
                        value={course.jobStateId ? course.jobStateId.toString() : ''}
                        onChange={(e) => handleStateChange(course.jobId, e.target.value)}
                      >
                        <option value="">선택</option>
                        {stateOptions.map((option) => (
                          <option 
                            key={option.categoryDetailId} 
                            value={option.categoryDetailId}
                          > 
                            {option.categoryDetailName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="note-zone"
                        value={course.jobNote}
                        onChange={(e) => handleNoteChange(course.jobId, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {modalOpen && selectedApplicantDetail && (
            <div
              className="modal-container"
              ref={modalBackground}
              onClick={(e) => {
                if (e.target === modalBackground.current) {
                  setModalOpen(false);
                }
              }}
            >
              <div className="modal-content">
                <div className="close-area">
                  <img
                    className="closeBtn"
                    src={close}
                    alt="닫기"
                    onClick={() => setModalOpen(false)}
                  />
                </div>
                <div className="modal-header-content">
                  <div className="modal-header-name">{selectedApplicantDetail.jobName}</div>
                  <div className="modal-header-pass">{selectedApplicantDetail.jobStateName}</div>
                </div>
                <div className="modal-personal-content">
                  <table>
                    <tbody>
                      <tr>
                        <th>연락처</th>
                        <td>{selectedApplicantDetail.jobPhoneNumber}</td>
                      </tr>
                      <tr>
                        <th>이메일</th>
                        <td>{selectedApplicantDetail.jobEmail}</td>
                      </tr>
                      <tr>
                        <th>회사명</th>
                        <td>{selectedApplicantDetail.jobCompany}</td>
                      </tr>
                      <tr>
                        <th>지원일자</th>
                        <td>{selectedApplicantDetail.jobCreateTime?.split('T')[0]}</td>
                      </tr>
                      <tr>
                        <th>요구사항</th>
                        <td>{selectedApplicantDetail.jobRequire || '-'}</td>
                      </tr>
                      <tr>
                        <th>비고</th>
                        <td>{selectedApplicantDetail.jobNote || '-'}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="modal-info-content">
                  <table>
                    <tbody>
                      <tr>
                        <th>약관 동의</th>
                        <td>
                          <div className="agree-checkbox">
                            <label className="label-display-flex">
                              <input 
                                type="checkbox" 
                                checked={selectedApplicantDetail.jobIsConsentCondition} 
                                disabled
                              /> 
                              서비스 이용약관
                            </label>
                            <label className="label-display-flex">
                              <input 
                                type="checkbox" 
                                checked={selectedApplicantDetail.jobIsConsentInformation} 
                                disabled
                              /> 
                              개인정보 수집 및 이용목적에 대한 동의
                            </label>
                            <label className="label-display-flex">
                              <input 
                                type="checkbox" 
                                checked={selectedApplicantDetail.jobIsConsentProvide} 
                                disabled
                              /> 
                              제3자 정보제공 동의 여부
                            </label>
                            <label className="label-display-flex">
                              <input 
                                type="checkbox" 
                                checked={selectedApplicantDetail.jobIsAdvertising} 
                                disabled
                              /> 
                              광고 제공 동의 여부
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="modal-button-container">
                  <button 
                    type="button" 
                    className="deleteBtn" 
                    onClick={() => handleDelete(selectedApplicantDetail.jobId)}
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="button-container">
            <button 
              type="submit" 
              className="submitBtn" 
              onClick={handleSave}
              disabled={Object.keys(modifiedData).length === 0} // 수정된 내용이 없으면 비활성화
            >
              저장
            </button>
          </div>
          <div className="pagination-zone">
            <button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>{"<<"}</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>{"<"}</button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageChange(page)}
                style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage - 1}>{">"}</button>
            <button onClick={() => handlePageChange(totalPage - 1)} disabled={currentPage === totalPage - 1}>{">>"}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Volunteer;
