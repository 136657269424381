import React, { useRef, useState } from "react";
import "admin/AdminPage.css";
import "admin/story/Story.css";
import "@toast-ui/editor/dist/toastui-editor.css"; // Toast UI 기본 스타일 불러오기
import { useLocation, useNavigate } from "react-router-dom";
import { call } from "service/ApiService";
import TuiEditor from "admin/TuiEditor";
import { extractImageNames } from "./ExtractImageNames";

function AddBlog(props) {
  const [wThumbnail, setWThumbnail] = useState(""); // 썸네일
  const [mThumbnail, setMThumbnail] = useState(""); // 썸네일
  const [category, setCategory] = useState(""); // 과정 카테고리
  const [deleteImages, setDeleteImages] = useState([]); // 삭제할 이미지들
  const [blog, setBlog] = useState({
    blogTitle: "",
    blogContent: "", 
    blogIsMain: false, 
    blogImage: "", 
    blogMobileImage: "",
    blogCategoryId: 0
  });
  const editorRef = useRef(); // 에디터 레퍼런스 생성
  const navigate = useNavigate();
  const location = useLocation();
  const {categories} = location.state;

  // 썸네일 이미지 처리 함수
  const handleWThumbnailChange = (e) => {
    const file = e.target.files[0]; // 업로드 된 이미지
    if (file.size !== 0) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // 데이터url로 변환
      reader.onloadend = () => {
        setBlog({
          ...blog,
          blogImage: file, // 변환된 이미지 URL을 blogImage에 저장
        });
        setWThumbnail(reader.result); // 웹이미지 썸네일 저장
      };
    }
  };

  const handleMThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file.size !== 0) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBlog({
          ...blog,
          blogMobileImage: file, // 변환된 이미지 URL을 blogImage에 저장
        });
        setMThumbnail(reader.result); // 모바일이미지 썸네일 저장
      };
    }
  };

  // 카테고리 변경 함수
  const handleCategoryChange = (e) => {
    setBlog({
      ...blog,
      blogCategoryId: e.target.selectedOptions[0].getAttribute('data-key')
    });
    setCategory(e.target.value);
  };

  // 에디터 내용 가져오는 함수
  const handleSubmit = () => {
    if (!editorRef.current) {
      return; // 에디터가 초기화되지 않았다면 함수 종료
    }
    const editorInstance = editorRef.current.getInstance(); // 에디터 인스턴스 가져오기
    const content = editorInstance.getMarkdown(); // 에디터 내용 가져오기

    const formData = new FormData();

    // blog 객체의 모든 키-값 쌍을 FormData에 추가
    Object.entries(blog).forEach(([key, value]) => {
      if (key === 'blogImage' && !(value instanceof File)) {
        const emptyBlob = new Blob([], { type: 'image/jpeg' }); // JPEG 타입의 빈 Blob
        const thumbnailFile = new File([emptyBlob], value !== "" ? value : 'empty'); // 빈 파일
        formData.append(key, thumbnailFile);
      } else if (key === 'blogMobileImage' && !(value instanceof File)) {
        const emptyBlob = new Blob([], { type: 'image/jpeg' }); // JPEG 타입의 빈 Blob
        const thumbnailFile = new File([emptyBlob], value !== "" ? value : 'empty'); // 빈 파일
        formData.append(key, thumbnailFile);
      } else if (key === 'blogContent') {
        formData.append(key, content);
      } else {
        formData.append(key, value);
      }
    });

    const contentImages = extractImageNames(content);

    // deleteImages에서 contentImages에 포함된 이미지 제외
    const finalDeleteImages = deleteImages.filter(img => !contentImages.includes(img));

    formData.append('contentImages', contentImages);
    formData.append('deleteImages', finalDeleteImages);

    call('/api/v1/admin/blog', 'POST', formData, 'multipart/form-data')
    .then(response => {
      alert(response.message);
      navigate('/admin/story/bloglist');
    })
    .catch(error=>{
      alert(error.message);
    })
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">Story{" > "}블로그</div>
        <div className="main-content">
          <div className="title">블로그 등록</div>
          <div className="subtitle">블로그 탭에 새로운 글을 등록합니다</div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">카테고리</div>
              <select
                className="drop-down-menu"
                value={category}
                onChange={handleCategoryChange}
              >
                <option key={0} value="" data-key={0}>선택하세요</option>
                {categories.map((category) => (
                  <option key={category.categoryDetailId} value={category.categoryDetailName} data-key={category.categoryDetailId}>
                    {category.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">메인에 고정</div>
              <div className="checkbox">
                <input type="checkbox" className="sticky-check" checked={blog.blogIsMain}
                  onChange={(e) => setBlog({ ...blog, blogIsMain: e.target.checked })}/>
              </div>
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">제목</div>
              <input className="long-title"
                id="blogTitle" 
                value={blog.blogTitle}
                onChange={(e) => {
                  if (e.target.value.length > 255) {
                    alert("제목은 최대 255자입니다.");
                    document.getElementById("blogTitle").focus();  // id로 요소를 찾아 포커스
                    return;
                  }
                  setBlog({ ...blog, blogTitle: e.target.value })
                }}/>
            </div>
          </div>
          <hr />
          <div className="input-image-zone">
            <div className="content-header">
              썸네일 이미지
            </div>
            <div className="image-container">
              <div className="img-tag">웹이미지 <div className="caption">300*200 권장</div></div>
              <input
                type="file"
                className="add-web-image"
                onChange={handleWThumbnailChange}
              />
              {wThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={wThumbnail}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
              <div className="img-tag">모바일 이미지 <div className="caption">126*100 권장</div></div>
              <input
                type="file"
                className="add-mobile-image"
                onChange={handleMThumbnailChange}
              />
              {mThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={mThumbnail}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="text-zone">
            <div className="content-header">내용</div>
              <div className="editor-area">
                <TuiEditor 
                    content={blog.blogContent}
                    wHhumbnail={blog.blogImage}
                    mThumbnail={blog.blogMobileImage}
                    editorRef={editorRef}
                    setDeleteImages={setDeleteImages} />
              </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBlog;
