import { useRef, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import "user/commons/AddressSearch.css";

function AddressSearch({setAddress}) {
    // 모달 상태 추가
    const [isOpen, setIsOpen] = useState(false);
    const scrollPositionRef = useRef(0);

    // 주소 검색 완료 시 실행
    const handleComplete = (data) => {
        setAddress(data.address);
        handleModal(false);
    }

    // 모달 제어 통합 핸들러
    const handleModal = (open) => {
        if (open) {
            scrollPositionRef.current = window.scrollY;
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
            window.scrollTo(0, scrollPositionRef.current);
        }
        setIsOpen(open);
    };

    return (
        <>
            <button 
                className="address-button insert-button" 
                onClick={() => handleModal(true)}
            >
                우편번호
            </button>
            
            {isOpen && (
                <div className='modal-overlay'>
                    <div className="modal">
                        <span 
                            className='modal_btn_close' 
                            onClick={() => handleModal(false)}
                            aria-label="모달 닫기"
                        />
                        <div className='address-search'>
                            <DaumPostcode 
                                onComplete={handleComplete}
                                style={{ 
                                    width: "100%",
                                    height: "400px",
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default AddressSearch;