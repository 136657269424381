import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DetailMenu from "user/commons/DetailMenu";
import DetailCategory from "user/story/commons/DetailCategory";
import Search from "user/story/commons/Search";
import StoryContents from "user/story/commons/StoryContents";
import Banner from 'user/commons/Banner';
import "user/story/Story.css";
import PageLayout from 'user/PageLayout';
import ErrorPageApi from "user/commons/ErrorPageApi";

function Story() {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const initialMenu = queryParams.get('menu') || 'itech'; // 기본값 'itech'
    const initialCategory = queryParams.get('category') || '전체'; // 기본값 '전체'

    const [selectedMenu, setSelectedMenu] = useState(initialMenu);
    const [selectedCategory, setSelectedCategory] = useState(initialCategory);
    const [categoryDetails, setCategoryDetails] = useState([]); // categoryDetails 상태 추가
    const [keyword, setKeyword] = useState(''); // 검색어 상태 추가
    const [categoryName, setCategoryName] = useState(''||'아이테크');

    const [isError, setIsError] = useState(false);  // 에러 상태
    

    // 메뉴 변경 시 URL 업데이트
    const handleMenuChange = (menu) => {
        setSelectedMenu(menu);
        setSelectedCategory('전체');
        setCategoryName(menu); // categoryName 설정
        navigate(`?menu=${menu}&category=전체`);
    };

    // 카테고리 변경 시 URL 업데이트
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        navigate(`?menu=${selectedMenu}&category=${category}`);
    };

    // 검색어가 변경될 때 호출
    const handleSearch = (searchTerm) => {
        setKeyword(searchTerm); // 검색어 업데이트
    };

    // call함수에서 백엔드로부터 정보를 조회하는 방식은 한글 이름으로 조회하지만
    // 모든 로직을 메뉴 이름이 한글이 아닌 영어로 짜버렸기에 해당 코드 필요
    // 해당 코드 없을 시 스토리에서 페이지를 새로고침할 경우
    // 기본 값으로 준 initialMenu와 initialCategory 때문에 카테고리가 해당 값의 카테고리로 나옴 (현재 itech와 전체로 되어있음)
    useEffect(() => {
        // 특정 값을(itech) 키key로, 특정 값을 (아이테크) 값value 이런식으로 매핑함
        // 즉, menuMapping['itech']로 접근하면 '아이테크'라는 값을 반환함
        const menuMapping = {
            'itech': '아이테크',
            'videostory': '영상스토리',
            'blog': '블로그',
        };
        // menuMapping 변수에 initialMenu 값이 존재하면 setCategoryName에 해당값 넣기
        if (menuMapping[initialMenu]) {
            setCategoryName(menuMapping[initialMenu]);
        }
    }, []);

    if (isError === true) {
        return (
            <ErrorPageApi></ErrorPageApi>
        )
    }


    return (
        <div>
            <PageLayout setIsError={setIsError}>
                <Banner category="story"/>
                <DetailMenu
                    selectedMenu={selectedMenu}
                    setCategoryName={setCategoryName}
                    setselectedMenu={handleMenuChange}
                    visibleMenus={['itech', 'videostory', 'blog']} 
                />
                <div className="category-search-container">
                    <DetailCategory 
                        selectedMenu={selectedMenu}
                        setSelectedCategory={handleCategoryChange}
                        selectedCategory={selectedCategory}
                        setCategoryDetails={setCategoryDetails}
                        categoryName={categoryName} // categoryName 전달
                        setIsError={setIsError}
                    />
                    <Search onSearch={handleSearch} />
                </div>
                <StoryContents 
                    selectedCategory={selectedCategory}
                    selectedMenu={selectedMenu}
                    categoryDetails={categoryDetails}
                    keyword={keyword} // 검색어 전달
                    setIsError={setIsError}
                />
            </PageLayout>
        </div>
    );
}

export default Story;