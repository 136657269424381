import React, { useState } from 'react';
import "user/register/component/AccordionBox.css"

function AccordionBox({checkList,check,title,content,name,number,className}) {
    // 아코디언 상태
    const [openAccordion, setOpenAccordion] = useState(null); 

    // 아코디언 토글 함수
    const toggleAccordion = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };

    return (
        <div className={`accordion-container ${className || ''}`}>
            <div className='accordion-title' >
                    <div className="check-container" onClick={() => check(name)}>
                        <span className={checkList.includes(name) ? "checked" : "check"} />
                    </div>
                    <div className="accordion-title-text" onClick={() => toggleAccordion(number)}>
                        <p>{title}</p>
                        <div className='toggle-container'>
                            <span className={openAccordion?"icon-toggle-open":"icon-toggle-close"}/>
                        </div>
                    </div>
            </div>
            {openAccordion === number && <div className="accordion-content">{content}</div>}
        </div>
    );
}

export default AccordionBox;