import React, { useEffect, useRef, useState } from "react";
import "user/main/Main.css";
import EduComponent from "user/main/EduComponent";
import StoryComponent from "user/main/StoryComponent";
import VideoComponent from "user/main/VideoComponent";
import Notice from "./Notice";
import EduFacilityComponent from "user/main/EduFacilityComponent";
import { call } from "service/ApiService";
import close from "images/close.png";
import PageLayout from "user/PageLayout";
import ErrorPageApi from "user/commons/ErrorPageApi";

function Main(props) {
  const [metadata, setMetadata] = useState({});
  const [trainingCourse, setTrainingCourse] = useState([]);
  const [mainNotice, setMainNotice] = useState({});
  const [mainITech, setMainITech] = useState({});
  const [mainVideoStory, setMainVideoStory] = useState({});
  const [mainBlog, setMainBlog] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [actIndex, setActIndex] = useState(0);
  const modalBackground = useRef();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [isScrolled, setIsScrolled] = useState(false);
  const mainImageZoneRef = useRef(null);
  const [isError, setIsError]=useState();
  const scrollPositionRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      if (mainImageZoneRef.current) {
        const mainImageZoneHeight = mainImageZoneRef.current.offsetHeight;
        setIsScrolled(window.scrollY > mainImageZoneHeight); // 스크롤이 mainImageZone 높이를 넘으면 true
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const updateView = () => {
    setIsMobileView(window.innerWidth < 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateView);
    return () => window.removeEventListener("resize", updateView);
  }, []);
  useEffect(() => {
    document.title = "퍼스트존 아카데미";
  }, []);

  useEffect(() => {
    call("/api/v1/metadata/main", "GET")
      .then((response) => {
        setMetadata(response);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    call("api/v1/notice?category=&keyword=&page=0&size=5", "GET")
      .then((response) => {
        setMainNotice(response);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    call("api/v1/eyetech/main", "GET")
      .then((response) => {
        setMainITech(response);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    call("api/v1/video-story/main", "GET")
      .then((response) => {
        setMainVideoStory(response);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    call("api/v1/blog/main", "GET")
      .then((response) => {
        setMainBlog(response);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    call("api/v1/training-session/main", "GET")
      .then((response) => {
        setTrainingCourse(response);
      })
      .catch((error) => {
        setIsError(true);
      });
  }, []);

  if(isError===true){
    return (
      <ErrorPageApi/>
    )
  }

   // 모달 열기/닫기 핸들러
   const handleModal = (open) => {
    if (open) {
        scrollPositionRef.current = window.scrollY; // 현재 스크롤 위치 저장
        document.body.style.overflow = 'hidden';
    } else {
        window.scrollTo(0, scrollPositionRef.current); // 저장된 위치로 복원
        document.body.style.overflow = 'auto';
    }
    setModalOpen(open);
};

  return (
    <div className="main-container">
      <PageLayout isScrolled={isScrolled} isMain={true} setIsError={setIsError}>
        <div style={{ height: "100vh" }}></div> 
        <div ref={mainImageZoneRef} className="main-image-zone">
          {metadata?.mainImage && (
            <img
              src={`/uploads/${
                isMobileView
                  ? metadata.mainImage.mobileImage
                  : metadata.mainImage.webImage
              }`}
              alt="메인이미지"
              className="main-image"
            />
          )}
          <div className="scroll-text">스크롤하세요</div>
        </div>
        <EduComponent
          recommendedCourseText={metadata.recommendedCourseText}
          trainingCourse={trainingCourse}
          eduId={trainingCourse[actIndex]}
          setModalOpen={()=>handleModal(true)}
          setActIndex={setActIndex}
        />
        <StoryComponent
          storyImage={metadata.storyImage}
          storyText={metadata.storyText}
          mainITech={mainITech}
          mainVideoStory={mainVideoStory}
          mainBlog={mainBlog}
        />
        <VideoComponent youtubeLink={metadata.youtubeLink} />
        <Notice mainNotice={mainNotice} />
        <EduFacilityComponent
          facilityImages={metadata.facilityImages}
          facilityText={metadata.facilityText}
          facilityBackgroundImage={metadata.facilityBackgroundImage}
        />
      </PageLayout>

      {/* 모달 */}
      {modalOpen && (
        <div
          className="modal-container"
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              setModalOpen(false);
            }
          }}
        >
          <div className="modal-content">
            <div className="close-area">
              <img
                className="roadmap-close"
                src={close}
                alt=" 닫기"
                onClick={()=>handleModal(false)}
              />
            </div>
            <div className="roadmap-zone">
              <img
                src={
                  isMobileView
                    ? `/uploads/${trainingCourse[actIndex].courseRoadmapMobileImage}`
                    : `/uploads/${trainingCourse[actIndex].courseRoadmapWebImage}`
                }
                alt="로드맵이미지"
                className="roadmap-img"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Main;
