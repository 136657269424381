import React, { useEffect, useState } from "react";
import "admin/jobTraining/JobTraining.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { call } from "service/ApiService";

function CategoryList(props) {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get("page")) - 1 || 0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    fetchCourses(currentPage);
  }, []);

  useEffect(() => {
    const pageFromParams = parseInt(searchParams.get("page")) - 1 || 0;
    setCurrentPage(pageFromParams);

    fetchCourses(pageFromParams);
  }, [searchParams]);

  const fetchCourses = (page) => {
    var options = { page: page, size: 10 };

    call('/api/v1/admin/category', 'GET', options)
        .then((response) => {
            const formattedCourses = response.content.map(course => {
                const formattedCreateDate = course.categoryCreatetime ? course.categoryCreatetime.split('T')[0] : ''; // 'YYYY-MM-DD' 형식으로 변환
                const formattedModifiedDate = course.categoryModifiedDate ? course.categoryModifiedDate.split('T')[0] : ''; // 'YYYY-MM-DD' 형식으로 변환

                return {
                    ...course,
                    categoryCreatetime: formattedCreateDate,   // 날짜 필드 포맷
                    categoryModifiedDate: formattedModifiedDate, // 수정일도 같은 방식으로 처리
                };
            });

            setCourses(formattedCourses);
            setTotalPage(response.totalPages);
        })
        .catch();
  };

  const handleNoticeChange = (categoryId) => {
    navigate("/admin/setting/categorydetail/"+categoryId);
  };

  const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return; // 유효성 검사
    setCurrentPage(page); // 현재 페이지를 업데이트합니다.
    setSearchParams({ page: page + 1 });
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수
  
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);
  
    // 계산된 페이지 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">시스템설정 {" > "} 카테고리</div>
        <div className="main-content">
          <div className="title">카테고리 목록</div>
          <div className="subtitle">현재 등록된 카테고리 목록입니다</div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th>분류</th>
                  <th>제목</th>
                  <th>작성일</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr className="category-table-not-pointer">
                    <td>{course.categoryTitle}</td>
                    <td className="innerText" key={course.categoryId} onClick={() => handleNoticeChange(course.categoryId)}>{course.categoryName}</td>
                    <td>{course.categoryCreatetime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-zone">
            <button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>{"<<"}</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>{"<"}</button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageChange(page)}
                style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage - 1}>{">"}</button>
            <button onClick={() => handlePageChange(totalPage - 1)} disabled={currentPage === totalPage - 1}>{">>"}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryList;
