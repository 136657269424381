import React, { useState, useEffect } from "react";
import { call } from "service/ApiService";
import { useNavigate } from 'react-router-dom';

function VolunteerList(props) {
  const [elementCount, setElementCount] = useState("");
  const [totalPage, setTotalPage] = useState(1); // 총 페이지 수 상태 추가
  const navigete = useNavigate();
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [checkItem, setCheckItem] = useState([]);

  //수정페이지 이동
  const handleVolunteerChange=(sessionId)=>{
    navigete('/admin/jobtraining/volunteer/'+sessionId);
  }

  const handleRegistChange = () => {
    navigete("/admin/jobtraining/addpractice");
  };

  // 훈련 리스트 불러오기
  const fetchCourses = (page = 0, size = 10) => {
    const options = { 
      page: page,
      size: size
    };
  
    call('/api/v1/admin/training-session/job-volunteer', 'GET', options)
      .then((response) => {
        
        // 응답이 배열인 경우
        if (Array.isArray(response)) {
          const formattedCourses = response.map((course) => ({
            ...course,
            courseCreateTime: course.createTime.split('T')[0] // 날짜 형식 변환
          }));
          
          setCourses(formattedCourses);
          setTotalPage(Math.ceil(formattedCourses.length / size)); // 총 페이지 수 계산
        } else {
          console.error('알맞지 않은 응답입니다.:', response);
          setCourses([]); // 빈 배열로 초기화
          setTotalPage(0); // 페이지 수 초기화
        }
      })
      .catch((error) => {
        console.error('코스 불러오기에 실패했습니다.:', error);
        alert('코스 불러오기에 실패했습니다.');
      });
  };

  // 컴포넌트가 렌더링될 때 실행
  useEffect(() => {
    fetchCourses(currentPage, elementCount || 10);
  }, [currentPage, elementCount]);

  //단일 체크
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItem((prev) => [...prev, id]); //체크 아이템을 배열에 추가
    } else {
      setCheckItem(checkItem.filter((el) => el !== id)); //선택해제시 요소 제외
    }
  };

  //전체체크
  const handleAllCheck = (checked) => {
    if (checked) {
      //전체선택시 전체 아이템을 담은 배열로 업데이트
      const idArray = courses.map((course) => course.id);
      setCheckItem(idArray);
    } else {
      setCheckItem([]); //새로운 빈 배열로 업데이트
    }
  };

  const handleElementCountChange = (e) => { 
    setElementCount(Number(e.target.value)); // 숫자로 변환하여 상태를 설정
};

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return; // 유효성 검사
    setCurrentPage(page); // 현재 페이지를 업데이트합니다.
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수
  
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);
    // 계산된 페이지 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">직무 훈련 과정 {" > "} 직무 훈련 지원자 관리</div>
        <div className="main-content">
          <div className="title">지원자 확인을 위한 직무 훈련 실시 목록</div>
          <div className="subtitle">
          지원자를 확인하기 위한 직무 훈련 실시 목록입니다
          </div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      checked={checkItem.length === courses.length}
                    />
                  </th>
                  <th>과정명</th>
                  <th>지원자 수</th>
                  <th>최초 등록일</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.sessionId}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) => handleSingleCheck(e.target.checked, course.sessionId)}
                        checked={checkItem.includes(course.sessionId)}
                      />
                    </td>
                    <td className="innerText" onClick={() => handleVolunteerChange(course.sessionId)}>{course.sessionName}</td>
                    <td>{course.totalApplicant}</td>
                    <td>{course.createTime.split('T')[0]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-zone">
            <button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>{"<<"}</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>{"<"}</button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageChange(page)}
                style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage - 1}>{">"}</button>
            <button onClick={() => handlePageChange(totalPage - 1)} disabled={currentPage === totalPage - 1}>{">>"}</button>
          </div>
        </div>
      </div>
    </div>
  );

}
export default VolunteerList;