import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { call } from 'service/ApiService';

const PrivateRoutes = () => {
    const [isLogin, setIsLogin] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const isAuthenticated = async () => {
            try {
                await call('/api/v1/admin/status', 'GET');
                setIsLogin(true);
            } catch (error) {
                setIsLogin(false);
            } finally {
                setIsLoading(false); // 로딩 종료
            }
        };
        isAuthenticated();
    }, []);

    if (isLoading) {
        return <div></div>; // 로딩 중일 때 표시할 내용
    }

    if (!isLogin) {
        return <Navigate to='/adminlogin'/>; // 로그인 페이지로 리다이렉트
    }

    return <Outlet />; // 인증된 경우 컴포넌트를 반환
}

export default PrivateRoutes;
