import React from "react";
import "user/main/VideoComponent.css";

function VideoComponent({youtubeLink}) {
  return (
    <div className="youtube-zone">
      <div className="mobile-youtube-header">
        <div className="mobile-youtube-title">소개영상</div>
      </div>
      <div className="video-zone">
        <iframe
          src={`https://www.youtube.com/embed/${youtubeLink}`}
          title="YouTube video player"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default VideoComponent;
