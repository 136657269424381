import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import DetailMenu from "user/commons/DetailMenu";
import DetailCategory from "user/story/commons/DetailCategory";
import FirstzoneContents from "user/withfirstzone/commons/FirstzoneContents";
import Banner from 'user/commons/Banner';
import "user/withfirstzone/First.css";
import Loading from 'user/commons/Loading';
import PageLayout from 'user/PageLayout';
import ErrorPageApi from "user/commons/ErrorPageApi";

function First() {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const initialMenu = queryParams.get('selectedMenu') || 'firstzone';  // 기본값을 'notice'로 설정
    const initialCategory = '전체'; // 기본값 '전체'로 설정

    const [selectedMenu, setSelectedMenu] = useState(initialMenu);
    const [selectedCategory, setSelectedCategory] = useState(initialCategory); // '전체'로 초기화된 선택된 카테고리 상태
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [categoryName, setCategoryName] = useState('');

    const [isError, setIsError] = useState(false);  // 에러 상태

    // 메뉴 변경 시 URL 업데이트
    const handleMenuChange = (menu) => {
        setSelectedMenu(menu);
        setSelectedCategory('전체'); // 메뉴 변경 시 카테고리 리셋
        setCategoryName(menu);
        navigate(`?selectedMenu=${menu}`);
    };
    
    // menuMapping에 따라 카테고리 이름 설정
    useEffect(() => {
        const menuMapping = {
            'notice': '공지사항',
            'faq': '자주묻는질문'
        };
        if (menuMapping[initialMenu]) {
            setCategoryName(menuMapping[initialMenu]);
        }
    }, [initialMenu]);

    if (isError) {
        return <ErrorPageApi />;
    }

    return (
        <div>
            <PageLayout setIsError={setIsError}>
                <Banner category="withfirstzone"/>
                <DetailMenu 
                    selectedMenu={selectedMenu}
                    setselectedMenu={handleMenuChange}
                    visibleMenus={['firstzone', 'firstmap', 'notice', 'faq']} 
                    setCategoryName={setCategoryName}
                />
                {(selectedMenu === 'notice' || selectedMenu === 'faq') && (
                    <DetailCategory 
                        selectedMenu={selectedMenu}
                        selectedCategory={selectedCategory} // '전체'로 초기화된 값 전달
                        setSelectedCategory={setSelectedCategory}
                        setCategoryDetails={setCategoryDetails}
                        categoryName={categoryName}
                        setIsError={setIsError}
                    />
                )}
                {!isLoading ? (
                    <FirstzoneContents 
                        selectedMenu={selectedMenu}
                        setSelectedMenu={handleMenuChange}
                        categoryDetails={categoryDetails}
                        selectedCategory={selectedCategory}
                        setIsError={setIsError}
                    />
                ) : (
                    <Loading />
                )}
            </PageLayout>
        </div>
    );
}

export default First;
