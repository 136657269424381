import React from 'react';
import "user/commons/Loading.css";

function Loading() {
    return (
        <div>
            <div className="loading">
                <div className="loading-spinner"></div>
            </div>
        </div>
    );
}

export default Loading;