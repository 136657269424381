import React, { useRef } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "user/main/swiper/FacilitySwiperComponent.css";
import prev from "images/icon_prev.png";
import next from "images/icon_next.png";

function FacilitySwiperComponent({ facilityImages = [] }) {
  // const facilityTxt = [
  //   "사진1 설명",
  //   "사진2 설명",
  //   "사진3 설명",
  //   "사진4 설명",
  //   "사진5 설명",
  //   "사진6 설명",
  //   "사진7 설명",
  //   "사진8 설명",
  // ];
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="swiper-container">
      <div ref={prevRef} className="custom-swiper-prev">
        <img src={prev} alt="이전" className="prev-icon" />
      </div>
      <div ref={nextRef} className="custom-swiper-next">
        <img src={next} alt="다음" className="next-icon" />
      </div>
      <div className="facility-img-zone">
        <Swiper
          className="facility-web"
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={facilityImages.length < 4 ? facilityImages.length : 4}
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          loop={true}
          pagination={{ clickable: true }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          breakpoints={{
            1600: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 3,
            },
            1023: {
              slidesPerView: 2,
            },
          }}
        >
          {facilityImages.map((img, index) => (
            <SwiperSlide key={index}>
              <div className="img-card">
                <div className="img-zone">
                  <img src={`/uploads/${img}`} alt={`이미지 ${index}`} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* 모바일 버전 스와이프 */}
        <Swiper
          className="facility-mobile"
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
          loop={true}
          pagination={{ clickable: true }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {facilityImages.map((img, index) => (
            <SwiperSlide key={index}>
              <div className="img-card">
                <div className="img-zone">
                  <img src={`/uploads/${img}`} alt={`이미지 ${index}`} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default FacilitySwiperComponent;
