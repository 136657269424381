import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import calendar from 'images/icon-calendar.svg';
import { useEffect, useState, useMemo, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import DdayTimer from 'user/courseDetail/component/DdayTimer';
import ShareModal from "user/courseDetail/component/ShareModal";
import "user/courseDetail/component/RegisterSection.css";

const APPLICATION_STATUS = {
    BEFORE: { status: '모집 예정', enabled: false, timerType: 'start' },
    DURING: { status: '수강신청', enabled: true, timerType: 'end' },
    AFTER: { status: '모집 마감', enabled: false, timerType: 'none' }
};

// dayjs 설정
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');

// 날짜 표시 컴포넌트
const DateDisplay = memo(({ icon, label, date, className }) => (
    <div className="course-detail-date">
        <img src={icon} alt={label} className="icon-calendar" />
        <p className={className}>{`${label} ${date}`}</p>
    </div>
));

// 수강신청 섹션 컴포넌트
function RegisterSection({ outline, courseType, handleTimerReady }) {
    // 수강신청 상태 관리
    const [applicationState, setApplicationState] = useState({
        isEnabled: true,
        status: APPLICATION_STATUS.DURING.status,
        timerType: APPLICATION_STATUS.DURING.timerType
    });

    const navigate = useNavigate();

    // 날짜 포맷팅 함수
    const formatDate = useCallback((date) => {
        if (!date) return '';
        return date.slice(0,10).replace(/-/g, '.');
    }, []);

    // 현재 시간과 신청 기간 계산 
    const dates = useMemo(() => ({
        current: dayjs().tz(),
        applicationStart: outline?.sessionApplicationStartDate && dayjs(outline.sessionApplicationStartDate).tz(),
        applicationEnd: outline?.sessionApplicationEndDate && dayjs(outline.sessionApplicationEndDate).tz()
    }), [outline?.sessionApplicationStartDate, outline?.sessionApplicationEndDate]);

    // 수강신청 상태 업데이트 
    useEffect(() => {
        try {
            if (!dates.applicationStart || !dates.applicationEnd) return;

            // 현재 시간을 기준으로 수강신청 상태 결정
            const newState = dates.applicationStart.isAfter(dates.current)
                ? APPLICATION_STATUS.BEFORE
                : dates.applicationEnd.isBefore(dates.current)
                    ? APPLICATION_STATUS.AFTER
                    : APPLICATION_STATUS.DURING;

            setApplicationState({
                isEnabled: newState.enabled,
                status: newState.status,
                timerType: newState.timerType
            });
        } catch (error) {
        }
    }, [dates]);

    // 네비게이션 핸들러 최적화
    const handleGoRegisterPage = useCallback(() => {
        if (courseType === 'training') {
            navigate(`/${courseType}/registration/inputinfo/${outline.sessionId}`);
        } else {
            navigate(`/${courseType}/registration/inputresume/${outline.sessionId}`);
        }
    }, [courseType, outline.sessionId, navigate]);

    // 컴포넌트 렌더링
    if (!outline) return null;

    return (
        <div className="register-section">
            <div className="register-section-container">
                {/* 과정 정보 영역 */}
                <div className="register-content">
                    <h1 className="course-detail-title">{outline.sessionName}</h1>
                    {/* 날짜 정보 표시 영역 */}
                    <div className="course-info">
                        <DateDisplay 
                            icon={calendar}
                            label="모집기간"
                            date={`${formatDate(outline.sessionApplicationStartDate?.slice(0,10))} ~ ${formatDate(outline.sessionApplicationEndDate?.slice(0,10))}`}
                            className="recruitment-period"
                        />
                        <DateDisplay 
                            icon={calendar}
                            label="교육기간"
                            date={`${formatDate(outline.sessioncourseStartDate)} ~ ${formatDate(outline.sessioncourseEndDate)}`}
                            className="education-period"
                        />
                    </div>
                </div>
                {/* 수강신청 버튼 영역 */}
                <div className="register-right">
                    <div className="register-nav-right">
                        {/* D-day 타이머 표시 */}
                        {applicationState.timerType !== 'none' && (
                            <p className="deadline">
                                {applicationState.timerType === 'start' ? '모집까지' : '마감까지'}{' '}
                                <span className="ddayTimer">
                                    <DdayTimer 
                                        targetTime={
                                            applicationState.timerType === 'start' 
                                                ? outline.sessionApplicationStartDate 
                                                : outline.sessionApplicationEndDate
                                        }
                                        onReady={handleTimerReady}
                                        timerType={applicationState.timerType}
                                    />
                                </span>
                            </p>
                        )}
                        {/* 공유 및 수강신청 버튼 */}
                        <div className="register-btn-share-container">
                            <ShareModal content={outline} />
                            <button 
                                className={`register-btn ${!applicationState.isEnabled ? 'disabled' : ''}`}
                                onClick={applicationState.isEnabled ? handleGoRegisterPage : undefined}
                                disabled={!applicationState.isEnabled}
                            >
                                {applicationState.status}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterSection;
