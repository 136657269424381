import React, { useEffect, useState } from "react";
import "admin/jobTraining/JobTraining.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { call } from "service/ApiService";

function VideoStoryList(props) {
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [courses, setCourses] = useState([]);
  const [checkItem, setCheckItem] = useState([]); //체크 아이템 배열
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // 처음 페이지 로드될 때
  useEffect(() => {
    const pageFromParams = parseInt(searchParams.get("page")) - 1 || 0;
    const categoryFromParams = searchParams.get("category");

    call("/api/v1/category/select", "GET", { categoryName: "영상스토리" })
      .then((response) => {
        setCategories(response);

        setCurrentPage(parseInt(pageFromParams)); // 페이지 설정
        const selectedCategory = response.find(
          (category) =>
            category.categoryDetailId === parseInt(categoryFromParams)
        );

        if (selectedCategory) {
          setVisible({
            key: selectedCategory.categoryDetailId,
            value: selectedCategory.categoryDetailName,
          });
        }
      })
      .catch();
  }, []);

  // url에 param 데이터 변경될 때(category, page)
  useEffect(() => {
    const pageFromParams = parseInt(searchParams.get("page")) - 1 || 0;
    const categoryFromParams = searchParams.get("category");

    fetchInitialData(categoryFromParams, pageFromParams);
  }, [searchParams]);

  // 목록 조회된 후 재렌더
  useEffect(() => {}, [courses]);

  const fetchInitialData = async (categoryFromParams, pageFromParams) => {
    if (!categoryFromParams) {
      // URL에 카테고리가 없을 때
      setVisible({ key: "", value: "" }); // 빈 카테고리 설정
      setCurrentPage(pageFromParams); // 첫 페이지 설정
      fetchCourses(undefined, pageFromParams); // 첫 페이지의 코스 조회
    } else {
      // URL에 카테고리가 있을 때
      setCurrentPage(pageFromParams); // 페이지 설정
      const selectedCategory = categories.find(
        (category) => category.categoryDetailId === parseInt(categoryFromParams)
      );

      if (selectedCategory) {
        setVisible({
          key: selectedCategory.categoryDetailId,
          value: selectedCategory.categoryDetailName,
        });
      }
      fetchCourses(categoryFromParams, pageFromParams); // 카테고리가 있을 때만 코스 조회
    }
  };

  // 3. 공통 API 호출 함수
  const fetchCourses = (category, currentPage) => {
    var options = { page: currentPage ? currentPage : 0, size: 10 };

    if (category !== undefined && category !== null && category !== 0) {
      options.category = category; // key가 0이 아닐 때만 추가
    }

    call("/api/v1/admin/video-story", "GET", options)
      .then((response) => {
        const formattedCourses = response.content.map((course) => {
          const formattedCreateDate = course.videoStoryCreateDate
            ? course.videoStoryCreateDate.split("T")[0]
            : ""; // 'YYYY-MM-DD' 형식으로 변환
          const formattedModifiedDate = course.videoStoryUpdateDate
            ? course.videoStoryUpdateDate.split("T")[0]
            : ""; // 'YYYY-MM-DD' 형식으로 변환

          return {
            ...course,
            videoStoryCreateDate: formattedCreateDate, // 날짜 필드 포맷
            videoStoryUpdateDate: formattedModifiedDate, // 수정일도 같은 방식으로 처리
          };
        });

        setCourses(formattedCourses);
        setTotalPage(response.totalPages);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  //단일 체크
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItem((prev) => [...prev, id]); //체크 아이템을 배열에 추가
    } else {
      setCheckItem(checkItem.filter((el) => el !== id)); //선택해제시 요소 제외
    }
  };

  //전체체크
  const handleAllCheck = (checked) => {
    if (checked) {
      //전체선택시 전체 아이템을 담은 배열로 업데이트
      const idArray = courses.map((course) => course.videoStoryId);
      setCheckItem(idArray);
    } else {
      setCheckItem([]); //새로운 빈 배열로 업데이트
    }
  };

  const handelDeleteVideoStory = () => {
    var options = {
      videoStoryIds: checkItem,
    };
    call("/api/v1/admin/video-story", "DELETE", options)
      .then((response) => {
        alert(response.message);
        fetchCourses(0);
      })
      .catch((error) => alert(error.message));
  };

  const handleVisibleChange = (e) => {
    const selectedKey = e.target.selectedOptions[0].getAttribute("data-key");
    setVisible({ key: selectedKey, value: e.target.value });

    // 선택된 카테고리를 URL에 업데이트

    selectedKey !== ""
      ? setSearchParams({ category: selectedKey })
      : setSearchParams({});
  };

  const handleVideoChange = (videoStoryId) => {
    var data = {
      categories: categories,
      category: visible.key,
      currentPage: currentPage + 1,
    };
    navigate("/admin/story/modifyvideostory/" + videoStoryId, { state: data });
  };

  const handleAddVideoChange = () => {
    var data = { categories: categories };
    navigate("/admin/story/addvideostory", { state: data });
  };

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return; // 유효성 검사
    setCurrentPage(page); // 현재 페이지를 업데이트합니다.

    setSearchParams(
      visible.key !== undefined
        ? { category: visible.key, page: page + 1 }
        : { page: page + 1 }
    );
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수

    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);

    // 계산된 페이지 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">Story {" > "} 영상 스토리</div>
        <div className="main-content">
          <div className="title">영상 스토리 목록</div>
          <div className="subtitle">
            현재 영상 스토리 탭에 등록 되어있는 글 목록입니다
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">카테고리</div>
              <select
                className="drop-down-menu"
                value={visible.value || ""}
                onChange={handleVisibleChange}
              >
                <option key="" value="" data-key="">
                  선택하세요
                </option>
                {categories.map((category) => (
                  <option
                    key={category.categoryDetailId}
                    value={category.categoryDetailName}
                    data-key={category.categoryDetailId}
                  >
                    {category.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      checked={checkItem.length === courses.length}
                    />
                  </th>
                  <th className="date-td">카테고리</th>
                  <th className="thumbnail-td">썸네일</th>
                  <th className="course-name">제목</th>
                  <th>작성일</th>
                  <th>수정일</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.videoStoryId}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleSingleCheck(
                            e.target.checked,
                            course.videoStoryId
                          )
                        }
                        checked={checkItem.includes(course.videoStoryId)}
                      />
                    </td>
                    <td>{course.videoStoryCategoryName}</td>
                    <td>
                      <img
                        src={
                          course.videoStoryImage
                            ? "/uploads/" + course.videoStoryImage
                            : "https://img.youtube.com/vi/" +
                              course.videoStoryYoutubeId +
                              "/mqdefault.jpg"
                        }
                        alt="썸네일"
                        className="list-img"
                      />
                    </td>
                    <td
                      onClick={() => handleVideoChange(course.videoStoryId)}
                      className="list-img-row"
                    >
                      {course.videoStoryTitle}
                    </td>
                    <td className="date-td">{course.videoStoryCreateDate}</td>
                    <td className="date-td">{course.videoStoryUpdateDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="button-container">
            <button
              type="submit"
              className="deleteBtn"
              onClick={handelDeleteVideoStory}
            >
              삭제
            </button>
            <button
              type="submit"
              className="submitBtn"
              onClick={handleAddVideoChange}
            >
              등록
            </button>
          </div>
          <div className="pagination-zone">
            <button
              onClick={() => handlePageChange(0)}
              disabled={currentPage === 0}
            >
              {"<<"}
            </button>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            >
              {"<"}
            </button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() =>
                  typeof page === "number" && handlePageChange(page)
                }
                style={{ fontWeight: page === currentPage ? "bold" : "normal" }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPage - 1}
            >
              {">"}
            </button>
            <button
              onClick={() => handlePageChange(totalPage - 1)}
              disabled={currentPage === totalPage - 1}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoStoryList;
