import React, { useState, useEffect } from "react";
import "admin/jobTraining/JobTraining.css";
import { call } from "service/ApiService";
import { useNavigate } from "react-router-dom";

function JobTrainingList(props) {
  const [visible, setVisible] = useState(""); // 기본값 설정
  const [elementCount, setElementCount] = useState(10); // 기본값 설정
  const [menu, setMenu] = useState([]); // 카테고리 배열
  const [courses, setCourses] = useState([]);
  const [checkItem, setCheckItem] = useState([]); // 체크된 아이템 배열
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1); // 총 페이지 수 상태 추가
  const navigate = useNavigate();

  // 카테고리 불러오기
  useEffect(() => {
    call('/api/v1/category/select?categoryName=직무', 'GET')
      .then((response) => {
        setMenu(response || []);
      })
      .catch((error) => alert('카테고리 조회에 실패했습니다'));
  }, []);

  // 훈련 리스트 불러오기
  const fetchCourses = (page = 0, size = elementCount) => {
    const options = { page, size, categoryId: visible };
    
    if(visible === null || visible === 0){
      visible = "";
      
    }

    call('/api/v1/admin/training-course/job', 'GET', options)
      .then((response) => {
        const formattedCourses = response.content.map((course) => ({
          ...course,
          courseCreateTime: course.courseCreateTime.split('T')[0],  // 'YYYY-MM-DD' 형식으로 변환
          courseUpdateTime: course.courseUpdateTime.split('T')[0],  // 'YYYY-MM-DD' 형식으로 변환
        }));
        
        setCourses(formattedCourses);
        setTotalPage(response.totalPages);
      })
      .catch(() => alert('코스 불러오기에 실패했습니다.'));
  };

  // 컴포넌트가 렌더링될 때 실행
  useEffect(() => {
    setCurrentPage(0);
    fetchCourses();
  }, [visible, elementCount]);

  // 단일 체크
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItem((prev) => [...prev, id]); // 체크 아이템을 배열에 추가
    } else {
      setCheckItem(checkItem.filter((el) => el !== id)); // 선택 해제 시 요소 제외
    }
  };

  // 전체 체크
  const handleAllCheck = (checked) => {
    if (checked) {
      const idArray = courses.map((course) => course.courseId); // courseId로 설정
      setCheckItem(idArray);
    } else {
      setCheckItem([]);
    }
  };

  // 카테고리 선택 변경
  const handleVisibleChange = (e) => {
    setVisible(e.target.value);
  };

  // 페이지당 항목 수 변경
  const handleElementCountChange = (e) => {
    setElementCount(parseInt(e.target.value));
  };

   // 페이지 변경 핸들러
   const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return; // 유효성 검사
    setCurrentPage(page); // 현재 페이지를 업데이트합니다.
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수
  
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);
    // 계산된 페이지 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  const handleRegistChange = () => {
    navigate("/admin/jobtraining/addjobtraining");
  };
  const handleRegistModifyChange=(courseId)=>{
    navigate("/admin/jobtraining/modifyjobtraining/"+courseId);
  }

  // 삭제 처리 함수 수정
  const handleDelete = async () => {
    if (checkItem.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }

    if (window.confirm('삭제를 할 경우 개설중인 훈련과정과 지원자 데이터가 삭제됩니다.\n그래도 삭제 하시겠습니까?')) {
      try {
        // 선택된 모든 항목에 대해 삭제 요청
        for (const courseId of checkItem) {
          await call(`/api/v1/admin/training-course/${courseId}`, 'DELETE');
        }
        
        alert('삭제가 완료되었습니다.');
        setCheckItem([]); // 체크 항목 초기화
        fetchCourses(currentPage); // 목록 새로고침
      } catch (error) {
        alert('삭제 처리 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">직무 훈련 과정 {" > "} 직무 훈련 목록</div>
        <div className="main-content">
          <div className="title">직무 훈련 목록</div>
          <div className="subtitle">
            현재 등록 되어있는 직무 훈련 목록입니다
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">카테고리</div>
              <select
                className="drop-down-menu"
                value={visible}
                onChange={handleVisibleChange}
              >
                <option value="">전체</option>
                {menu.map((item) => (
                  <option key={item.categoryDetailId} value={item.categoryDetailId}>
                    {item.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">보기</div>
              <select
                className="drop-down-menu"
                value={elementCount}
                onChange={handleElementCountChange}
              >
                <option value={10}>10개씩 보기</option>
                <option value={20}>20개씩 보기</option>
                <option value={50}>50개씩 보기</option>
                <option value={100}>100개씩 보기</option>
              </select>
            </div>
          </div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      checked={checkItem.length === courses.length}
                    />
                  </th>
                  <th>카테고리</th>
                  <th className="course-name">과정명</th>
                  <th>최초 등록일</th>
                  <th>수정일</th>
                </tr>
              </thead>
              <tbody>
              {courses.map((course) => (
                  <tr key={course.courseId}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleSingleCheck(e.target.checked, course.courseId)
                        }
                        checked={checkItem.includes(course.courseId)}
                      />
                    </td>
                    <td>{course.courseCategoryName}</td>
                    <td className="innerText" onClick={() => handleRegistModifyChange(course.courseId)}>{course.courseName}</td>
                    <td className="date-td">{course.courseCreateTime}</td>
                    <td className="date-td">{course.courseUpdateTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="button-container">
            <button type="submit" className="deleteBtn" onClick={handleDelete}>
              삭제
            </button>
            <button
              type="submit"
              className="submitBtn"
              onClick={handleRegistChange}
            >
              등록
            </button>
          </div>
          <div className="pagination-zone">
            <button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>{"<<"}</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>{"<"}</button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageChange(page)}
                style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage - 1}>{">"}</button>
            <button onClick={() => handlePageChange(totalPage - 1)} disabled={currentPage === totalPage - 1}>{">>"}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobTrainingList;
