import React, { useEffect, useState, useRef } from "react";
import fzLogo from "images/fzAcademy_Logo.png";
import icon from "images/sidebar_icon.png";
import story from "images/Story.png";
import setting from "images/setting.png";
import home from "images/home.png";
import logout from "images/logout.png";
import { Link, useLocation } from "react-router-dom";
import { call } from "service/ApiService";

function Sidebar(props) {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);
  const [submenuHeights, setSubmenuHeights] = useState({}); // 서브메뉴 높이 상태 추가
  const submenuRefs = useRef({}); // 각 서브메뉴 참조를 저장

  useEffect(() => {
    if (location.pathname.includes("/admin/sitecontent")) {
      setActiveMenu("siteContent");
    } else if (location.pathname.includes("/admin/employTraining")) {
      setActiveMenu("employTraining");
    } else if (location.pathname.includes("/admin/jobTraining")) {
      setActiveMenu("jobTraining");
    } else if (location.pathname.includes("/admin/story")) {
      setActiveMenu("story");
    } else if (location.pathname.includes("/admin/withfirstzone")) {
      setActiveMenu("withFirstzone");
    } else if (location.pathname.includes("/admin/setting")) {
      setActiveMenu("setting");
    }
  }, [location]);

  const toggleMenu = (menu) => {
    setActiveMenu((prev) => (prev === menu ? null : menu));
    if (submenuRefs.current[menu]) {
      setSubmenuHeights((prev) => ({
        ...prev,
        [menu]: submenuRefs.current[menu].scrollHeight,
      }));
    }
  };

  const handleLogout = () => {
    call('/api/v1/admin/logout', 'GET')
      .then(response => {
        alert(response.message);
      })
      .catch(error => {
        alert(error.message);
      });
    sessionStorage.removeItem('isLogin');
    window.location.href = '/adminlogin';
  };

  return (
    <aside className="sidebar">
      <div className="logo">
        <Link to="/admin">
          <img className="fzlogo" src={fzLogo} alt="fz로고" />
        </Link>
      </div>
      <div className="menu">
        <div
          className={`menu-item ${activeMenu === "siteContent" ? "active" : ""}`}
        >
          <h3 onClick={() => toggleMenu("siteContent")}>
            <img className="sidebar-icon" src={icon} alt="아이콘" />
            사이트 컨텐츠
          </h3>
          <ul
            className="submenu"
            ref={(el) => (submenuRefs.current["siteContent"] = el)}
            style={{
              maxHeight: activeMenu === "siteContent" ? `${submenuHeights.siteContent}px` : "0",
              transition: "max-height 0.3s ease",
            }}
          >
            <li
              className={location.pathname === "/admin/sitecontent/mainpage" ? "selected" : ""}
            >
              <Link to="/admin/sitecontent/mainpage" className="menu-link">메인페이지</Link>
            </li>
            <li
              className={location.pathname === "/admin/sitecontent/firstzone" ? "selected" : ""}
            >
              <Link to="/admin/sitecontent/firstzone" className="menu-link">퍼스트존</Link>
            </li>
            <li
              className={location.pathname === "/admin/sitecontent/firstmap" ? "selected" : ""}
            >
              <Link to="/admin/sitecontent/firstmap" className="menu-link">퍼스트맵</Link>
            </li>
            <li
              className={location.pathname === "/admin/sitecontent/footer" ? "selected" : ""}
            >
              <Link to="/admin/sitecontent/footer" className="menu-link">하단바</Link>
            </li>
          </ul>
        </div>

        <div
          className={`menu-item ${activeMenu === "employTraining" ? "active" : ""}`}
        >
          <h3 onClick={() => toggleMenu("employTraining")}>
            <img className="sidebar-icon" src={icon} alt="아이콘" />
            취업 훈련 과정
          </h3>
          <ul
            className="submenu"
            ref={(el) => (submenuRefs.current["employTraining"] = el)}
            style={{
              maxHeight: activeMenu === "employTraining" ? `${submenuHeights.employTraining}px` : "0",
              transition: "max-height 0.3s ease",
            }}
          >
            <li
              className={location.pathname === "/admin/employtraining/employlist" ? "selected" : ""}
            >
              <Link to="/admin/employtraining/employlist" className="menu-link">취업 훈련 목록</Link>
            </li>
            <li
              className={location.pathname === "/admin/employtraining/emppracticelist" ? "selected" : ""}
            >
              <Link to="/admin/employtraining/emppracticelist" className="menu-link">취업 훈련 실시 목록</Link>
            </li>
            <li
              className={location.pathname === "/admin/employtraining/empvolunteerlist" ? "selected" : ""}
            >
              <Link to="/admin/employtraining/empvolunteerlist" className="menu-link">취업 훈련 지원자 관리</Link>
            </li>
          </ul>
        </div>

        <div
          className={`menu-item ${activeMenu === "jobTraining" ? "active" : ""}`}
        >
          <h3 onClick={() => toggleMenu("jobTraining")}>
            <img className="sidebar-icon" src={icon} alt="아이콘" />
            직무 훈련 과정
          </h3>
          <ul
            className="submenu"
            ref={(el) => (submenuRefs.current["jobTraining"] = el)}
            style={{
              maxHeight: activeMenu === "jobTraining" ? `${submenuHeights.jobTraining}px` : "0",
              transition: "max-height 0.3s ease",
            }}
          >
            <li
              className={location.pathname === "/admin/jobtraining/traininglist" ? "selected" : ""}
            >
              <Link to="/admin/jobtraining/traininglist" className="menu-link">직무 훈련 목록</Link>
            </li>
            <li
              className={location.pathname === "/admin/jobtraining/practicelist" ? "selected" : ""}
            >
              <Link to="/admin/jobtraining/practicelist" className="menu-link">직무 훈련 실시 목록</Link>
            </li>
            <li
              className={location.pathname === "/admin/jobtraining/volunteerlist" ? "selected" : ""}
            >
              <Link to="/admin/jobtraining/volunteerlist" className="menu-link">직무 훈련 지원자 관리</Link>
            </li>
          </ul>
        </div>

        <div
          className={`menu-item ${activeMenu === "story" ? "active" : ""}`}
        >
          <h3 onClick={() => toggleMenu("story")}>
            <img className="sidebar-icon" src={story} alt="아이콘" />
            Story
          </h3>
          <ul
            className="submenu"
            ref={(el) => (submenuRefs.current["story"] = el)}
            style={{
              maxHeight: activeMenu === "story" ? `${submenuHeights.story}px` : "0",
              transition: "max-height 0.3s ease",
            }}
          >
            <li
              className={location.pathname === "/admin/story/eyetechlist" ? "selected" : ""}
            >
              <Link to="/admin/story/eyetechlist" className="menu-link">아이테크</Link>
            </li>
            <li
              className={location.pathname === "/admin/story/videostorylist" ? "selected" : ""}
            >
              <Link to="/admin/story/videostorylist" className="menu-link">영상 스토리</Link>
            </li>
            <li
              className={location.pathname === "/admin/story/bloglist" ? "selected" : ""}
            >
              <Link to="/admin/story/bloglist" className="menu-link">블로그</Link>
            </li>
          </ul>
        </div>

        <div
          className={`menu-item ${activeMenu === "withFirstzone" ? "active" : ""}`}
        >
          <h3 onClick={() => toggleMenu("withFirstzone")}>
            <img className="sidebar-icon" src={icon} alt="아이콘" />
            With 퍼스트존
          </h3>
          <ul
            className="submenu"
            ref={(el) => (submenuRefs.current["withFirstzone"] = el)}
            style={{
              maxHeight: activeMenu === "withFirstzone" ? `${submenuHeights.withFirstzone}px` : "0",
              transition: "max-height 0.3s ease",
            }}
          >
            <li
              className={location.pathname === "/admin/withFirstzone/noticelist" ? "selected" : ""}
            >
              <Link to="/admin/withFirstzone/noticelist" className="menu-link">공지사항</Link>
            </li>
            <li
              className={location.pathname === "/admin/withFirstzone/faqlist" ? "selected" : ""}
            >
              <Link to="/admin/withFirstzone/faqlist" className="menu-link">FAQ</Link>
            </li>
            <li
              className={location.pathname === "/admin/withFirstzone/qnalist" ? "selected" : ""}
            >
              <Link to="/admin/withFirstzone/qnalist" className="menu-link">문의사항</Link>
            </li>
          </ul>
        </div>

        <div
          className={`menu-item ${activeMenu === "setting" ? "active" : ""}`}
        >
          <h3 onClick={() => toggleMenu("setting")}>
            <img className="sidebar-icon" src={setting} alt="아이콘" />
            설정
          </h3>
          <ul
            className="submenu"
            ref={(el) => (submenuRefs.current["setting"] = el)}
            style={{
              maxHeight: activeMenu === "setting" ? `${submenuHeights.setting}px` : "0",
              transition: "max-height 0.3s ease",
            }}
          >
            <li
              className={location.pathname === "/admin/setting/categorylist" ? "selected" : ""}
            >
              <Link to="/admin/setting/categorylist" className="menu-link">카테고리</Link>
            </li>
            <li
              className={location.pathname === "/admin/setting/modifybanner" ? "selected" : ""}
            >
              <Link to="/admin/setting/modifybanner" className="menu-link">배너 수정</Link>
            </li>
            <li
              className={location.pathname === "/admin/setting/changepw" ? "selected" : ""}
            >
              <Link to="/admin/setting/changepw" className="menu-link">관리자 비밀번호 변경</Link>
            </li>
            <li
              className={location.pathname === "/admin/setting/changeinfo" ? "selected" : ""}
            >
              <Link to="/admin/setting/changeinfo" className="menu-link">관리자 정보 변경</Link>
            </li>
          </ul>
        </div>

      </div>
      <div className="bottom-menu">
        <div className="menu-item">
          <h3>
            <img className="sidebar-icon" src={home} alt="아이콘" />
            <Link to="/" className="menu-link">홈페이지로 이동</Link>
          </h3>
        </div>
        <div className="menu-item">
          <h3>
            <img className="sidebar-icon" src={logout} alt="아이콘" />
            <Link className="menu-link" onClick={handleLogout}>로그아웃</Link>
          </h3>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
