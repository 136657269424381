import React, { useState } from "react";
import "user/main/Notice.css";
import { useNavigate } from "react-router-dom";
import { Viewer } from "@toast-ui/react-editor";

function Notice({mainNotice}) {
  const [openNotices, setOpenNotices] = useState({});

  const navi = useNavigate();

  const handleMoreNotice = () => {
    navi("/withfirstzone?selectedMenu=notice");
  };

  const toggleNotice = (id) => {
    setOpenNotices(prevState => ({
        ...prevState,
        [id]: !prevState[id] // 공지사항의 id에 따라 열림/닫힘 상태를 토글
    }));
};

  return (
    <div className="notice-zone">
      <div className="notice-header">
        <div className="more-notice" onClick={handleMoreNotice}>
          + 더보기
        </div>
      </div>
      <div className="mobile-notice-header">
        <div className="mobile-notice-title">공지사항</div>
      </div>
      <div className="accordion-notice">
        {mainNotice.content?.map((notice) => (
          <div key={notice.noticeId} className='notice-list'>
          <div className='notice-list-header' onClick={() => toggleNotice(notice.noticeId)}>
              <span
                  className={`notice-list-title ${openNotices[notice.noticeId] ? 'open-title' : ''}`}
              >
                  {notice.noticeTitle}
              </span>
              <div className='notice-list-toggle-button'>
                  <span className={openNotices[notice.noticeId] ? "notice-list-toggle-open" : "notice-list-toggle-close"} >+</span>
              </div>
          </div>

          {openNotices[notice.noticeId] && (
              <div className='notice-list-content'>
                  <p><Viewer initialValue= {notice.noticeContent}/></p>
                  <span>
                      {new Date(notice.noticeCreateDate).toISOString().slice(0, 10)}
                  </span>
              </div>
          )}
      </div>
        ))}
      </div>
      <div className="mobile-add-notice" onClick={handleMoreNotice}>+ 더보기</div>
    </div>
  );
}

export default Notice;
