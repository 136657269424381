import camera from "images/icon_camera.svg";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { call } from "service/ApiService";
import Loading from "user/commons/Loading";
import AgreementBox from "user/register/component/AgreementBox";
import InputBox from "user/register/component/InputBox";
import RegisterBtn from "user/register/component/RegisterBtn";
import "user/register/employTraining/InputInfo.css";
import { useMember } from "user/register/RegisterPage";

// 에러 메시지 상수 정의
const ERROR_MESSAGES = {
    REQUIRED_FIELD: (fieldName) => `${fieldName} 필수 입력 항목입니다.`,
    UPLOAD_FAILED: "수강신청에 실패했습니다.",
    INVALID_FILE_TYPE: "이미지 파일만 업로드 가능합니다. (jpg, jpeg, png, gif)",
    PREVIOUS_PAGE: "이전 페이지로 이동합니다.",
    API_ERROR: "서버 통신 중 오류가 발생했습니다."
};

// 파일 타입 및 필수 동의 항목 상수
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
const REQUIRED_AGREEMENTS = ["employIsCondition", "employIsInformation", "employIsProvide"];
const CATEGORIES = ["이메일", "최종학력", "지원동기", "실직기간", "최초정보습득경로", "병역관계"];

function InputInfo() {
    // Context와 상태 관리
    const { userInfo, setUserInfo, handleChange, setIsError } = useMember();
    const [highlightImageUpload, setHighlightImageUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imgFile, setImgFile] = useState(null);
    const [requiredErrorMsg, setRequiredErrorMsg] = useState({});
    const [checkList, setCheckList] = useState([]);
    const [isNextEnabled, setIsNextEnabled] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState({
        emailList: [],
        educationList: [],
        reasonList: [],
        unemployPeriodList: [],
        infoList: [],
        militaryList: []
    });

    // refs 선언
    const selectFile = useRef(null);
    const photoUploadRef = useRef(null);
    const inputRefs = useRef({});
    
    // 라우터 파라미터
    const { courseType, sessionId } = useParams();
    const navi = useNavigate();

    // 필수 필드 및 레이블 정의 (메모이제이션)
    const { requiredFields, fieldLabels } = useMemo(() => ({
        requiredFields: [
            "employProfileImage", "employName", "employPhonenumber", "employBirth",
            "employGender", "employEmail", "employAddress", "employFinalEducation",
            "employReason", "unemployPeriod", "employInformationPath", "employIsMilitary"
        ],
        fieldLabels: {
            employProfileImage: "사진은",
            employName: "이름은",
            employPhonenumber: "연락처는",
            employBirth: "생년월일은",
            employGender: "성별은",
            employEmail: "이메일은",
            employAddress: "현주소는",
            employFinalEducation: "최종학력 구분은",
            employReason: "지원동기는",
            unemployPeriod: "실직기간은",
            employInformationPath: "최초 정보습득경로는",
            employIsMilitary: "병역관계는"
        }
    }), []);

    // 이메일 유효성 검사
    const validateEmail = useCallback((email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }, []);

    // 파일 유효성 검사
    const validateFile = useCallback((file) => {
        if (!file) return { isValid: false, error: ERROR_MESSAGES.REQUIRED_FIELD('파일') };
        if (!ALLOWED_FILE_TYPES.includes(file.type)) {
            return { isValid: false, error: ERROR_MESSAGES.INVALID_FILE_TYPE };
        }
        return { isValid: true, error: null };
    }, []);

    // 필수 필드 검증
    const checkRequiredFields = useCallback(() => {
        const allFieldsFilled = requiredFields.every(field => {
            if (field === 'employEmail') {
                return validateEmail(userInfo[field] || '');
            }
            return userInfo[field];
        });
        
        const allAgreementsChecked = REQUIRED_AGREEMENTS
            .every(key => checkList.includes(key));
        
        setIsNextEnabled(allFieldsFilled && allAgreementsChecked);
    }, [userInfo, checkList, requiredFields, validateEmail]);

    // 이미지 업로드 핸들러
    const handleUploadPhoto = useCallback(() => {
        selectFile.current?.click();
    }, []);

    // 이미지 미리보기 핸들러
    const handleShowPreviewImg = useCallback((e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const { isValid, error } = validateFile(file);
        if (!isValid) {
            alert(error);
            return;
        }

        if (imgFile) {
            URL.revokeObjectURL(imgFile);
        }

        const previewUrl = URL.createObjectURL(file);
        setImgFile(previewUrl);
        setUserInfo(prev => ({ ...prev, employProfileImage: file }));
        setHighlightImageUpload(false);
    }, [imgFile, validateFile, setUserInfo]);

    // 이미지 삭제 핸들러
    const handleDeletePhoto = useCallback(() => {
        if (imgFile) {
            URL.revokeObjectURL(imgFile);
        }
        setImgFile(null);
        setUserInfo(prev => ({ ...prev, employProfileImage: null }));
        if (selectFile.current) {
            selectFile.current.value = '';
        }
    }, [imgFile, setUserInfo]);

    // 카테고리 데이터 가져오기
    const fetchCategories = useCallback(async () => {
        try {
            const promises = CATEGORIES.map(category => 
                call('/api/v1/category/select', 'GET', { categoryName: category })
                    .catch(error => {
                        return [];
                    })
            );
            
            const [emailData, educationData, reasonData, periodData, infoData, militaryData] 
                = await Promise.all(promises);

            setDropdownOptions({
                emailList: emailData || [],
                educationList: educationData || [],
                reasonList: reasonData || [],
                unemployPeriodList: periodData || [],
                infoList: infoData || [],
                militaryList: militaryData || []
            });
        } catch (error) {
            setIsError(true);
            alert(ERROR_MESSAGES.API_ERROR);
        }
    }, [setIsError]);

    // 폼 제출 핸들러
    const handleSubmit = useCallback(async () => {
        if (!isNextEnabled) {
            const firstEmptyField = requiredFields.find(field => !userInfo[field]);
            setRequiredErrorMsg({});
            
            if (firstEmptyField === "employProfileImage") {
                setHighlightImageUpload(true);
                setRequiredErrorMsg({ 
                    employProfileImage: ERROR_MESSAGES.REQUIRED_FIELD(fieldLabels.employProfileImage) 
                });
                photoUploadRef.current?.scrollIntoView({ 
                    behavior: "smooth", 
                    block: "center" 
                });
            } else if (firstEmptyField) {
                setRequiredErrorMsg({ 
                    [firstEmptyField]: ERROR_MESSAGES.REQUIRED_FIELD(fieldLabels[firstEmptyField]) 
                });
                
                const targetRef = inputRefs.current[firstEmptyField];
                if (targetRef) {
                    targetRef.scrollIntoView({ behavior: "smooth", block: "center" });
                    if (firstEmptyField === 'employBirth') {
                        const datePickerContainer = targetRef.querySelector('.date-picker-input');
                        datePickerContainer?.click();
                    } else {
                        targetRef.focus();
                    }
                }
            }
            return;
        }

        setIsLoading(true);
        try {
            const formData = new FormData();
            Object.entries(userInfo).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    formData.append(key, value);
                }
            });
            
            await call('/api/v1/employ-training', 'POST', formData, 'multipart/form-data');
            navi(`/${courseType}/registration/success/${sessionId}`);
        } catch (error) {
            alert(ERROR_MESSAGES.UPLOAD_FAILED);
            navi(`/${courseType}/detail/${sessionId}`);
        } finally {
            setIsLoading(false);
        }
    }, [isNextEnabled, userInfo, courseType, sessionId, navi, requiredFields, fieldLabels]);

    // 필수 필드 검증 Effect
    useEffect(() => {
        checkRequiredFields();
    }, [userInfo, checkList, checkRequiredFields]);

    // 초기 데이터 로딩 Effect
    useEffect(() => {
        fetchCategories();
        setUserInfo(prev => ({ ...prev, sessionId }));      

        return () => {
            if (imgFile) {
                URL.revokeObjectURL(imgFile);
            }
        };
    }, []);

    // 페이지 로드 시 최상단으로 이동
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    // useEffect 추가
    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isLoading]);

    return (
        <div className='info-container'>
            {/* 로딩 오버레이 */}
            {isLoading && (
                <div className='loading-overlay'>
                    <Loading/>
                </div>
            )}
            
            {/* 기본 정보 섹션 */}
            <div className="info-section">
                <div className='info-title'>
                    <p className='info-title-text'>기본 정보</p>
                    <p><span className='star'>*</span> 표기된 항목은 필수입력 항목입니다.</p>
                </div>
                <div className="basic-info">
                    <div className="photo">
                        <div className={`photo-wrap ${highlightImageUpload ? 'highlight' : ''}`} ref={photoUploadRef}>
                            {imgFile ? (
                                <img src={imgFile} alt="미리보기 이미지" className="photo-file" />
                            ) : (
                                <>
                                    <img src={camera} alt="사진등록아이콘" className="icon-camera" />
                                    <div className="photo-square" />
                                </>
                            )}
                        </div>
                        <div className="photo-explain">
                            {requiredErrorMsg.employProfileImage && (
                                <p className="error-msg">{requiredErrorMsg.employProfileImage}</p>
                            )}
                            <p className="photo-size">권장사이즈: 3cm X 4cm</p>
                            <input
                                type="file"
                                ref={selectFile}
                                accept="image/*"
                                onChange={handleShowPreviewImg}
                                style={{ display: "none" }}
                            />
                            <div className="photo-button-wrap">
                                <button className="photo-button insert-button" onClick={handleUploadPhoto}>사진등록</button>
                                <button className="photo-button delete-button" onClick={handleDeletePhoto}>삭제</button>
                            </div>
                            
                        </div>
                    </div>
                    <div className="info-normal">
                        <InputBox 
                            inputLabel="이름" 
                            inputId="korean_name" 
                            inputName="employName" 
                            isRequired={true} 
                            half 
                            handleChange={handleChange}
                            ref={el => inputRefs.current.employName = el}
                            errorMsg={requiredErrorMsg.employName}
                        />
                        <InputBox 
                            inputLabel="연락처" 
                            inputId="phone_number" 
                            inputName="employPhonenumber"
                            type="tel" 
                            isRequired={true} 
                            half 
                            handleChange={handleChange}
                            ref={el => inputRefs.current.employPhonenumber = el}
                            errorMsg={requiredErrorMsg.employPhonenumber}
                        />
                        <InputBox 
                            inputLabel="생년월일" 
                            inputName="employBirth" 
                            type="date" 
                            isRequired 
                            half 
                            handleChange={handleChange}
                            ref={el => inputRefs.current.employBirth = el}
                            errorMsg={requiredErrorMsg.employBirth} />
                  
                        <InputBox 
                            inputLabel="성별" 
                            inputName="employGender" 
                            type="radio" 
                            isRequired 
                            half 
                            handleChange={handleChange}
                            ref={el => inputRefs.current.employGender = el}
                            errorMsg={requiredErrorMsg.employGender} />
                        <InputBox 
                            inputLabel="추가 연락처" 
                            inputName="employAddPhonenumber" 
                            type="tel" 
                            handleChange={handleChange}/>
                        <InputBox 
                            inputLabel="이메일" 
                            inputName="employEmail" 
                            type="email" 
                            isRequired 
                            list={dropdownOptions.emailList} 
                            handleChange={handleChange} 
                            ref={el => inputRefs.current.employEmail = el}
                            errorMsg={requiredErrorMsg.employEmail}/>
                        <InputBox 
                            inputLabel="주소" 
                            inputName="employAddress" 
                            type="address" 
                            isRequired 
                            handleChange={handleChange}
                            ref={el => inputRefs.current.employAddress = el}
                            errorMsg={requiredErrorMsg.employAddress} />
                    </div>
                </div>
            </div>

            {/* 학력 사항 섹션 */}
            <div className="info-section">
                <div className='info-title'>
                    <p className='info-title-text'>학력 사항</p>
                </div>
                <InputBox 
                    inputLabel="최종학력 구분" 
                    inputId="graduation-date" 
                    inputName="employFinalEducation" 
                    type="graduation" 
                    isRequired
                    list={dropdownOptions.educationList}
                    handleChange={handleChange}
                    ref={el => inputRefs.current.employFinalEducation = el}
                    errorMsg={requiredErrorMsg.employFinalEducation}
                    />
            </div>

            {/* 기타 입력 사항 섹션 */}
            <div className="info-section">
                <div className='info-title'>
                    <p className='info-title-text'>기타입력 사항</p>
                </div>
                <InputBox 
                    inputLabel="지원동기" 
                    inputId="reason" 
                    inputName="employReason" 
                    type="select" 
                    isRequired 
                    half 
                    list=
                    {dropdownOptions.reasonList} 
                    handleChange={handleChange} 
                    ref={el => inputRefs.current.employReason = el}
                    errorMsg={requiredErrorMsg.employReason}/>
                <InputBox 
                    inputLabel="실직기간" 
                    inputId="reason" 
                    inputName="unemployPeriod" 
                    type="select" 
                    isRequired 
                    half 
                    list={dropdownOptions.unemployPeriodList} 
                    handleChange={handleChange} 
                    ref={el => inputRefs.current.unemployPeriod = el}
                    errorMsg={requiredErrorMsg.unemployPeriod}/>
                <InputBox 
                    inputLabel="최초 정보습득경로" 
                    inputId="reason" 
                    inputName="employInformationPath" 
                    type="select" 
                    isRequired 
                    half 
                    list={dropdownOptions.infoList} 
                    handleChange={handleChange} 
                    ref={el => inputRefs.current.employInformationPath = el}
                    errorMsg={requiredErrorMsg.employInformationPath}/>
                <InputBox 
                    inputLabel="병역관계" 
                    inputId="reason"
                    inputName="employIsMilitary"
                    type="select" 
                    isRequired 
                    half 
                    list={dropdownOptions.militaryList} 
                    handleChange={handleChange} 
                    ref={el => inputRefs.current.employIsMilitary = el}
                    errorMsg={requiredErrorMsg.militaryList}/>
            </div>

            {/* 동의 항목 섹션 */}
            <div className="info-section">
                <AgreementBox 
                    checkList={checkList} 
                    setCheckList={setCheckList} 
                    category={courseType}
                    sessionId={sessionId}
                />
            </div>

            {/* 등록 버튼 */}
            <RegisterBtn 
                prev={`/${courseType}/detail/${sessionId}`}  
                handleSubmit={handleSubmit} 
                isNextEnabled={isNextEnabled}
                next="신청" 
            />
        </div>
    );
}

export default InputInfo;
