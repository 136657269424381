import React, { useState, useEffect } from "react";
import "admin/AdminPage.css";
import "admin/jobTraining/JobTraining.css";
import { call } from "service/ApiService";

function ModifyBanner(props) {
  const [trainingWebBanner, setTrainingWebBanner] = useState({ preview: "", file: null });
  const [trainingMobileBanner, setTrainingMobileBanner] = useState({ preview: "", file: null });
  const [careerWebBanner, setCareerWebBanner] = useState({ preview: "", file: null });
  const [careerMobileBanner, setCareerMobileBanner] = useState({ preview: "", file: null });
  const [storyWebBanner, setStoryWebBanner] = useState({ preview: "", file: null });
  const [storyMobileBanner, setStoryMobileBanner] = useState({ preview: "", file: null });
  const [withFirstZoneWebBanner, setWithFirstZoneWebBanner] = useState({ preview: "", file: null });
  const [withFirstZoneMobileBanner, setWithFirstZoneMobileBanner] = useState({ preview: "", file: null });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await call("/api/v1/admin/banner", "GET");
        response.forEach(banner => {
          switch (banner.bannerName) {
            case "training":
              setTrainingWebBanner({ preview: `/uploads/${banner.bannerWebImage}`, file: null });
              setTrainingMobileBanner({ preview: `/uploads/${banner.bannerMobileImage}`, file: null });
              break;
            case "career":
              setCareerWebBanner({ preview: `/uploads/${banner.bannerWebImage}`, file: null });
              setCareerMobileBanner({ preview: `/uploads/${banner.bannerMobileImage}`, file: null });
              break;
            case "story":
              setStoryWebBanner({ preview: `/uploads/${banner.bannerWebImage}`, file: null });
              setStoryMobileBanner({ preview: `/uploads/${banner.bannerMobileImage}`, file: null });
              break;
            case "withfirstzone":
              setWithFirstZoneWebBanner({ preview: `/uploads/${banner.bannerWebImage}`, file: null });
              setWithFirstZoneMobileBanner({ preview: `/uploads/${banner.bannerMobileImage}`, file: null });
              break;
            default:
              console.warn(`Unknown banner name: ${banner.bannerName}`);
          }
        });
      } catch (error) {
        alert(error.message);
      }
    };
    fetchData();
  }, []);

  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage({ preview: reader.result, file }); // 미리보기와 파일 모두 업데이트
      };
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (trainingWebBanner.file) formData.append("trainingWebImage", trainingWebBanner.file);
    if (trainingMobileBanner.file) formData.append("trainingMobileImage", trainingMobileBanner.file);
    if (careerWebBanner.file) formData.append("careerWebImage", careerWebBanner.file);
    if (careerMobileBanner.file) formData.append("careerMobileImage", careerMobileBanner.file);
    if (storyWebBanner.file) formData.append("storyWebImage", storyWebBanner.file);
    if (storyMobileBanner.file) formData.append("storyMobileImage", storyMobileBanner.file);
    if (withFirstZoneWebBanner.file) formData.append("withfirstzoneWebImage", withFirstZoneWebBanner.file);
    if (withFirstZoneMobileBanner.file) formData.append("withfirstzoneMobileImage", withFirstZoneMobileBanner.file);

    try {
      const response = await call("/api/v1/admin/banner", "PUT", formData, "multipart/form-data");
      alert("배너가 성공적으로 저장되었습니다.");
      window.location.reload();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
          시스템 설정{" > "}배너 수정
        </div>
        <div className="main-content">
          <div className="title">배너 수정</div>
          <div className="subtitle">상단 배너 이미지를 수정합니다</div>
          <hr />

          {/* 반복 코드 부분 개선 */}
          {[
            { name: "취업트레이닝", banner: trainingWebBanner, mobileBanner: trainingMobileBanner, setWeb: setTrainingWebBanner, setMobile: setTrainingMobileBanner },
            { name: "직무커리어", banner: careerWebBanner, mobileBanner: careerMobileBanner, setWeb: setCareerWebBanner, setMobile: setCareerMobileBanner },
            { name: "Story", banner: storyWebBanner, mobileBanner: storyMobileBanner, setWeb: setStoryWebBanner, setMobile: setStoryMobileBanner },
            { name: "W.firstzone", banner: withFirstZoneWebBanner, mobileBanner: withFirstZoneMobileBanner, setWeb: setWithFirstZoneWebBanner, setMobile: setWithFirstZoneMobileBanner }
          ].map(({ name, banner, mobileBanner, setWeb, setMobile }) => (
            <div>
              <div key={name} className="input-image-zone">
                <div className="content-header">{name}<br />배너 이미지</div>
                <div className="image-container">
                  <div className="img-tag">웹이미지</div>
                  <input type="file" className="add-web-image" onChange={(e) => handleImageChange(e, setWeb)} />
                  {banner.preview && (
                    <div className="image-preview-box">
                      <img src={banner.preview} alt="미리보기" className="image-preview" />
                    </div>
                  )}
                  <div className="img-tag">모바일 이미지</div>
                  <input type="file" className="add-mobile-image" onChange={(e) => handleImageChange(e, setMobile)} />
                  {mobileBanner.preview && (
                    <div className="image-preview-box">
                      <img src={mobileBanner.preview} alt="미리보기" className="image-preview" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSave}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyBanner;
