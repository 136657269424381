import React, { useState, useEffect } from "react";
import "admin/AdminPage.css";
import "admin/siteContents/SiteContent.css";
import { call } from "service/ApiService";

function Footer(props) {
  const [companyName, setCompanyName] = useState("");
  const [ceo, setCeo] = useState("");
  const [privacyOfficer, setPrivacyOfficer] = useState("");
  const [businessRegistration, setBusinessRegistration] = useState("");
  const [communicationSalesNumber, setCommunicationSalesNumber] = useState("");
  const [address, setAddress] = useState("");
  const [tel, setTel] = useState("");
  const [consultingHours, setConsultingHours] = useState("");
  const [copyright, setCopyright] = useState("");
  const [familySite, setFamilySite] = useState([{ name: "", url: "" }]);
  const [sns, setSns] = useState({
    kakaoTalk: "",
    instagram: "",
    blog: "",
    youtube: ""
  });

  // 페이지 로드 시 데이터 불러오기
  useEffect(() => {
    call("/api/v1/admin/metadata/footer", 'GET')
      .then(response => {
        setCompanyName(response.companyName);
        setCeo(response.ceo);
        setPrivacyOfficer(response.privacyOfficer);
        setBusinessRegistration(response.businessRegistration);
        setCommunicationSalesNumber(response.communicationSalesNumber);
        setAddress(response.address);
        setTel(response.tel);
        setConsultingHours(response.consultingHours);
        setCopyright(response.copyright);
        setFamilySite(response.familySite.map(site => ({ name: site.siteName, url: site.url })));
        setSns(response.sns);
      })
      .catch(error => {
        alert(error.message);
      })
  }, []);

  const addFamilySite = () => {
    setFamilySite([...familySite, { name: "", url: "" }]); //버튼 누르면 추가양식
  }

  const handleFamilySiteChange = (index, field, value) => { //값 변경 동작함수
    const newFamilySite = familySite.map((site, i) => {
      if (i === index) {
        return { ...site, [field]: value }; // 해당 인덱스의 객체만 업데이트
      }
      return site;
    });
    setFamilySite(newFamilySite);
  }

  // 사이트삭제 함수
  const deleteFamilySite = (index) => {
    const newFamilySite = familySite.filter((_, i) => i !== index); //해당 인덱스 삭제 후 새 배열 생성
    setFamilySite(newFamilySite);
  }

  const handleSnsChange = (field, value) => {
    setSns(prevSns => ({ ...prevSns, [field]: value }));
  }

  // 데이터 등록 함수
  const handleSave = () => {
    const data = {
      companyName,
      ceo,
      privacyOfficer,
      businessRegistration,
      communicationSalesNumber,
      address,
      tel,
      consultingHours,
      copyright,
      familySite: familySite.map((site) => ({
        siteName: site.name,
        url: site.url
      })),
      sns
    };
  
    console.log("JSON Data to be saved:", data);
  
    call("/api/v1/admin/metadata/footer", "PUT", data)
      .then((response) => {
        alert("저장되었습니다!");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">사이트 컨텐츠 {">"} 하단바</div>
        <div className="main-content">
          <div className="colunm-content-header">상호</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          </div>
          <div className="colunm-content-header">대표</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={ceo} onChange={(e) => setCeo(e.target.value)} />
          </div>
          <div className="colunm-content-header">개인정보관리책임자</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={privacyOfficer} onChange={(e) => setPrivacyOfficer(e.target.value)} />
          </div>
          <div className="colunm-content-header">사업자등록번호</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={businessRegistration} onChange={(e) => setBusinessRegistration(e.target.value)} />
          </div>
          <div className="colunm-content-header">통신판매</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={communicationSalesNumber} onChange={(e) => setCommunicationSalesNumber(e.target.value)} />
          </div>
          <div className="colunm-content-header">주소 <div className="caption caption-address">변경 시 퍼스트맵에서의 사옥 위치도 변경됩니다</div></div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={address} onChange={(e) => setAddress(e.target.value)} />
          </div>
          <div className="colunm-content-header">TEL</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={tel} onChange={(e) => setTel(e.target.value)} />
          </div>
          <div className="colunm-content-header">상담시간</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={consultingHours} onChange={(e) => setConsultingHours(e.target.value)} />
          </div>
          <div className="colunm-content-header">Copyright</div>
          <div className="footer-zone">
            <input type="text" className="text-zone" value={copyright} onChange={(e) => setCopyright(e.target.value)} />
          </div>
          <div className="colunm-content-header">Family site</div>
          <div className="footer-zone">
            {familySite.map((site, index) => (
              <div key={index} className="site-zone">
                <div className="input-container">
                  <div className="etc-title">사이트명</div>
                  <input
                    type="text"
                    className="text-zone"
                    value={site.name}
                    onChange={(e) => handleFamilySiteChange(index, "name", e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <div className="etc-title">주소</div>
                  <input
                    type="text"
                    className="text-zone"
                    value={site.url}
                    onChange={(e) => handleFamilySiteChange(index, "url", e.target.value)}
                  />
                </div>
                <div className="site-delete" onClick={() => deleteFamilySite(index)}>삭제</div>
              </div>
            ))}
            <div className="add-site-btn" onClick={addFamilySite}>
              +추가하기
            </div>
          </div>
          <div className="colunm-content-header">SNS</div>
          <div className="footer-zone">
            <div className="site-zone">
              <div className="input-container">
                <div className="etc-title">카카오톡</div>
                <input type="text" className="text-zone" value={sns.kakaoTalk} onChange={(e) => handleSnsChange("kakaoTalk", e.target.value)} />
              </div>
              <div className="input-container">
                <div className="etc-title">인스타그램</div>
                <input type="text" className="text-zone" value={sns.instagram} onChange={(e) => handleSnsChange("instagram", e.target.value)} />
              </div>
            </div>
          </div>
          <div className="footer-zone">
            <div className="site-zone">
              <div className="input-container">
                <div className="etc-blog-title">블로그</div>
                <input type="text" className="text-zone" value={sns.blog} onChange={(e) => handleSnsChange("blog", e.target.value)} />
              </div>
              <div className="input-container">
                <div className="etc-yb-title">유튜브</div>
                <input type="text" className="text-zone" value={sns.youtube} onChange={(e) => handleSnsChange("youtube", e.target.value)} />
              </div>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSave}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
