import moment from "moment";
import React, { useState, useEffect } from "react";
import { call } from "service/ApiService";
import Calendar from "react-calendar";
import calendar from "images/calendar.png";
import { useNavigate } from "react-router-dom";

function AddEmpPracticeTraining(props) {
  const [visible, setVisible] = useState(""); // 보기 카테고리
  const [menu, setMenu] = useState([]);
  const [procedure, setProcedure] = useState("");
  const [startDate, setStartDate] = useState(null); // 신청 시작일
  const [endDate, setEndDate] = useState(null); // 신청 종료일
  const [showStartCalendar, setShowStartCalendar] = useState(false); // 시작일 캘린더 표시 여부
  const [showEndCalendar, setShowEndCalendar] = useState(false); // 종료일 캘린더 표시 여부
  const [startEdu, setStartEdu] = useState(null); // 신청 시작일
  const [endEdu, setEndEdu] = useState(null); // 신청 종료일
  const [showStartEduCalendar, setShowStartEduCalendar] = useState(false); // 시작일 캘린더 표시 여부
  const [showEndEduCalendar, setShowEndEduCalendar] = useState(false); // 종료일 캘린더 표시 여부
  const [open, setOpen] = useState(false); // 메인 공개여부
  const [sessionName, setSessionName] = useState(""); // 과정 이름
  const [sessionRecruitNumber, setSessionRecruitNumber] = useState(0); // 모집 인원
  const [sessionCondition, setSessionCondition] = useState(""); // 조건
  const [sessionInformation, setSessionInformation] = useState(""); // 세션 정보
  const [sessionProvide, setSessionProvide] = useState(""); // 제공 내용
  const [sessionAdvertise, setSessionAdvertise] = useState(""); // 광고 문구
  const navigate = useNavigate();

  // 카테고리 불러오기
  useEffect(() => {
    call('/api/v1/admin/training-course/summary', 'GET')
      .then((response) => {
        setMenu(response || []);
      })
      .catch((error) => alert('카테고리 조회에 실패했습니다'));
  }, []);

  //라디오버튼값 업데이트
  const handleVisibieChange = (e) => {
    setVisible(e.target.value);
  };
  const handleOpenChange = (e) => {
    setOpen(e.target.value);
  };

  const handleProcedureChange = (e) => {
    setProcedure(e.target.value);
  };

  // 시작일 캘린더 표시/숨김
  const toggleStartCalendar = () => {
    setShowStartCalendar(!showStartCalendar);
  };
  const toggleStartEduCalendar = () => {
    setShowStartEduCalendar(!showStartEduCalendar);
  };

  // 종료일 캘린더 표시/숨김
  const toggleEndCalendar = () => {
    setShowEndCalendar(!showEndCalendar);
  };
  const toggleEndEduCalendar = () => {
    setShowEndEduCalendar(!showEndEduCalendar);
  };

  // 시작일 선택 핸들러
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setShowStartCalendar(false); // 선택 후 캘린더 닫기
  };
  const handleStartEduChange = (date) => {
    setStartEdu(date);
    setShowStartEduCalendar(false); // 선택 후 캘린더 닫기
  };

  // 종료일 선택 핸들러
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setShowEndCalendar(false); // 선택 후 캘린더 닫기
  };
  const handleEndEduChange = (date) => {
    setEndEdu(date);
    setShowEndEduCalendar(false); // 선택 후 캘린더 닫기
  };

  const handleSubmit = () => {
    const data = {
      courseId: { courseId: procedure },
      sessionName,
      sessionIsPublic: visible,
      sessionIsMain: open,
      sessionRecruitNumber,
      sessionApplicationStartDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      sessionApplicationEndDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
      sessioncourseStartDate: moment(startEdu).format("YYYY-MM-DD"),
      sessioncourseEndDate: moment(endEdu).format("YYYY-MM-DD"),
      sessionCondition,
      sessionInformation,
      sessionProvide,
      sessionAdvertise
    };
    
    call('/api/v1/admin/training-session', 'POST', data)
    .then(response => {
      alert(response.message);
      navigate('/admin/employtraining/emppracticelist');
    })
    .catch(error=>{
      alert(error.message);
    });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
        취업 훈련 과정{" > "}취업 훈련 실시 목록{" > "}취업 훈련 과정 등록
        </div>
        <div className="main-content">
          <div className="title">훈련과정 등록</div>
          <div className="subtitle">
            새로 등록할 취업 훈련 실시에 대한 정보를 입력하세요
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">실시여부</div>
              <div className="radio-button">
                <label>
                  <input
                    type="radio"
                    value="true"
                    defaultChecked
                    checked={visible === "true"}
                    onChange={handleVisibieChange}
                  />
                  실시
                </label>
                <label>
                  <input
                    type="radio"
                    value="false"
                    checked={visible === "false"}
                    onChange={handleVisibieChange}
                  />
                  미실시
                </label>
              </div>
            </div>
            <div className="option-content">
              <div className="option-subtitle">메인공개</div>
              <div className="radio-button">
                <label>
                  <input
                    type="radio"
                    value="true"
                    defaultChecked
                    checked={open === "true"}
                    onChange={handleOpenChange}
                  />
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    value="false"
                    checked={open === "false"}
                    onChange={handleOpenChange}
                  />
                  비공개
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">과정 선택</div>
              <select
                className="long-drop-down-menu"
                value={procedure}
                onChange={handleProcedureChange}
              >
                {menu.map((item) => (
                  <option key={item.courseId} value={item.courseId}>{item.courseName}</option>
                ))}
              </select>
            </div>
            <div className="option-zone">
              <div className="content-header">과정 이름</div>
              <input type="text" className="text-input" value={sessionName} onChange={(e) => setSessionName(e.target.value)} placeholder="과정 이름" maxLength={255} />
            </div>
          </div>
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">신청 시작일</div>
              <button
                className="date-input-button"
                onClick={toggleStartCalendar}
              >
                {startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showStartCalendar && (
                <Calendar onChange={handleStartDateChange} value={startDate} />
              )}
            </div>
            <div className="option-zone">
              <div className="content-header">신청 종료일</div>
              <button className="date-input-button" onClick={toggleEndCalendar}>
                {endDate ? moment(endDate).format("YYYY-MM-DD") : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showEndCalendar && (
                <Calendar onChange={handleEndDateChange} value={endDate} />
              )}
            </div>
          </div>
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">교육 시작일</div>
              <button
                className="date-input-button"
                onClick={toggleStartEduCalendar}
              >
                {startEdu ? moment(startEdu).format("YYYY-MM-DD") : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showStartEduCalendar && (
                <Calendar onChange={handleStartEduChange} value={startEdu} />
              )}
            </div>
            <div className="option-zone">
              <div className="content-header">교육 종료일</div>
              <button
                className="date-input-button"
                onClick={toggleEndEduCalendar}
              >
                {endEdu ? moment(endEdu).format("YYYY-MM-DD") : "날짜 선택"}
              </button>
              <img src={calendar} className="calendar-icon" alt="캘린더" />
              {showEndEduCalendar && (
                <Calendar onChange={handleEndEduChange} value={endEdu} />
              )}
            </div>
            <div className="option-zone">
              <div className="content-header">모집 인원</div>
              <input type="text" className="calc-input" value={sessionRecruitNumber} onChange={(e) => setSessionRecruitNumber(e.target.value)} placeholder="모집 인원"/>
            </div>
          </div>
          <div className="add-training-zone">
            <div className="option-zone">
              <div className="content-header">사용자 동의</div>
              <div className="agreement-zone">
                <div className="agreement-content">
                  <div className="agreement-header">
                    서비스 이용약관 <span className="essential">(필수)</span>
                  </div>
                  <textarea className="textbox" value={sessionCondition} onChange={(e) => setSessionCondition(e.target.value)} ></textarea>
                </div>
                <div className="agreement-content">
                  <div className="agreement-header">
                    개인정보 수집 및 이용 동의{" "}
                    <span className="essential">(필수)</span>
                  </div>
                  <textarea className="textbox" value={sessionInformation} onChange={(e) => setSessionInformation(e.target.value)}></textarea>
                </div>
                <div className="agreement-content">
                  <div className="agreement-header">
                    개인정보 제 3자 제공 동의{" "}
                    <span className="essential">(필수)</span>
                  </div>
                  <textarea className="textbox" value={sessionProvide} onChange={(e) => setSessionProvide(e.target.value)}></textarea>
                </div>
                <div className="agreement-content">
                  <div className="agreement-header">마케팅 활용 동의</div>
                  <textarea className="textbox" value={sessionAdvertise} onChange={(e) => setSessionAdvertise(e.target.value)}></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEmpPracticeTraining;
