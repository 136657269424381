import React, { useState } from "react";
import "admin/AdminPage.css";
import "admin/jobTraining/JobTraining.css";
import { call } from "service/ApiService";

function ChangePW(props) {
  const [currentPW, setCurrentPW] = useState("");
  const [newPW, setNewPW] = useState("");
  const [checkPW, setCheckPW] = useState("");

  const PWInput = (e) => {
    setNewPW(e.target.value);
  }

  const checkPWChange = (e) => {
    setCheckPW(e.target.value);
    return newPW === e.target.value;
  }

  const feedbackMessage = () => {
    if (checkPW && newPW !== checkPW) {
      return (
        <div className="feedback-wrong">패스워드가 일치하지 않습니다</div>
      )
    } else if (checkPW === "" || newPW === "") {
      return (
        <div className="feedback-correct"></div>
      )
    } else {
      return (
        <div className="feedback-correct">패스워드가 일치합니다</div>
      )
    }
  }

  const handleSubmit = async () => {
    const data = {
      currentPassword: currentPW,
      newPassword: newPW,
      confirmPassword: checkPW,
    };

    call("/api/v1/admin/update-password", 'PUT', data)
      .then(response => {
        alert(response.message);
        window.location.reload();
      })
      .catch(error => {
        alert(error.message);
      })
  };


  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">시스템 설정{" > "}관리자 비밀번호 변경</div>
        <div className="main-content">
          <div className="title">관리자 비밀번호 변경</div>
          <div className="option-zone">
            <div className="option-content">
              <div className="pw-content-header">현재 비밀번호 입력</div>
              <input
                type="password"
                className="long-title"
                placeholder="현재 비밀번호를 입력해주세요"
                onChange={(e) => setCurrentPW(e.target.value)}
              />
            </div>
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="pw-content-header">새 비밀번호 입력</div>
              <input
                type="password"
                className="long-title"
                placeholder="변경할 비밀번호를 입력해주세요"
                onChange={PWInput}
              />
            </div>
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="pw-content-header">새 비밀번호 확인</div>
              <input
                type="password"
                className="long-title"
                placeholder="한번 더 입력해주세요"
                onChange={checkPWChange}
              />
            </div>
          </div>
          {feedbackMessage()}
          <hr />
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              변경
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePW;
