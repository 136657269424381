import React, { useState, useEffect } from "react";
import "admin/jobTraining/JobTraining.css";
import { call } from "service/ApiService";
import { useNavigate } from "react-router-dom";

function JobTrainingPractice(props) {
  const [visible, setVisible] = useState(""); // 기본값 설정
  const [elementCount, setElementCount] = useState(10); // 기본값 설정
  const [courses, setCourses] = useState([]);
  const [checkItem, setCheckItem] = useState([]); // 체크된 아이템 배열
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1); // 총 페이지 수 상태 추가
  const navigete = useNavigate();
  
  //수정페이지 이동
  const handleModifyChange=(sessionId)=>{
    navigete('/admin/jobtraining/modifypractice/' + sessionId);
  }
  const handleRegistChange = () => {
    navigete("/admin/jobtraining/addjobpractice");
  };
  
  // 훈련 리스트 불러오기
  const fetchCourses = (page = currentPage, size = elementCount) => {
    const options = { page, size, sessionIsPublic: visible };
    
    if(visible === null || visible === 0){
      visible = "";
      
    }

    call('/api/v1/admin/training-session/job', 'GET', options)
      .then((response) => {
        const formattedCourses = response.content.map((course) => ({
          ...course,
          sessionCreateTime: course.sessionCreateTime.split('T')[0],  // 'YYYY-MM-DD' 형식으로 변환
          sessionMoidfiedTime: course.sessionMoidfiedTime.split('T')[0],  // 'YYYY-MM-DD' 형식으로 변환
      }));
      setCourses(formattedCourses); 
        setTotalPage(response.totalPages);
      })
      .catch(() => alert('코스 불러오기에 실패했습니다.'));
  };

  //단일 체크
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      setCheckItem((prev) => [...prev, id]); //체크 아이템을 배열에 추가
    } else {
      setCheckItem(checkItem.filter((el) => el !== id)); //선택해제시 요소 제외
    }
  };

  //전체체크
  const handleAllCheck = (checked) => {
    if (checked) {
      // 전체선택시 전체 아이템의 sessionId를 담은 배열로 업데이트
      const idArray = courses.map((course) => course.sessionId);
      setCheckItem(idArray);
    } else {
      setCheckItem([]); // 새로운 빈 배열로 업데이트
    }
  };

  // 컴포넌트가 렌더링될 때 실행
  useEffect(() => {
    fetchCourses(currentPage); 
  }, [visible, elementCount, currentPage]);
  
  // 카테고리 선택 변경
  const handleVisibleChange = (e) => {
    setVisible(e.target.value);
  };  
  
  // elementCount 선택 핸들러 수정
  const handleElementCountChange = (e) => {
    // 문자열에서 숫자만 추출
    const number = parseInt(e.target.value);
    setElementCount(number);
  };

  // 페이지 변경 핸들러
  const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return; // 유효성 검사
    setCurrentPage(page); // 현재 페이지를 업데이트합니다.
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수
  
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);
    // 계산된 페이지 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  // 삭제 핸들러 함수 추가
  const handleDelete = () => {
    if (checkItem.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }

    const confirmDelete = window.confirm('삭제를 할 경우 해당 과정 지원자의 데이터가 모두 삭제 됩니다.\n정말 삭제하시겠습니까?');
    
    if (confirmDelete) {
      const deletePromises = checkItem.map(sessionId => 
        call(`/api/v1/admin/training-session/${sessionId}`, 'DELETE')
      );

      Promise.all(deletePromises)
        .then(() => {
          alert('성공적으로 삭제되었습니다.');
          setCheckItem([]); // 체크항목 초기화
          fetchCourses(currentPage); // 목록 새로고침
        })
        .catch(() => {
          alert('삭제 중 오류가 발생했습니다.');
        });
    }
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">직무 훈련 과정 {" > "} 직무 훈련 실시 목록</div>
        <div className="main-content">
          <div className="title">직무 훈련 실시 목록</div>
          <div className="subtitle">
            현재 실시 되어있는 직무 훈련 목록입니다
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">실시 여부</div>
              <select
                className="drop-down-menu"
                value={visible}
                onChange={handleVisibleChange}
              >
                <option value={""}selected>전체</option>
                <option value={true}>실시</option>
                <option value={false}>미실시</option>
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">보기</div>
              <select
                className="drop-down-menu"
                value={elementCount}
                onChange={handleElementCountChange}
              >
                <option value={10}>10개씩 보기</option>
                <option value={20}>20개씩 보기</option>
                <option value={50}>50개씩 보기</option>
                <option value={100}>100개씩 보기</option>
              </select>
            </div>
          </div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      checked={
                        courses.length > 0 && 
                        courses.every((course) => checkItem.includes(course.sessionId))
                      }
                    />
                  </th>
                  <th>실시여부</th>
                  <th className="course-name">과정명</th>
                  <th>최초 등록일</th>
                  <th>수정일</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.id || course.sessionId}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleSingleCheck(e.target.checked, course.sessionId)
                        }
                        checked={checkItem.includes(course.sessionId)}
                      />
                    </td>
                    <td>{course.sessionIsPublic ? '실시' : '미실시'}</td>
                    <td className="innerText" onClick={() => handleModifyChange(course.sessionId)}>
                      {course.sessionName}
                    </td>
                    <td className="date-td">{course.sessionCreateTime}</td> 
                    <td className="date-td">{course.sessionMoidfiedTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="button-container">
            <button type="submit" className="deleteBtn" onClick={handleDelete}>
              삭제
            </button>
            <button
              type="submit"
              className="submitBtn"
              onClick={handleRegistChange}
            >
              등록
            </button>
          </div>
          <div className="pagination-zone">
            <button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>{"<<"}</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>{"<"}</button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageChange(page)}
                style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage - 1}>{">"}</button>
            <button onClick={() => handlePageChange(totalPage - 1)} disabled={currentPage === totalPage - 1}>{">>"}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobTrainingPractice;
