import "admin/Login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { call } from "service/ApiService";

function Login(props) {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();  // 페이지 리로딩 방지

    const loginData = {
      adminId: id,
      adminPassword: password
    };

    call('/api/v1/admin/login', 'POST', loginData)
    .then(response=>{
      navigate("/admin");
    })
    .catch(error=>alert(error.message));
  };

  return (
    <div className="app">
      <div className="login-zone">
        <div className="login-title">관리자 로그인</div>

        <form onSubmit={handleLogin}>
          <div className="login-area">
            <div className="input-zone">
              <label>아이디</label>
              <input className="login-input" type="text" placeholder="아이디" value={id} required
                  onChange={(e) => setId(e.target.value)}/>
            </div>
            <div className="input-zone">
              <label>비밀번호</label>
              <input className="login-input" type="password" placeholder="비밀번호" value={password} required
                  onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <hr/>
            <button type="submit" className="loginBtn">로그인</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
