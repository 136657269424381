import React, { useState, useEffect } from "react";
import "admin/AdminPage.css";
import "admin/siteContents/SiteContent.css";
import { call } from "service/ApiService";

function MainPage(props) {
  const [mainImage, setMainImage] = useState({});
  const [recommendedCourseText, setRecommendedCourseText] = useState("");
  const [storyImage, setStoryImage] = useState(null);
  const [storyText, setStoryText] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [facilityText, setFacilityText] = useState("");
  const [facilityBackgroundImage, setFacilityBackgroundImage] = useState(null);
  const [facilityImages, setFacilityImages] = useState([]);
  const [inputCount, setInputCount] = useState(1);

  useEffect(() => {
    call("/api/v1/admin/metadata/main", "GET")
      .then((response) => {
        setMainImage({
          web: {
            preview: `/uploads/${response.mainImage.webImage}`,
            file: new File([], ""),
          },
          mobile: {
            preview: `/uploads/${response.mainImage.mobileImage}`,
            file: new File([], ""),
          },
        });
        setRecommendedCourseText(response.recommendedCourseText);
        setStoryImage({
          preview: `/uploads/${response.storyImage}`,
          file: new File([], ""),
        });
        setStoryText(response.storyText);
        setYoutubeLink(response.youtubeLink);
        setFacilityText(response.facilityText);
        setFacilityBackgroundImage({
          preview: `/uploads/${response.facilityBackgroundImage}`,
          file: new File([], ""),
        });

        const facilityImagesArray = response.facilityImages.map((image) => ({
          preview: `/uploads/${image}`,
          file: new File([], ""),
        }));
        setFacilityImages(facilityImagesArray);

        setInputCount(response.facilityImages.length);
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  const handleImageChange = (e, setImages) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages({ preview: reader.result, file: file });
      };
    }
  };

  const handleImageDictChange = (e, type, setImages) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages((prevImages) => ({
          ...prevImages, // 이전 상태를 유지
          [type]: { preview: reader.result, file: file }, // type에 따라 업데이트
        }));
      };
    }
  };

  const handleImageListChange = (index, e, Images, setImages) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const newImages = [...Images];
        newImages[index] = { preview: reader.result, file: file };
        setImages(newImages);
      };
    }
  };

  const handleTextChange = (e, setFunction) => {
    setFunction(e.target.value);
  };

  const addImageInput = () => {
    //시설이미지 추가
    setInputCount(inputCount + 1);
    setFacilityImages([...facilityImages, ""]);
  };

  const deleteImageInput = (index) => {
    //시설이미지 삭제
    const newfacilityImage = [...facilityImages];
    newfacilityImage.splice(index, 1); // 해당 인덱스의 웹 이미지 삭제
    setFacilityImages(newfacilityImage);
    setInputCount(inputCount - 1);
  };

  const handleSave = () => {
    const formData = new FormData();

    if (mainImage.web && mainImage.web.file) {
      formData.append("mainImage.webImage", mainImage.web.file);
    }
    if (mainImage.mobile && mainImage.mobile.file) {
      formData.append("mainImage.mobileImage", mainImage.mobile.file);
    }

    if (storyImage && storyImage.file) {
      formData.append("storyImage", storyImage.file);
    }

    formData.append("recommendedCourseText", recommendedCourseText);

    formData.append("storyText", storyText);
    formData.append("youtubeLink", youtubeLink);
    formData.append("facilityText", facilityText);

    if (facilityBackgroundImage && facilityBackgroundImage.file) {
      formData.append("facilityBackgroundImage", facilityBackgroundImage.file);
    }

    facilityImages.forEach((image, index) => {
      if (image.file) {
        formData.append(`facilityImages[${index}]`, image.file);
      }
    });

    call("/api/v1/admin/metadata/main", "PUT", formData, "multipart/form-data")
      .then((response) => {
        alert("이미지가 성공적으로 저장되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">사이트 컨텐츠 {" > "} 메인페이지</div>
        <div className="main-content">
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">
                메인 이미지
              </div>
              <div className="image-container">
                <div className="img-tag">웹 이미지<div className="caption">1920*1080권장</div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleImageDictChange(e, "web", setMainImage)}
                />
                {mainImage.web && (
                  <div className="image-preview-box">
                    <img
                      src={mainImage.web.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
                <div className="img-tag">모바일 이미지<div className="caption">1:2비율 권장</div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleImageDictChange(e, "mobile", setMainImage)}
                />
                {mainImage.mobile && (
                  <div className="image-preview-box">
                    <img
                      src={mainImage.mobile.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-zone">
            <div className="content-header">
              추천과정
              <br />
              텍스트
            </div>
            <textarea
              className="textbox"
              placeholder="교육과정 문구를 작성해주세요."
              value={recommendedCourseText}
              onChange={(e) => handleTextChange(e, setRecommendedCourseText)}
            ></textarea>
          </div>
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">
                스토리 이미지<div className="caption">3:5비율 권장</div>
              </div>
              <div className="image-container">
                <div className="img-tag">스토리 이미지</div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) =>
                    handleImageChange(e, setStoryImage)
                  }
                />
                {storyImage && (
                  <div className="image-preview-box">
                    <img
                      src={storyImage.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="inner-text-zone">
              <div className="content-header">스토리 텍스트</div>
              <textarea
                className="textbox"
                placeholder="문구를 작성해주세요."
                value={storyText}
                onChange={(e) => handleTextChange(e, setStoryText)}
              ></textarea>
            </div>
          </div>
          <div className="link-zone">
            <div className="content-header">유튜브 링크</div>
            <input
              type="text"
              className="text-input"
              placeholder="유튜브 링크를 입력하세요."
              value={youtubeLink}
              onChange={(e) => handleTextChange(e, setYoutubeLink)}
            />
          </div>
          <div className="image-zone">
            <div className="inner-text-zone">
              <div className="content-header">
                교육시설
                <br />
                텍스트 추가
              </div>
              <textarea
                className="textbox"
                placeholder="문구를 작성해주세요."
                value={facilityText}
                onChange={(e) => handleTextChange(e, setFacilityText)}
              ></textarea>
            </div>
            <div className="input-image-zone">
              <div className="content-header">
                교육시설
                <br />
                배경이미지<div className="caption">5:2 비율 권장</div>
              </div>
              <div className="image-container">
                <div className="img-tag">배경이미지</div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) =>
                    handleImageChange(e, setFacilityBackgroundImage)
                  }
                />
                {facilityBackgroundImage && (
                  <div className="image-preview-box">
                    <img
                      src={facilityBackgroundImage.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="image-content">
            <div className="content-header">
              교육시설
              <br />
              이미지<div className="caption">330*380 권장</div>
            </div>
            <div className="edu-image-zone">
              {Array.from({ length: inputCount }).map((_, index) => (
                <div key={index}>
                  <div className="input-image-zone2">
                    <div className="image-container">
                      <div className="img-tag">시설이미지</div>
                      <input
                        type="file"
                        className="add-web-image"
                        onChange={(e) =>
                          handleImageListChange(
                            index,
                            e,
                            facilityImages,
                            setFacilityImages
                          )
                        }
                      />
                      {facilityImages[index] && (
                        <div className="image-preview-box">
                          <img
                            src={facilityImages[index].preview}
                            alt="미리보기"
                            className="image-preview"
                          />
                        </div>
                      )}
                    </div>
                    <button
                      className="delete-image-btn"
                      onClick={() => deleteImageInput(index)}
                    >
                      - 삭제하기
                    </button>
                  </div>
                </div>
              ))}
              <button className="add-image-btn" onClick={addImageInput}>
                + 추가하기
              </button>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSave}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
