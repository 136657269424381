import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { call } from "service/ApiService";

function ModifyEmpTraining(props) {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [wThumbnail, setWThumbnail] = useState(""); // 썸네일
  const [category, setCategory] = useState(""); // 과정 카테고리 ID
  const [pVisible, setPVisible] = useState(true); // 포폴보기 여부
  const [webExplainImg, setWebExplainImg] = useState(""); // 웹포트폴리오설명
  const [courseName, setCourseName] = useState(""); // 강좌명
  const [menu, setMenu] = useState([]); // 카테고리 배열
  const [courseTag, setCourseTag] = useState(""); // 과정 설명글
  const [content, setContent] = useState(""); // 과정 내용
  const [cFile, setCFile] = useState(null); // 커리큘럼 첨부파일
  const [webRoadmapImg, setWebRoadmapImg] = useState(""); // 로드맵 웹 이미지
  const [mobileRoadmapImg, setMobileRoadmapImg] = useState(""); // 로드맵 모바일 이미지
  const [listThumbnail, setListThumbnail] = useState(""); // 목록 썸네일

  // 카테고리 목록과 과정 데이터를 함께 불러오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 카테고리 목록 불러오기
        const menuResponse = await call(
          "/api/v1/category/select?categoryName=취업",
          "GET"
        );
        setMenu(menuResponse || []);

        // 과정 데이터 불러오기
        const courseData = await call(
          `/api/v1/admin/training-course/${courseId}`,
          "GET"
        );

        // 기본 정보 초기화
        setCategory(String(courseData.courseCategoryId));
        setCourseName(courseData.courseName);
        setPVisible(courseData.courseIsProjectPublic);
        setCourseTag(courseData.courseSummary || "");
        setContent(courseData.courseContent || "");

        // 썸네일 이미지 초기화
        if (courseData.courseThumbnailImage) {
          setWThumbnail(courseData.courseThumbnailImage);
        }

        // 로드맵 이미지 초기화
        if (courseData.courseRoadMapWebImage) {
          setWebRoadmapImg(courseData.courseRoadMapWebImage);
        }
        if (courseData.courseRoadMapMobileImage) {
          setMobileRoadmapImg(courseData.courseRoadMapMobileImage);
        }

        // 컨텐츠 초기화
        if (courseData.contents && courseData.contents.length > 0) {
          const initialContents = courseData.contents.map((content) => ({
            courseContentId: content.courseContentId,
            title: content.courseContentName,
            webImage: content.courseContentImage1 || "",
            mobileImage: content.courseContentImage2 || "",
          }));
          setImageInputs(initialContents);
        }

        // 포트폴리오 초기화
        if (courseData.projects && courseData.projects.length > 0) {
          const initialProjects = courseData.projects.map((project) => ({
            courseProjectId: project.courseProjectId,
            url: project.courseProjectLink || "",
            webImage: project.courseProjectWebImage || "",
            mobileImage: project.courseProjectMobileImage || "",
          }));
          setPortfolioInputs(initialProjects);
        }

        // 커리큘럼 파일 초기화
        if (courseData.courseFile) {
          setCFile(courseData.courseFile);
        }

        // 포트폴리오 섹터 이미지 초기화
        if (courseData.courseProjectSectorImage) {
          setWebExplainImg(courseData.courseProjectSectorImage);
        }

        // 목록 썸네일 이미지 초기화
        if (courseData.courseListThumbnailImage) {
          setListThumbnail(courseData.courseListThumbnailImage);
        }
      } catch (error) {
        console.error("데이터 로딩 중 오류 발생:", error);
      }
    };

    fetchData();
  }, [courseId]);

  const handleWExplainImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWebExplainImg(file);
    }
  };

  // 컨텐츠 이미지 상태 배열로 관
  const [imageInputs, setImageInputs] = useState([
    { title: "", webImage: "", mobileImage: "" },
  ]);
  // 포트폴리오 이미지 상태 배열로 관리
  const [portfolioInputs, setPortfolioInputs] = useState([
    { courseProjectId: null, url: "", webImage: "", mobileImage: "" },
  ]);

  // 썸네일 이미지 처리 함수
  const handleWThumbnailChange = (e) => {
    const file = e.target.files[0]; // 업로드 된 이미지
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // 데이터url로 변환
      reader.onloadend = () => {
        setWThumbnail(reader.result); // 웹이미지 썸네일 저장
      };
    }
  };

  // 컨텐츠 이미지 추가 함수
  const addImageInput = () => {
    setImageInputs([
      ...imageInputs,
      { title: "", webImage: "", mobileImage: "" },
    ]);
  };

  // 컨텐츠 이미지 삭제 함수 수정
  const removeImageInput = async (index) => {
    const input = imageInputs[index];

    // 서버에 저장된 컨텐츠인 경우 (courseContentId가 있는 경우)
    if (input.courseContentId) {
      try {
        await call(
          `/api/v1/admin/training-course/content/${input.courseContentId}`,
          "DELETE"
        );
      } catch (error) {
        console.error("컨텐츠 삭제 중 오류 발생:", error);
        alert("컨텐츠 삭제에 실패했습니다.");
        return;
      }
    }

    // UI에서 해당 컨텐츠 제거
    const newImageInputs = [...imageInputs];
    newImageInputs.splice(index, 1);
    setImageInputs(newImageInputs);
  };

  // 포트폴리오 이미지 추가 함수
  const addPortfolioImageInput = () => {
    setPortfolioInputs([
      ...portfolioInputs,
      { courseProjectId: null, url: "", webImage: "", mobileImage: "" },
    ]);
  };

  // 포트폴리오 이미지 삭제 함수 수정
  const removePortfolioImageInput = async (index) => {
    const input = portfolioInputs[index];

    // 서버에 저장된 프로젝트인 경우 (courseProjectId가 있는 경우)
    if (input.courseProjectId) {
      try {
        await call(
          `/api/v1/admin/training-course/project/${input.courseProjectId}`,
          "DELETE"
        );
      } catch (error) {
        console.error("포트폴리오 삭제 중 오류 발생:", error);
        alert("포트폴리오 삭제에 실패했습니다.");
        return;
      }
    }

    // UI에서 해당 포트폴리오 제거
    const newPortfolioInputs = [...portfolioInputs];
    newPortfolioInputs.splice(index, 1);
    setPortfolioInputs(newPortfolioInputs);
  };

  // 카테고리 변경 함수
  const handleVisibleChange = (e) => {
    const selectedCategoryId = e.target.value;
    setCategory(selectedCategoryId); // 카테고리 ID 저장
  };

  // 파일 처리 함수들 추가
  const handleAddCFile = (e) => {
    setCFile(e.target.files[0]);
  };

  const handleWRoadmapImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setWebRoadmapImg(file);
    }
  };

  const handleMRoadmapImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMobileRoadmapImg(file);
    }
  };

  // handleSubmit 함수 수정
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // 기본 정보 추가
    formData.append("courseDivisionType", "취업트레이닝과정");
    formData.append("courseCategoryId", category);
    formData.append("courseName", courseName);
    formData.append("courseIsProjectPublic", pVisible);
    formData.append("courseSummary", courseTag);
    formData.append("courseContent", content);

    // 썸네일 이미지 추가
    if (wThumbnail instanceof File) {
      formData.append("courseThumbnailImage", wThumbnail);
    }

    // 로드맵 이미지 추가
    if (webRoadmapImg instanceof File) {
      formData.append("courseRoadMapWebImage", webRoadmapImg);
    }
    if (mobileRoadmapImg instanceof File) {
      formData.append("courseRoadMapMobileImage", mobileRoadmapImg);
    }

    // 커리큘럼 파일 추가
    if (cFile instanceof File) {
      formData.append("courseFile", cFile);
    }

    // 컨텐츠 이미지 추가
    imageInputs.forEach((input, index) => {
      if (input.courseContentId) {
        formData.append(
          `contents[${index}].courseContentId`,
          input.courseContentId
        );
      }
      formData.append(`contents[${index}].courseContentName`, input.title);
      if (input.webImage instanceof File) {
        formData.append(
          `contents[${index}].courseContentImage1`,
          input.webImage
        );
      }
      if (input.mobileImage instanceof File) {
        formData.append(
          `contents[${index}].courseContentImage2`,
          input.mobileImage
        );
      }
    });

    // 포트폴리오 섹터 이미지 추가 (최상위 레벨)
    if (webExplainImg instanceof File) {
      formData.append("courseProjectSectorImage", webExplainImg);
    }

    // 포트폴리오 이미지 추가
    portfolioInputs.forEach((input, index) => {
      if (input.courseProjectId) {
        formData.append(
          `projects[${index}].courseProjectId`,
          input.courseProjectId
        );
      }
      formData.append(`projects[${index}].courseProjectLink`, input.url);

      if (input.webImage instanceof File) {
        formData.append(
          `projects[${index}].courseProjectWebImage`,
          input.webImage
        );
      }
      if (input.mobileImage instanceof File) {
        formData.append(
          `projects[${index}].courseProjectMobileImage`,
          input.mobileImage
        );
      }
    });

    // 목록 썸네일 이미지 추가
    if (listThumbnail instanceof File) {
      formData.append("courseListThumbnailImage", listThumbnail);
    }

    try {
      const response = await call(
        `/api/v1/admin/training-course/${courseId}`,
        "PUT",
        formData,
        "multipart/form-data"
      );

      if (response.message) {
        alert("훈련과정이 성공적으로 수정되었습니다.");
        navigate("/admin/employtraining/employlist");
      } else {
        alert("수정에 실패했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("수정 중 오류가 발생했습니다.");
    }
  };

  // dataURL을 File 객체로 변환하는 유틸리티 함수
  const dataURLtoFile = (dataurl, filename) => {
    if (dataurl.startsWith("data:")) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
    return null;
  };

  // 이미지 미리보기를 위한 헬퍼 함수 수정
  const getImagePreviewUrl = (image) => {
    if (!image) return null;

    if (typeof image === "string") {
      return `/uploads/${image}`;
    }

    if (image instanceof File) {
      return URL.createObjectURL(image);
    }

    return null;
  };

  // 파일 삭제 함수 수정
  const deleteFile = async () => {
    try {
      const formData = new FormData();  // 빈 FormData 객체 생성
      await call(
        `/api/v1/admin/training-course/file/${courseId}`, 
        "PUT",
        formData,
        "multipart/form-data"  // Content-Type을 multipart/form-data로 변경
      );
      setCFile(null);
      alert("파일이 성공적으로 삭제되었습니다.");
    } catch (error) {
      console.error("파일 삭제 중 오류 발생:", error);
      alert("파일 삭제에 실패했습니다.");
    }
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
          취업 훈련 과정{" > "}취업 훈련 목록{" > "}취업 훈련 등록
        </div>
        <div className="main-content">
          <div className="title">훈련과정 등록</div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">과정 카테고리</div>
              <select
                className="drop-down-menu"
                value={category}
                onChange={handleVisibleChange}
              >
                {menu.map((item) => (
                  <option
                    key={item.categoryDetailId}
                    value={item.categoryDetailId}
                  >
                    {item.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">포트폴리오 공개여부</div>
              <div className="radio-button">
                <label>
                  <input
                    type="radio"
                    value="true"
                    checked={pVisible === true}
                    onChange={(e) => setPVisible(e.target.value === "true")}
                  />
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    value="false"
                    checked={pVisible === false}
                    onChange={(e) => setPVisible(e.target.value === "true")}
                  />
                  비공개
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">강좌명</div>
              <input
                className="long-title"
                maxLength={255}
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">
                태그
                <div className="caption">
                  메인페이지에만
                  <br />
                  표기됩니다
                  <br />[ , ]로 구분
                </div>
              </div>
              <input
                className="long-title"
                value={courseTag}
                onChange={(e) => setCourseTag(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">
                과정 설명글
                <div className="caption">
                  메인페이지에만
                  <br />
                  표기됩니다
                </div>
              </div>
              <textarea
                className="long-title"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="portfolio-image-zone">
            <div className="content-header">
              로드맵 이미지
              <div className="caption">
                메인페이지에만
                <br />
                표기됩니다
              </div>
            </div>
            <div className="image-container">
              <div className="img-tag">웹 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={(e) => setWebRoadmapImg(e.target.files[0])} // 파일 객체 저장
              />
              {webRoadmapImg && (
                <div className="image-preview-box">
                  <img
                    src={getImagePreviewUrl(webRoadmapImg)}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
              <div className="img-tag">모바일 이미지</div>
              <input
                type="file"
                className="add-mobile-image"
                onChange={(e) => setMobileRoadmapImg(e.target.files[0])} // 파일 객체 저장
              />
              {mobileRoadmapImg && (
                <div className="image-preview-box">
                  <img
                    src={getImagePreviewUrl(mobileRoadmapImg)}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="input-image-zone">
            <div className="content-header">썸네일 이미지</div>
            <div className="image-container">
              <div className="img-tag">썸네일 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setWThumbnail(file);
                  }
                }}
              />
              {wThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={
                      wThumbnail instanceof File
                        ? URL.createObjectURL(wThumbnail)
                        : `/uploads/${wThumbnail}`
                    }
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="input-image-zone">
            <div className="content-header">목록 썸네일 <br /> 이미지</div>
            <div className="image-container">
              <div className="img-tag">목록 썸네일 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setListThumbnail(file);
                  }
                }}
              />
              {listThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={
                      listThumbnail instanceof File
                        ? URL.createObjectURL(listThumbnail)
                        : `/uploads/${listThumbnail}`
                    }
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="image-content">
            <div className="edu-image-zone">
              {imageInputs.map((input, index) => (
                <div key={index}>
                  <div className="input-image-zone">
                    <div className="content-header">
                      컨텐츠 제목 {index + 1}
                    </div>
                    <div className="input-zone-container">
                      <div className="image-container">
                        <input
                          className="content-title"
                          value={input.title}
                          onChange={(e) => {
                            const newInputs = [...imageInputs];
                            newInputs[index].title = e.target.value;
                            setImageInputs(newInputs);
                          }}
                        />
                        <div className="img-tag">웹이미지</div>
                        <input
                          type="file"
                          className="add-web-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...imageInputs];
                              newInputs[index].webImage = file; // 파일 객체 저장
                              setImageInputs(newInputs);
                            }
                          }}
                        />
                        {input.webImage && (
                          <div className="image-preview-box">
                            <img
                              src={
                                input.webImage instanceof File
                                  ? URL.createObjectURL(input.webImage)
                                  : `/uploads/${input.webImage}`
                              }
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                        <div className="img-tag">모바일 이미지</div>
                        <input
                          type="file"
                          className="add-mobile-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...imageInputs];
                              newInputs[index].mobileImage = file; // 파일 체 저장
                              setImageInputs(newInputs);
                            }
                          }}
                        />
                        {input.mobileImage && (
                          <div className="image-preview-box">
                            <img
                              src={
                                input.mobileImage instanceof File
                                  ? URL.createObjectURL(input.mobileImage)
                                  : `/uploads/${input.mobileImage}`
                              }
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                      </div>
                      <button
                        className="delete-image-btn"
                        onClick={() => removeImageInput(index)}
                      >
                        - 삭제하기
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <button className="add-image-btn" onClick={addImageInput}>
                + 추가하기
              </button>
            </div>
          </div>
          <div className="portfolio-image-zone">
            <div className="content-header">
              커리큘럼
              <br />
              첨부파일
              <div className="caption">
                제목이 '커리큘럼'인
                <br />
                경우 다운로드
                <br />
                버튼이 표기됩니다.
              </div>
            </div>
            <div className="input-zone-container">
              <div className="image-container">
                <div className="img-tag">커리큘럼 첨부파일</div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={handleAddCFile}
                />
                {cFile && (
                  <div className="file-preview">
                    현재 파일:{" "}
                    {typeof cFile === "string"
                      ? cFile.split("/").pop()
                      : cFile.name}
                  </div>
                )}
              </div>
              <button
                className="delete-image-btn"
                onClick={deleteFile}
              >
                - 삭제하기
              </button>
            </div>
          </div>

          <div className="portfolio-image-zone">
            <div className="content-header">
              포트폴리오
              <br /> 설명이미지
            </div>
            <div className="image-container">
              <div className="img-tag">썸네일 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={handleWExplainImgChange}
              />
              {webExplainImg && (
                <div className="image-preview-box">
                  <img
                    src={getImagePreviewUrl(webExplainImg)}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="image-content">
            <div className="edu-image-zone">
              {portfolioInputs.map((input, index) => (
                <div key={index}>
                  <div className="input-image-zone">
                    <div className="content-header">
                      포트폴리오
                      <br />
                      링크{index + 1}
                    </div>
                    <div className="input-zone-container">
                      <div className="image-container">
                        <input
                          className="content-title"
                          value={input.url}
                          onChange={(e) => {
                            const newInputs = [...portfolioInputs];
                            newInputs[index].url = e.target.value;
                            setPortfolioInputs(newInputs);
                          }}
                        />
                        <div className="img-tag">웹 이미지</div>
                        <input
                          type="file"
                          className="add-web-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...portfolioInputs];
                              newInputs[index].webImage = file; // 파일 객체 저장
                              setPortfolioInputs(newInputs);
                            }
                          }}
                        />
                        {input.webImage && (
                          <div className="image-preview-box">
                            <img
                              src={
                                input.webImage instanceof File
                                  ? URL.createObjectURL(input.webImage)
                                  : `/uploads/${input.webImage}`
                              }
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                        <div className="img-tag">모바일 이미지</div>
                        <input
                          type="file"
                          className="add-mobile-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...portfolioInputs];
                              newInputs[index].mobileImage = file; // 파일 객체 저장
                              setPortfolioInputs(newInputs);
                            }
                          }}
                        />
                        {input.mobileImage && (
                          <div className="image-preview-box">
                            <img
                              src={
                                input.mobileImage instanceof File
                                  ? URL.createObjectURL(input.mobileImage)
                                  : `/uploads/${input.mobileImage}`
                              }
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                      </div>
                      <button
                        className="delete-image-btn"
                        onClick={() => removePortfolioImageInput(index)}
                      >
                        - 삭제하기
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="add-image-btn"
                onClick={addPortfolioImageInput}
              >
                + 추가하기
              </button>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyEmpTraining;
