import defaultImg from "images/defaultImg.png";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import "user/course/component/CourseCard.css";

// 모집 상태 관련 상수
const RECRUITMENT_STATUS = {
    CLOSED: "모집마감",
    UPCOMING: "모집예정"
};

// 이미지 경로 상수
const IMAGE_BASE_PATH = '/uploads/';

function CourseCard({ 
    imageSrc,       // 과정 썸네일 이미지 이름
    title,          // 과정명
    time,           // 교육 기간
    label,          // 모집 상태
    id,             // 과정 sessionId
    courseType,     // 과정 타입 (training/job)
    sessionAplicationStartDate // 과정 모집 시작일
}) {
    const navigate = useNavigate();
    
    // 모집 상태를 계산하는 함수
    const getRecruitmentStatus = () => {
        const currentTime = dayjs();
        const startDate = dayjs(sessionAplicationStartDate);
        
         // 신청 시작일이 현재보다 미래인 경우 '모집예정'
        if (startDate.isAfter(currentTime)) {
            return RECRUITMENT_STATUS.UPCOMING;
        }

        // 모집마감이거나 모집중인 경우
        return label === RECRUITMENT_STATUS.CLOSED 
            ? RECRUITMENT_STATUS.CLOSED 
            : `${RECRUITMENT_STATUS.CLOSED} ${label}`;
    };

    // 모집 상태에 따른 라벨 클래스 이름을 반환하는 함수
    const getLabelClassName = (status) => {
        const baseClass = 'course-label';
        if (status === RECRUITMENT_STATUS.CLOSED) return `${baseClass} course-closed-label`;
        if (status === RECRUITMENT_STATUS.UPCOMING) return `${baseClass} course-upcoming-label`;
        return baseClass;
    };

    // 이미지 로드 실패 시 기본 이미지로 대체하는 함수
    const handleImgError = (e) => {
        e.target.src = defaultImg;
    };

    // 과정 카드를 클릭했을 때 이동하는 함수
    const handleCardClick = () => {
        navigate(`/${courseType}/detail/${id}`);
    };

    return (
        <div className="course-card" onClick={handleCardClick}>
            {/* 이미지 섹션 */}
            <div className="image-wrap">
                <img 
                    src={`${IMAGE_BASE_PATH}${imageSrc}`} 
                    alt={`${title} 강좌 이미지`}
                    className="course-image" 
                    onError={handleImgError}
                />
                {/* 모집 상태 라벨 */}
                <span className={getLabelClassName(getRecruitmentStatus())}>
                    {getRecruitmentStatus()}
                </span>
            </div>  
            {/* 과정 정보 섹션 */}
            <div className="course-content">
                <h3 className="course-title">{title}</h3>
                <p className="course-time">{time}</p>
            </div>
        </div>
    );
}

export default CourseCard;