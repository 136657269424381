import connection from "images/icon_connection.svg";
import instagram from "images/icon_instagram.svg";
import kakao from "images/icon_kakao.svg";
import blog from "images/icon_naver_blog.svg";
import youtube from "images/icon_youtube.svg";
import { useEffect, useState } from 'react';
import { call } from "service/ApiService";
import "user/footer/Footer.css";

function Footer({setIsError}) {
    const [content, setContent] = useState({});
    const [site, setSite] = useState([]);

    const handleOnchangeSite = (e) => {
        const selectedIndex = e.target.selectedIndex - 1; // "Family site"를 제외하고 인덱스를 0부터 설정함
        if (selectedIndex >= 0) {
            window.open(site[selectedIndex].url, '_blank'); // 새 탭에서 열기
        }
    }

    useEffect(()=>{
        call('/api/v1/metadata/footer','GET').then((response)=>{
            setContent(response);
            setSite(response.familySite);
        }).catch((error)=>{
            setIsError(true);
        });
    },[]);
    
    return (
        <footer>
            
            <div className='footer-content'>

                <p className='footer-item-second'>
                    <span>상호 : {content.companyName} │ 대표 : {content.ceo}</span><br/>
                    <span>개인정보관리책임자 : {content.privacyOfficer}</span><br/>
                    <span>사업자 등록번호 : {content.businessRegistration}</span><br/>
                    <span>통신판매 : {content.communicationSalesNumber}</span>
                </p>

                <p className='footer-item-third'>
                    <span>주소 : {content.address}</span><br/>
                    <span>TEL : {content.tel}</span><br/>
                    <span>상담시간 : {content.consultingHours}</span>
                </p>

                <p className='footer-item-fourth'>
                    <img src={connection} alt='패밀리사이트'/>
                    <select 
                        className='family-site' 
                        // onClick으로 하면 어떤 주소를 마지막으로 선택했을 때 클릭 시 바로 해당 주소로 이동해버림
                        onChange={handleOnchangeSite}
                    >
                        <option>Family site</option>
                        {site.map((item, index) => (
                            <option key={index}>{item.siteName}</option>
                        ))}
                    </select>
                </p>
            
            </div>
            <div className='footer-content2'>             
                <p className='footer-item2-second footer-logo-web'>
                    <span> {content.copyright}</span>
                </p>

                <div className='sns_logo footer-item2-third footer-logo-web'>
                    <img className="kakao_logo" src={kakao} alt="카카오로고" onClick={() => { window.open(content.sns?.kakaoTalk); }} />
                    <img className="youtube_logo" src={youtube} alt="유튜브로고" onClick={() => { window.open(content.sns?.youtube); }} />
                    <img className="instagram_logo" src={instagram} alt="인스타그램로고" onClick={() => { window.open(content.sns?.instagram); }} />
                    <img className="blog_logo" src={blog} alt="네이버블로그로고" onClick={() => { window.open(content.sns?.blog); }} />
                </div>
            </div>
        </footer>
    );
}

export default Footer;