import React, { useState, useRef } from 'react';
import "user/story/commons/Search.css";

function Search({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false); // 검색창 가시성 상태
  const inputRef = useRef(null); // input에 접근하기 위한 ref

  // 특수문자 체크 함수 추가
  const containsSpecialCharacters = (str) => {
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]+/;
    return specialChars.test(str);
  };

  const handleSearchClick = () => {
    if (searchTerm && containsSpecialCharacters(searchTerm)) {
      alert("특수문자는 검색할 수 없습니다.");
      return;
    }
    onSearch(searchTerm); // 검색어를 상위 컴포넌트로 전달
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (searchTerm && containsSpecialCharacters(searchTerm)) {
        alert("특수문자는 검색할 수 없습니다.");
        return;
      }
      handleSearchClick(); // 엔터키가 눌리면 검색 실행
    }
  };

  const handleIconClick = () => {
    setIsSearchVisible(true); // 검색창 보이기
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // input에 포커스 설정
      }
    }, 0); // 다음 렌더 주기에 focus() 실행
  };

  return (
    <div className='search-container'>
      <div className='search-set-container'>
        {isSearchVisible ? (
          <>
            <input
              ref={inputRef} // ref를 input에 할당
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // 입력 값을 상태에 저장
              onKeyDown={handleKeyDown} // 엔터키 눌림 감지
              placeholder="검색어를 입력하세요"
            />
            <span onClick={handleSearchClick}>검색</span> {/* 검색 버튼 클릭 시 onSearch 호출 */}
          </>
        ) : (
          <div className="search-icon" onClick={handleIconClick}>
            <div className="lens"></div>
            <div className="handle"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Search;
