import React, { useEffect, useState } from "react";
import "admin/AdminPage.css";
import "admin/jobTraining/JobTraining.css";
import { call } from "service/ApiService";

function ChangePW(props) {
  const [adminInfo, setAdminInfo] = useState({
    adminInfoId: "",
    adminInfoName: "",
    adminInfoEmail: "",
    adminInfoPassword: "",
  });

  useEffect(() => {
    call("/api/v1/admin/admin-info", 'GET')
      .then(response => {
        setAdminInfo({
          adminInfoId: response.adminInfoId,
          adminInfoName: response.adminInfoName,
          adminInfoEmail: response.adminInfoEmail
        });
      })
      .catch(error => {
        alert(error.message);
      });
  }, []);

  useEffect(() => {
  }, [adminInfo]);

  const handleSubmit = async () => {
    const data = {
      adminInfoName: adminInfo.adminInfoName,
      adminInfoEmail: adminInfo.adminInfoEmail,
      adminInfoPassword: adminInfo.adminInfoPassword,
    };

    call("/api/v1/admin/admin-info/" + adminInfo.adminInfoId, 'PUT', data)
      .then(response => {
        alert(response.message);
        window.location.reload();
      })
      .catch(error => {
        alert(error.message);
      });
  };


  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">시스템 설정{" > "}관리자 정보 변경</div>
        <div className="main-content">
          <div className="title">관리자 정보 변경</div>
          <div className="info-title-caption">
            <div className="subtitle">이메일 발송을 위한 [gmail 설정 &gt; 전달 및 POP/IMAP 탭] 설정이 필요합니다.</div>
            <div className="subtitle">- [POP 다운로드] 설정은 [모든 메일에 POP 사용하기] 설정이 필요합니다.</div>
            <div className="subtitle">- [IMAP 엑세스] 설정은 [IMAP 사용], [자동 삭제 사용으로 변경] 설정이 필요합니다.</div>
            <hr />
          </div>
          
          <div className="option-zone">
            <div className="info-option-content">
              <div className="info-content-header">
                관리자명 입력
              </div>
              <div>
                <input
                  value={adminInfo.adminInfoName || ""}
                  className="long-title"
                  placeholder="ex) 퍼스트존"
                  onChange={(e) => setAdminInfo({ ...adminInfo, adminInfoName: e.target.value })}
                />
                <div className="info-caption">문의사항 답변 메일 제목에 표시됩니다.</div>
              </div>
            </div>
          </div>
          <div className="option-zone">
            <div className="info-option-content">
              <div className="info-content-header">
                이메일 입력
              </div>
              <div>
                <input
                  type="email"
                  className="long-title"
                  value={adminInfo.adminInfoEmail || ""}
                  placeholder="ex) firstzone@gmail.com"
                  onChange={(e) => setAdminInfo({ ...adminInfo, adminInfoEmail: e.target.value })}
                />
                <div className="info-caption">구글 계정 이메일을 입력해주세요.</div>
              </div>
            </div>
          </div>
          <div className="option-zone">
            <div className="info-option-content">
              <div className="info-content-header">
                비밀번호 입력
              </div>
              <div>
                <input
                  type="password"
                  className="long-title"
                  placeholder="ex) sdfs12veww43677g"
                  onChange={(e) => setAdminInfo({ ...adminInfo, adminInfoPassword: e.target.value })}
                />
                <div className="info-caption">[구글 계정 &gt; 앱 비밀번호] 에 있는 16자리 비밀번호를 입력해주세요.</div>
              </div>
            </div>
          </div>
          <hr />
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              변경
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePW;
