import React, { useRef, useState } from "react";
import "admin/AdminPage.css";
import "admin/story/Story.css";
import "@toast-ui/editor/dist/toastui-editor.css"; // Toast UI 기본 스타일 불러오기
import TuiEditor from "admin/TuiEditor";
import { call } from "service/ApiService";
import { useLocation, useNavigate } from "react-router-dom";
import { extractImageNames } from "admin/story/ExtractImageNames";

function AddNotice(props) {
  const [category, setCategory] = useState(""); // 과정 카테고리
  const [notice, setNotice] = useState({
    noticeTitle: null,
    noticeContent: null,
    noticeCategoryId: 0
  });
  const [deleteImages, setDeleteImages] = useState([]); // 삭제할 이미지들
  const editorRef = useRef(); // 에디터 레퍼런스 생성
  const navigate = useNavigate();
  const location = useLocation();
  const {categories} = location.state;

  // 카테고리 변경 함수
  const handleCategoryChange = (e) => {
    setNotice({
      ...notice,
      noticeCategoryId: e.target.selectedOptions[0].getAttribute('data-key')
    });
    setCategory(e.target.value);
  };

  // 에디터 내용 가져오는 함수
  const handleSubmit = () => {
    const editorInstance = editorRef.current.getInstance(); // 에디터 인스턴스 가져오기
    const content = editorInstance.getMarkdown(); // 에디터 내용 가져오기

    // deleteImages에서 contentImages에 포함된 이미지 제외
    const contentImages = extractImageNames(content);
    const finalDeleteImages = deleteImages.filter(img => !contentImages.includes(img));
    
    const data = {
      ...notice, 
      noticeContent: content,
      deleteImages: finalDeleteImages
    };

    call('/api/v1/admin/notice', 'POST', data)
    .then(response => {
      alert(response.message);
      navigate('/admin/withfirstzone/noticelist');
    })
    .catch(error=>{
      alert(error.message);
    });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">With 퍼스트존 {" > "} 공지사항</div>
        <div className="main-content">
          <div className="title">공지사항 등록</div>
          <div className="subtitle">새로운 공지사항을 등록합니다</div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">카테고리</div>
              <select
                className="drop-down-menu"
                value={category}
                onChange={handleCategoryChange}
              >
                <option key="" value="" data-key="">선택하세요</option>
                {categories.map((category) => (
                  <option key={category.categoryDetailId} value={category.categoryDetailName} data-key={category.categoryDetailId}>
                    {category.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">제목</div>
              <input className="long-title" 
                value={notice.noticeTitle}
                id="noticeTitle"
                onChange={(e) => {
                  if (e.target.value.length > 255) {
                    alert("제목은 최대 255자입니다.");
                    document.getElementById("noticeTitle").focus();  // id로 요소를 찾아 포커스
                    return;
                  }
                  setNotice({ ...notice, noticeTitle: e.target.value })
                }}/>
            </div>
          </div>
          <hr />
          <div className="text-zone">
            <div className="content-header">내용
              {/* <div className="caption">이미지 최대 넓이</div>
              <div className="caption">(웹, 태블릿, 모바일)</div>
              <div className="caption">(770, 560, 205) 이하 권장</div> */}
            </div>
            <div className="editor-area">
                <TuiEditor 
                content={notice.noticeContent}
                editorRef={editorRef}
                setDeleteImages={setDeleteImages} />
              </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNotice;
