import { useEffect, useState } from 'react';
import { call } from 'service/ApiService';
import AccordionBox from "user/register/component/AccordionBox";
import "user/register/component/AgreementBox.css";
import { useMember } from "user/register/RegisterPage";

function AgreementBox({ category,checkList,setCheckList, sessionId }) {
    const { userInfo, setUserInfo,setIsError } = useMember();
    const [content, setContent] = useState([]);

    // 전체 동의 버튼
    const checkAll = (e) => {
        const isChecked = e.target.checked;
        const newCheckList = category === "career" 
            ? ["jobIsConsentCondition", "jobIsConsentInformation", "jobIsConsentProvide", "jobIsConsentAdvertise"]
            : ["employIsCondition", "employIsInformation", "employIsProvide", "employIsAdvertise"];

        setCheckList(isChecked ? newCheckList : []);
        const updatedUserInfo = newCheckList.reduce((acc, key) => {
            acc[key] = isChecked;
            return acc;
        }, {});
        setUserInfo({ ...userInfo, ...updatedUserInfo });
    };

    // 각각의 동의 버튼
    const check = (name) => {
        const isChecked = !checkList.includes(name);
        const newCheckList = isChecked
            ? [...checkList, name]
            : checkList.filter(choice => choice !== name);

        setCheckList(newCheckList);
        setUserInfo({ ...userInfo, [name]: isChecked });
    };

    // 동의 항목 전체 선택 상태 업데이트
    useEffect(() => {
        const requiredList = category === "career" 
            ? ["jobIsConsentCondition", "jobIsConsentInformation", "jobIsConsentProvide", "jobIsConsentAdvertise"]
            : ["employIsCondition", "employIsInformation", "employIsProvide", "employIsAdvertise"];

        // '전체 동의' 버튼이 체크되어 있으면 모든 항목을 포함시킴
        const isAllChecked = requiredList.every(item => checkList.includes(item));
        
        if (isAllChecked && checkList.length < requiredList.length) {
            setCheckList(requiredList);
        }
    }, [checkList, category]);

    // 동의 항목 내용 불러오기
    useEffect(()=>{
        call(`/api/v1/training-session/agree/${sessionId}`,"GET").then((response)=>{
            setContent(response);
        }).catch((error)=>setIsError(true));
    },[]);

    return (
        <div className='agreement-container'>
            <div className='agreement-all'>
                <div className="checkmark-container" onClick={() => checkAll({ target: { checked: checkList.length !== 4 } })}>
                    <span className={checkList.length === 4 ? "checkedmark" : "checkmark"} />
                </div>
                <p className='info-title-text'>전체 동의</p>
            </div>

            <div className='agreement-box-content'>
                <AccordionBox 
                    checkList={checkList} 
                    check={check}
                    title="(필수) 서비스 이용약관"
                    content={content.sessionCondition}
                    name={category === "career" ? "jobIsConsentCondition" : "employIsCondition"}
                    number={1}
                />
                <AccordionBox 
                    checkList={checkList} 
                    check={check}
                    title="(필수) 개인정보 수집 및 이용 동의"
                    content={content.sessionInformation}
                    name={category === "career" ? "jobIsConsentInformation" : "employIsInformation"}
                    number={2}
                />
                <AccordionBox 
                    checkList={checkList} 
                    check={check}
                    title="(필수) 개인정보 제 3자 제공 동의"
                    content={content.sessionProvide}
                    name={category === "career" ? "jobIsConsentProvide" : "employIsProvide"}
                    number={3}
                />
                <AccordionBox 
                    checkList={checkList} 
                    check={check}
                    title="(선택) 마케팅 활용 동의"
                    content={content.sessionAdvertise}
                    name={category === "career" ? "jobIsConsentAdvertise" : "employIsAdvertise"}
                    number={4}
                    className="no-border"
                />
            </div>
        </div>
    );
}

export default AgreementBox;