import React, { useState, useEffect } from "react";
import "admin/siteContents/SiteContent.css";
import { call } from "service/ApiService";

function Firstmap(props) {
  const [webImages, setWebImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);
  const [inputCount, setInputCount] = useState(1);
  const [webTrafficImg, setWebTrafficImg] = useState(null);
  const [mobileTrafficImg, setMobileTrafficImg] = useState(null);

  useEffect(() => {
    call("/api/v1/admin/metadata/first-map", "GET")
      .then((response) => {
        const loadedWebImages = []; // 초기화
        const loadedMobileImages = []; // 초기화

        for (let images of response.educationEnvironmentImages) {
          loadedWebImages.push({
            preview: `/uploads/${images.webImage}`,
            file: new File([], ""),
          });
          loadedMobileImages.push({
            preview: `/uploads/${images.mobileImage}`,
            file: new File([], ""),
          });
        }

        setInputCount(response.educationEnvironmentImages.length);

        setWebImages(loadedWebImages);
        setMobileImages(loadedMobileImages);
        setWebTrafficImg({
          preview: `/uploads/${response.transportationImage.webImage}`,
          file: new File([], ""),
        });
        setMobileTrafficImg({
          preview: `/uploads/${response.transportationImage.mobileImage}`,
          file: new File([], ""),
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  const handleImageChange = (index, e, setImages) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const newImages = [
          ...(setImages === setWebImages ? webImages : mobileImages),
        ];
        newImages[index] = { preview: reader.result, file: file };
        setImages(newImages);
      };
    }
  };

  const addImageInput = () => {
    setInputCount(inputCount + 1);
    setWebImages([...webImages, ""]);
    setMobileImages([...mobileImages, ""]);
  };

  const deleteImageInput = (index) => {
    const newWebImages = [...webImages];
    const newMobileImages = [...mobileImages];
    newWebImages.splice(index, 1); // 해당 인덱스의 웹 이미지 삭제
    newMobileImages.splice(index, 1); // 해당 인덱스의 모바일 이미지 삭제
    setWebImages(newWebImages);
    setMobileImages(newMobileImages);
    setInputCount(inputCount - 1);
  };

  const handleTrafficImgChange = (e, setImages) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImages({ preview: reader.result, file: file });
      };
    }
  };

  const handleSave = () => {
    const formData = new FormData();

    webImages.forEach((image, index) => {
      if (image.file) {
        formData.append(
          `educationEnvironmentImages[${index}].webImage`,
          image.file
        );
      }
    });

    mobileImages.forEach((image, index) => {
      if (image.file) {
        formData.append(
          `educationEnvironmentImages[${index}].mobileImage`,
          image.file
        );
      }
    });

    if (webTrafficImg && webTrafficImg.file) {
      formData.append("transportationImage.webImage", webTrafficImg.file);
    }

    if (mobileTrafficImg && mobileTrafficImg.file) {
      formData.append("transportationImage.mobileImage", mobileTrafficImg.file);
    }

    call(
      "/api/v1/admin/metadata/first-map",
      "PUT",
      formData,
      "multipart/form-data"
    )
      .then((response) => {
        alert("이미지가 성공적으로 저장되었습니다.");
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">사이트 컨텐츠 {" > "} 퍼스트맵</div>
        <div className="main-content">
          <div className="image-content">
            <div className="content-header">
              교육환경
              <br />
              이미지
            </div>
            <div className="edu-image-zone">
              {Array.from({ length: inputCount }).map((_, index) => (
                <div key={index}>
                  <div className="input-image-zone2">
                    <div className="image-container">
                      <div className="img-tag">웹이미지 <div className="caption"></div></div>
                      <input
                        type="file"
                        className="add-web-image"
                        onChange={(e) =>
                          handleImageChange(index, e, setWebImages)
                        }
                      />
                      {webImages[index] && (
                        <div className="image-preview-box">
                          <img
                            src={webImages[index].preview}
                            alt="미리보기"
                            className="image-preview"
                          />
                        </div>
                      )}
                      <div className="img-tag">모바일 이미지 <div className="caption"></div></div>
                      <input
                        type="file"
                        className="add-mobile-image"
                        onChange={(e) =>
                          handleImageChange(index, e, setMobileImages)
                        }
                      />
                      {mobileImages[index] && (
                        <div className="image-preview-box">
                          <img
                            src={mobileImages[index].preview}
                            alt="미리보기"
                            className="image-preview"
                          />
                        </div>
                      )}
                    </div>
                    <button
                      className="delete-image-btn"
                      onClick={() => deleteImageInput(index)}
                    >
                      - 삭제하기
                    </button>
                  </div>
                </div>
              ))}
              <button className="add-image-btn" onClick={addImageInput}>
                + 추가하기
              </button>
            </div>
          </div>
          <div className="image-zone">
            <div className="input-image-zone">
              <div className="content-header">
                교통안내
                <br />
                이미지
              </div>
              <div className="image-container">
                <div className="img-tag">웹이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-web-image"
                  onChange={(e) => handleTrafficImgChange(e, setWebTrafficImg)}
                />
                {webTrafficImg && (
                  <div className="image-preview-box">
                    <img
                      src={webTrafficImg.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
                <div className="img-tag">모바일 이미지 <div className="caption"></div></div>
                <input
                  type="file"
                  className="add-mobile-image"
                  onChange={(e) =>
                    handleTrafficImgChange(e, setMobileTrafficImg)
                  }
                />
                {mobileTrafficImg && (
                  <div className="image-preview-box">
                    <img
                      src={mobileTrafficImg.preview}
                      alt="미리보기"
                      className="image-preview"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSave}>
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Firstmap;
