import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Banner from 'user/commons/Banner';
import ErrorPageApi from "user/commons/ErrorPageApi";
import PageLayout from 'user/PageLayout';
import 'user/register/RegisterPage.css';

// 사용자 정보를 공유하기 위한 Context 생성
export const UserContext = createContext();
// Context를 쉽게 사용하기 위한 커스텀 훅
export const useMember = () => useContext(UserContext);

// 사용자 정보 초기 상태
const initialUserInfo = {};

function RegisterPage() {

    const [userInfo, setUserInfo] = useState(initialUserInfo);
    const {courseType} = useParams();
    const handleChange = useCallback((e) => {
        setUserInfo(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }, []);

     // 에러 상태 관리
    const [isError, setIsError] = useState(false);

    // Context에 전달할 값 메모이제이션
    const contextValue = useMemo(() => ({
        userInfo, // 사용자 정보
        setUserInfo, // 사용자 정보 업데이트 함수
        handleChange, // 입력 필드 변경 핸들러
        setIsError // 에러 상태 업데이트 함수
    }), [userInfo, handleChange]);

    // 에러 페이지
    if (isError) {
        return <ErrorPageApi />;
    }

    return (
        <PageLayout setIsError={setIsError}>
            <Banner category={courseType} /> 
            <UserContext.Provider value={contextValue}>
                <Outlet />
            </UserContext.Provider>
        </PageLayout>
    );
}

export default RegisterPage;
