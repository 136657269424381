import React, { useRef, useState, useEffect } from "react";
import { call } from "service/ApiService";  
import download from "images/download.png";
import close from "images/close.png";
import pom from "images/pompom.jpg";
import { useParams } from "react-router-dom";

function EmpVolunteer(props) {
  const {sessionId} = useParams();
  const [elementCount, setElementCount] = useState("");
  const [stateContent, setStateContent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const modalBackground = useRef();
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1); // 총 페이지 수 상태 추가
  const [courseName, setCourseName] = useState(""); // 과정명을 저장할 state 추가

  // 정렬 상태를 관리하기 위한 state 수정
  const [sortState, setSortState] = useState({
    number: '',    // 지원순번 정렬
    name: '',      
    birth: '',
    score: '',
    date: '',
    state: ''
  });

  // 상태 목록을 저장할 state 추가
  const [stateOptions, setStateOptions] = useState([]);

  // 수정된 데이터를 추적하기 위한 state 추가
  const [modifiedData, setModifiedData] = useState({});

  // 점수 변경 핸들러 수정
  const handleScoreChange = (employId, score) => {
    // 숫자만 추출하고 3자리로 제한
    const numericValue = score.replace(/[^0-9]/g, '').slice(0, 3);
    
    const course = courses.find(c => c.employId === employId);
    setModifiedData(prev => ({
      ...prev,
      [employId]: {
        ...prev[employId],
        employScore: numericValue,
        employNote: prev[employId]?.employNote ?? course.employNote
      }
    }));
  };

  // 비고 변경 핸들러 수정
  const handleNoteChange = (employId, note) => {
    const course = courses.find(c => c.employId === employId);
    setModifiedData(prev => ({
      ...prev,
      [employId]: {
        ...prev[employId],
        employNote: note,
        employScore: prev[employId]?.employScore ?? course.employScore
      }
    }));
  };

  // 저장 버튼 핸들러 수정
  const handleSave = async () => {
    try {
      if (Object.keys(modifiedData).length === 0) {
        alert('수정된 내용이 없습니다.');
        return;
      }

      if (!window.confirm('수정된 내용을 저장하시겠습니까?')) {
        return;
      }

      const updatePromises = Object.entries(modifiedData).map(([employId, data]) => {
        const updateData = {
          employStateId: courses.find(course => course.employId === Number(employId))?.employStateId,
          employScore: data.employScore,
          employNote: data.employNote
        };
        
        return call(`/api/v1/admin/employ-training/${employId}`, 'PUT', updateData);
      });

      await Promise.all(updatePromises);
      
      alert('저장되었습니다.');
      setModifiedData({});
      
      // 현재 페이지의 데이터만 다시 불러오기
      fetchCourses(currentPage, elementCount);

    } catch (error) {
      console.error('저장 실패:', error);
      alert('저장에 실패했습니다. 다시 시도해주세요.');
    }
  };

  // 상태 목록을 불러오는 함수
  const fetchStateOptions = () => {
    call(`/api/v1/category/select?categoryName=취업상태`, 'GET')
      .then((response) => {
        setStateOptions(response || []);
      })
      .catch((error) => {
        console.error('상태 목록 조회 실패:', error);
        alert('상태 목록을 불러오는데 실패했습니다.');
      });
  };

  // 컴포넌트 마운트 시 상태 목록 불러오기
  useEffect(() => {
    fetchStateOptions();
  }, []);

  // 정렬 처리 함수 수정
  const handleSort = async (field) => {
    try {
      // 새로운 정렬 상태 설정
      const newState = {
        number: '',
        name: '',
        birth: '',
        score: '',
        date: '',
        state: sortState.state
      };

      // 현재 필드의 정렬 상태 변경
      if (!sortState[field]) newState[field] = 'asc';
      else if (sortState[field] === 'asc') newState[field] = 'desc';
      else newState[field] = '';

      // 정렬 상태 즉시 업데이트
      setSortState(newState);
      setCurrentPage(0);

      // URL 파라미터 초기화
      const url = new URL(window.location.href);
      url.searchParams.delete('nameOrder');
      url.searchParams.delete('dateOrder');
      url.searchParams.delete('birthOrder');
      url.searchParams.delete('scoreOrder');

      // 번호 정렬
      if (field === 'number') {
        setIsLocalSort(true);
        setAllSortedData(null); // 기존 정렬 데이터 초기화
        
        const response = await call(`/api/v1/admin/employ-training/applicant/${sessionId}?page=0&size=99999`, 'GET');
        
        if (response && response.content) {
          let allCourses = response.content.map((course) => ({
            ...course,
            courseCreateTime: course.employCreateTime.split('T')[0]
          }));

          // 번호 기준 정렬
          allCourses.sort((a, b) => {
            const aNum = parseInt(a.employNumber);
            const bNum = parseInt(b.employNumber);
            return newState.number === 'asc' ? aNum - bNum : bNum - aNum;
          });

          // 전체 데이터 저장
          setAllSortedData(allCourses);
          
          // 현재 페이지 데이터 설정
          const pageSize = elementCount || 10;
          setCourses(allCourses.slice(0, pageSize));
          setTotalPage(Math.ceil(allCourses.length / pageSize));
        }
      } else {
        setIsLocalSort(false);
        setAllSortedData(null);

        if (newState[field]) {
          switch(field) {
            case 'name':
              url.searchParams.set('nameOrder', newState.name);
              break;
            case 'date':
              url.searchParams.set('dateOrder', newState.date);
              break;
            case 'birth':
              url.searchParams.set('birthOrder', newState.birth);
              break;
            case 'score':
              url.searchParams.set('scoreOrder', newState.score);
              break;
          }
        }
        
        window.history.pushState({}, '', url);
        fetchCourses(0, elementCount || 10);
      }
    } catch (error) {
      console.error('정렬 실패:', error);
      alert('정렬에 실패했습니다. 다시 시도해주세요.');
    }
  };

  // 전체 정렬된 데이터를 저장할 state 추가
  const [allSortedData, setAllSortedData] = useState(null);

  // 정렬 파라미터 변환 함수 추가
  const getSortParam = (field, direction) => {
    const fieldMapping = {
      name: 'employName',
      birth: 'employBirth',
      score: 'employScore',
      date: 'employCreateTime'
    };
    
    return fieldMapping[field] ? `${fieldMapping[field]},${direction}` : null;
  };

  // 훈련 리스트 불러오기 함수 수정
  const fetchCourses = (page = 0, size = elementCount) => {
    const validSize = size || 10;
    
    let url = `/api/v1/admin/employ-training/applicant/${sessionId}?page=${page}&size=${validSize}`;
    
    // 정렬 파라미터 추가 (number 정렬 포함)
    if (sortState.number) {
      url += `&numberOrder=${sortState.number}`;
    } else if (sortState.name) {
      url += `&nameOrder=${sortState.name}`;
    } else if (sortState.date) {
      url += `&dateOrder=${sortState.date}`;
    } else if (sortState.birth) {
      url += `&birthOrder=${sortState.birth}`;
    } else if (sortState.score) {
      url += `&scoreOrder=${sortState.score}`;
    }

    if (sortState.state) {
      url += `&employStateId=${sortState.state}`;
    }

    call(url, 'GET')
      .then((response) => {
        if (response && response.content) {
          const formattedCourses = response.content.map((course) => ({
            ...course,
            courseCreateTime: course.employCreateTime.split('T')[0]
          }));
          
          setCourses(formattedCourses);
          setTotalPage(response.totalPages);
        }
      })
      .catch((error) => {
        console.error('데이터 로딩 실패:', error);
        alert('데이터를 불러오는데 실패했습니다.');
      });
  };

  // useEffect 수정
  useEffect(() => {
    if (!isLocalSort) {
      fetchCourses(currentPage, elementCount || 10);
    }
  }, [currentPage, elementCount, sortState, sessionId]);

  const handleElementCountChange = (e) => {
    // 문자열에서 숫자만 추출
    const number = parseInt(e.target.value);
    setElementCount(number);
  };

  // 페이지 변경 핸들러 수정
  const handlePageChange = (page) => {
    if (page < 0 || page >= totalPage) return;
    setCurrentPage(page);
    
    if (isLocalSort && allSortedData) {
      // 로컬 정렬일 경우 저장된 전체 데이터에서 페이지에 해당하는 데이터만 표시
      const pageSize = elementCount || 10;
      const start = page * pageSize;
      const end = start + pageSize;
      setCourses(allSortedData.slice(start, end));
    } else {
      // URL에 페이지 정보 추가
      const url = new URL(window.location.href);
      url.searchParams.set('page', page);
      window.history.pushState({}, '', url);
    }
  };

  // 컴포넌트 초기 마운트 시 URL의 페이지 정보 확인하는 useEffect 수정
  useEffect(() => {
    const url = new URL(window.location.href);
    
    // 정렬 파라미터 중 하나만 적용
    const nameOrder = url.searchParams.get('nameOrder');
    const dateOrder = url.searchParams.get('dateOrder');
    const birthOrder = url.searchParams.get('birthOrder');
    const scoreOrder = url.searchParams.get('scoreOrder');
    
    // 정렬 상태 복원 (하나만 적용)
    const newSortState = {
      name: '',
      birth: '',
      score: '',
      date: '',
      state: sortState.state
    };

    if (nameOrder) newSortState.name = nameOrder;
    else if (dateOrder) newSortState.date = dateOrder;
    else if (birthOrder) newSortState.birth = birthOrder;
    else if (scoreOrder) newSortState.score = scoreOrder;
    
    setSortState(newSortState);
  }, []);

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10; // 최대 페이지 번호 수
  
    const startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPage - 1, startPage + maxPagesToShow - 1);
    // 계산된 페이 번호 추가 (최대 10개까지만 표시)
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  };

  // 상태 변경 핸들러 수정
  const handleStateChange = (employId, value) => {
    const newState = value;
    
    if (employId) {
      // 개별 지원자의 상태 변경
      const course = courses.find(c => c.employId === employId);
      setModifiedData(prev => ({
        ...prev,
        [employId]: {
          ...prev[employId],
          employStateId: newState,
          employScore: prev[employId]?.employScore ?? course.employScore,
          employNote: prev[employId]?.employNote ?? course.employNote
        }
      }));
    } else {
      // 전체 필터링을 위한 상태 변경
      setSelectedState(newState);
      
      // URL 파라미터 업데이트
      const url = new URL(window.location.href);
      if (newState) {
        url.searchParams.set('state', newState);
      } else {
        url.searchParams.delete('state');
      }
      window.history.pushState({}, '', url);
      
      // API 호출
      fetchCourses(0, elementCount);
    }
  };

  // 상태 ID로 상태명을 찾는 함수 추가
  const getStateName = (stateId) => {
    const state = stateOptions.find(option => option.categoryDetailId === stateId);
    return state ? state.categoryDetailName : '';
  };

  // 선택된 지원자 정보를 저장할 state 추가
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  // 선택된 지원자의 상세 정보를 저장할 state 추가
  const [selectedApplicantDetail, setSelectedApplicantDetail] = useState(null);

  // 상세 정보를 가져오는 함수 수정
  const fetchApplicantDetail = async (employId) => {
    try {
      const response = await call(`/api/v1/admin/employ-training/${employId}`, 'GET');
      
      // null 값 처리를 위한 기본값 설정
      const processedData = {
        ...response,
        employAddPhonenumber: response.employAddPhonenumber || '-',
        employGraduationDate: response.employGraduationDate || '-',
        employMajor: response.employMajor || '-',
        employSchoolName: response.employSchoolName || '-',
        employNote: response.employNote || '-',
        employScore: response.employScore || '-',
        employStateId: response.employStateId || '-',
        employStateName: response.employStateName || '상태 미지정'
      };
      
      setSelectedApplicantDetail(processedData);
    } catch (error) {
      console.error('상세 정보 조회 실패:', error);
      alert('상세 정보를 불러오는데 실패했습니다.');
    }
  };

  // 모달 열기 함수 수정
  const handleOpenModal = async (applicant) => {
    setSelectedApplicant(applicant);
    await fetchApplicantDetail(applicant.employId);
    setModalOpen(true);
  };

  // 파일 다운로드 함수 수정
  const handleFileDownload = async (employId, employName) => {
    try {
      const accessToken = localStorage.getItem('ACCESS_TOKEN');
      
      console.log('다운로드 시도:', employId); // 요청 ID 로깅

      const response = await fetch(`/api/v1/admin/employ-training/download/${employId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      // 응답 상태 자세히 로깅
      console.log('응답 상태:', response.status);
      console.log('응답 헤더:', Object.fromEntries(response.headers.entries()));

      if (!response.ok) {
        // 에러 응답 자세히 확인
        const errorText = await response.text();
        console.log('에러 응답 전문:', errorText);
        
        // Content-Type 확인
        const contentType = response.headers.get('content-type');
        console.log('응답 Content-Type:', contentType);

        throw new Error(`다운로드 실패 (${response.status}): ${errorText}`);
      }

      const blob = await response.blob();
      console.log('다운로드된 파일 크기:', blob.size);
      console.log('다운로드된 파일 타입:', blob.type);

      if (blob.size === 0) {
        throw new Error('빈 파일이 다운로드되었습니다.');
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const fileName = `지원자_${employName || '미상'}.pdf`;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    } catch (error) {
      console.error('파일 다운로드 실패 상세:', error);
      alert(`파일 다운로드에 실패했습니다: ${error.message}`);
    }
  };

  // 전체 다운로드 함수 수정
  const handleAllDownload = async () => {
    try {
      const accessToken = localStorage.getItem('ACCESS_TOKEN');
      
      if (!sessionId) {
        alert('세션 ID가 유효하지 않습니다.');
        return;
      }

      const apiUrl = `/api/v1/admin/employ-training/download-all/${sessionId}`;

      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        console.error('Response status:', response.status);
        console.error('Response statusText:', response.statusText);
        throw new Error('다운로드 실패');
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `지원자_전체.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    } catch (error) {
      console.error('전체 다운로드 실패:', error);
      alert('파일 다운로드에 실패했습니다.');
    }
  };

  // 삭제 함수 추가
  const handleDelete = async (employId) => {
    try {
      if (!window.confirm('정말로 이 지원자를 삭제하시겠습니까?')) {
        return;
      }

      const response = await call(`/api/v1/admin/employ-training/${employId}`, 'DELETE');
      
      if (response) {  // 삭제 성공
        alert('지원자가 삭제되었습니다.');
        setModalOpen(false);  // 모달 닫기
        fetchCourses();  // 목록 새로고침
      }
    } catch (error) {
      console.error('삭제 실패:', error);
      alert('삭에 실패했습니다.');
    }
  };

  // 과정명을 가져오는 함수 수정
  const fetchCourseName = async () => {
    try {
      const response = await call(`/api/v1/admin/training-session/${sessionId}`, 'GET');
      if (response && response.sessionName) {
        setCourseName(response.sessionName);
      } else {
        console.log('과정명을 찾을 수 없습니다');
        setCourseName('과정명 없음');
      }
    } catch (error) {
      console.error('과정명 조회 실패:', error);
      setCourseName('과정명 조회 실패');
    }
  };

  // sessionId가 있을 때 과정명 조회
  useEffect(() => {
    if (sessionId) {
      fetchCourseName();
    }
  }, [sessionId]);

  // 이미지 URL을 처리하는 헬퍼 함수 추가
  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `/uploads/${imagePath}`;
  };

  // 새로 state 추가
  const [isLocalSort, setIsLocalSort] = useState(false);

  // 상태 필터 state 추가 (없다면)
  const [selectedState, setSelectedState] = useState('');

  // 개별 지원자의 상태 변경 핸들러
  const handleIndividualStateChange = (employId, value) => {
    const course = courses.find(c => c.employId === employId);
    
    // courses 상태도 함께 업데이트
    setCourses(prevCourses => 
      prevCourses.map(c => 
        c.employId === employId 
          ? { ...c, employStateId: value }
          : c
      )
    );

    setModifiedData(prev => ({
      ...prev,
      [employId]: {
        ...prev[employId],
        employStateId: value,
        employScore: prev[employId]?.employScore ?? course.employScore,
        employNote: prev[employId]?.employNote ?? course.employNote
      }
    }));
  };

  // 필터링을 한 전체 상태 변경 핸들러 (프론트엔드 필터링 버전)
  const handleFilterStateChange = (value) => {
    console.log('선택된 상태 ID:', value);
    
    // 전체 데이터 가져오기
    let url = `/api/v1/admin/employ-training/applicant/${sessionId}?page=0&size=${elementCount || 10}`;
    
    call(url, 'GET')
      .then((response) => {
        if (response && response.content) {
          let formattedCourses = response.content.map((course) => ({
            ...course,
            courseCreateTime: course.employCreateTime.split('T')[0]
          }));

          // 선택된 상태값이 있는 경우에만 필터링
          if (value) {
            formattedCourses = formattedCourses.filter(
              course => course.employStateId?.toString() === value.toString()
            );
          }
          
          setCourses(formattedCourses);
          setTotalPage(Math.ceil(formattedCourses.length / (elementCount || 10)));
        }
      })
      .catch((error) => {
        console.error('데이터 로딩 실패:', error);
        alert('데이터를 불러오는데 실패했습니다.');
      });
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
          취업 훈련 과정 {" > "} 취업 훈련 지원자 관리
        </div>
        <div className="main-content">
          <div className="title">{courseName} 지원자 목록</div>
          <div className="subtitle">해당 과정에 지원한 지원자입니다 (- 표기가 있는 항목을 클릭하면 정렬 순서를 변경할 수 있습니다)</div>
          <div className="subtitle">지원자별 상태는 변경 즉시 반영되어 저장 버튼을 누를 필요 없습니다</div>
          <hr />
          <div className="down-option-zone">
            <div className="option-content">
              <div className="option-subtitle">보기</div>
              <select
                className="drop-down-menu"
                value={elementCount}
                onChange={handleElementCountChange}
              >
                <option value="10">10개씩 보기</option>
                <option value="20">20개씩 보기</option>
                <option value="50">50개씩 보기</option>
                <option value="100">100개씩 보기</option>
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">상태 필터</div>
              <select 
                className="drop-down-menu"
                onChange={(e) => handleFilterStateChange(e.target.value)}
              >
                <option value="">전체</option>
                {stateOptions.map((option) => (
                  <option 
                    key={option.categoryDetailId} 
                    value={option.categoryDetailId}
                  >
                    {option.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
            <div className="pdf-download">
              <button type="button" className="all-download" onClick={handleAllDownload}>
                모든 지원자 이력 다운로드
              </button>
            </div>
          </div>
          <div className="list-zone">
            <table className="list-table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('number')} style={{cursor: 'pointer'}}>
                    번호 {
                      sortState.number === 'asc' ? '↑' : 
                      sortState.number === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th onClick={() => handleSort('name')} style={{cursor: 'pointer'}}>
                    이름 {
                      sortState.name === 'asc' ? '↑' : 
                      sortState.name === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th onClick={() => handleSort('birth')} style={{cursor: 'pointer'}}>
                    생년월일 {
                      sortState.birth === 'asc' ? '↑' : 
                      sortState.birth === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th>성별</th>
                  <th onClick={() => handleSort('date')} style={{cursor: 'pointer'}}>
                    지원일 {
                      sortState.date === 'asc' ? '↑' : 
                      sortState.date === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th onClick={() => handleSort('score')} style={{cursor: 'pointer'}}>
                    점수 {
                      sortState.score === 'asc' ? '↑' : 
                      sortState.score === 'desc' ? '↓' : 
                      '-'
                    }
                  </th>
                  <th>상태</th>
                  <th>비고</th>
                  <th>내려받기</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.employId}>
                    <td className="empText" onClick={() => handleOpenModal(course)}>{course.employNumber}</td>
                    <td className="empText" onClick={() => handleOpenModal(course)}>{course.employName}</td>
                    <td>{course.employBirth}</td>
                    <td>{course.employGender}</td>
                    <td>{course.employCreateTime.split('T')[0]}</td>
                    <td>
                      <input
                        type="text"
                        className="score-zone"
                        value={modifiedData[course.employId]?.employScore ?? course.employScore}
                        onChange={(e) => handleScoreChange(course.employId, e.target.value)}
                        maxLength={3}
                      />
                    </td>
                    <td>
                      <select 
                        className="drop-down-menu-not-marginleft" 
                        value={course.employStateId ? course.employStateId.toString() : ''}
                        onChange={(e) => handleIndividualStateChange(course.employId, e.target.value)}
                      >
                        <option value="">상태</option>
                        {stateOptions.map((option) => (
                          <option key={option.categoryDetailId} value={option.categoryDetailId}>
                            {option.categoryDetailName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="note-zone input-width-set"
                        value={modifiedData[course.employId]?.employNote ?? course.employNote}
                        onChange={(e) => handleNoteChange(course.employId, e.target.value)}
                      />
                    </td>
                    <td>
                      <img 
                        src={download} 
                        alt="다운로드" 
                        onClick={() => handleFileDownload(course.employId, course.employName)}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {modalOpen && selectedApplicantDetail && (
            <div
              className="modal-container"
              ref={modalBackground}
              onClick={(e) => {
                if (e.target === modalBackground.current) {
                  setModalOpen(false);
                }
              }}
            >
              <div className="modal-content">
                <div className="close-area">
                  <img
                    className="closeBtn"
                    src={close}
                    alt="닫기"
                    onClick={() => setModalOpen(false)}
                  />
                </div>
                <div className="modal-header-content">
                  <div className="modal-header-name">{selectedApplicantDetail.employName}</div>
                  <div className="modal-header-score">점수 : {selectedApplicantDetail.employScore}</div>
                  <div className="modal-header-pass">{selectedApplicantDetail.employStateName}</div>
                </div>
                <div className="modal-personal-content">
                  <table>
                    <tbody>
                      <tr>
                        <th>성별</th>
                        <td>{selectedApplicantDetail.employGender}</td>
                        <td className="profile" rowSpan={6}>
                          {selectedApplicantDetail.employProfileImage && (
                            <img 
                              src={getImageUrl(selectedApplicantDetail.employProfileImage)} 
                              alt="지원자 프로필" 
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>생년월일</th>
                        <td>{selectedApplicantDetail.employBirth}</td>
                      </tr>
                      <tr>
                        <th>연락처</th>
                        <td>{selectedApplicantDetail.employPhonenumber}</td>
                      </tr>
                      <tr>
                        <th>추가 연락처</th>
                        <td>{selectedApplicantDetail.employAddPhonenumber || '-'}</td>
                      </tr>
                      <tr>
                        <th>이메일</th>
                        <td>{selectedApplicantDetail.employEmail}</td>
                      </tr>
                      <tr>
                        <th>주소</th>
                        <td>{selectedApplicantDetail.employAddress}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="modal-info-content">
                  <table>
                    <tbody>
                      <tr>
                        <th>최종 학력</th>
                        <td>
                          {`${selectedApplicantDetail.employFinalEducation || '-'} / 
                            ${selectedApplicantDetail.employSchoolName || '-'} / 
                            ${selectedApplicantDetail.employMajor || '-'} / 
                            ${selectedApplicantDetail.employGraduationDate || '-'}`}
                        </td>
                      </tr>
                      <tr>
                        <th>실직기간</th>
                        <td>{selectedApplicantDetail.unemployPeriod}</td>
                      </tr>
                      <tr>
                        <th>병역관계</th>
                        <td>{selectedApplicantDetail.employIsMilitary}</td>
                      </tr>
                      <tr>
                        <th>최초 정보습득 경로</th>
                        <td>{selectedApplicantDetail.employInformationPath}</td>
                      </tr>
                      <tr>
                        <th>지원동기</th>
                        <td>{selectedApplicantDetail.employReason}</td>
                      </tr>
                      <tr>
                        <th>비고</th>
                        <td>{selectedApplicantDetail.employNote || '-'}</td>
                      </tr>
                      <tr>
                        <th>약관 동의</th>
                        <td>
                          <div className="agree-checkbox">
                            <label className="label-display-flex">
                              <input type="checkbox" checked={selectedApplicantDetail.employIsCondition} disabled/> 서비스 이용약관
                            </label>
                            <label className="label-display-flex">
                              <input type="checkbox" checked={selectedApplicantDetail.employIsInformation} disabled/> 개인정보 수집 및 이용목적에 대한 동 여부
                            </label>
                            <label className="label-display-flex">
                              <input type="checkbox" checked={selectedApplicantDetail.employIsProvide} disabled/> 제3자 정보제공 동의 여부
                            </label>
                            <label className="label-display-flex">
                              <input type="checkbox" checked={selectedApplicantDetail.employIsAdvertise} disabled/> 광고 제공 동의 여부
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="modal-button-container">
                  <button 
                    type="button" 
                    className="deleteBtn" 
                    onClick={() => handleDelete(selectedApplicantDetail.employId)}
                  >
                    삭제
                  </button>
                  <button 
                    type="button" 
                    className="pdf-downloadBtn" 
                    onClick={() => handleFileDownload(selectedApplicantDetail.employId)}
                  >
                    pdf 다운로드
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSave}>
              저장
            </button>
          </div>
          <div className="pagination-zone">
            <button onClick={() => handlePageChange(0)} disabled={currentPage === 0}>{"<<"}</button>
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>{"<"}</button>
            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageChange(page)}
                style={{ fontWeight: page === currentPage ? 'bold' : 'normal' }}
              >
                {page === currentPage ? <strong>{page + 1}</strong> : page + 1}
              </button>
            ))}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage - 1}>{">"}</button>
            <button onClick={() => handlePageChange(totalPage - 1)} disabled={currentPage === totalPage - 1}>{">>"}</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EmpVolunteer;
