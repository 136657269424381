import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "user/main/swiper/SwiperComponent.css";
import roadmap from "images/icon_roadmap.png";
import prev from "images/icon_prev.png";
import next from "images/icon_next.png";
import { useNavigate } from "react-router-dom";

function SwiperComponent({ trainingCourse, setModalOpen, setActIndex, eduId }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768); //창크기 상태값
  const [isTabletView, setIsTabletView] = useState(
    //창크기 상태값
    window.innerWidth >= 768 && window.innerWidth <= 1023
  );

  //창크기 상태값 변경 인식 함수
  const updateView = () => {
    setIsMobileView(window.innerWidth < 768);
    setIsTabletView(window.innerWidth >= 768 && window.innerWidth <= 1023);
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", updateView);
    return () => window.removeEventListener("resize", updateView);
  }, []);

  const navi = useNavigate();

  const handleAppDirect = () => {
    //교육더보기
    if (eduId.courseType === "취업트레이닝과정") {
      navi(`/training/detail/${eduId.sessionId}`);
    } else {
      navi(`/career/detail/${eduId.sessionId}`);
    }
  };

  return (
    <div className="swiper-container">
      <div ref={prevRef} className="custom-swiper-prev">
        <img src={prev} alt="이전" className="prev-icon" />
      </div>
      <div ref={nextRef} className="custom-swiper-next">
        <img src={next} alt="다음" className="next-icon" />
      </div>
      <div className="swiper-zone">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          loop={true}
          modules={[Pagination, Navigation]}
          onSlideChange={(swiper) => {
            if (swiper.activeIndex !== activeIndex) {
              setActiveIndex(swiper.activeIndex);
              setIsAccordionOpen(null);
              setActIndex(swiper.realIndex);
            }
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {trainingCourse.map((course, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content">
                <div className="edu-content-box">
                  <div className="container1">
                    <div className="edu-img-zone">
                      <img
                        src={`/uploads/${course.courseThumbnail}`}
                        alt={`Slide ${index + 1}`}
                        className="edu-image"
                      />
                    </div>
                    <div className="info-zone">
                      <div className="edu-info-header">
                        [{course.courseCategory}]{course.sessionName}
                      </div>
                      <ul className="tag-list">
                        {course.courseSummary.map((tag, tagIndex) => (
                          <li key={tagIndex}>
                            {"#"}
                            {tag}
                          </li>
                        ))}
                      </ul>
                      <div className="roadmap-btn-zone">
                        <button
                          className="roadmapBtn"
                          type="button"
                          onClick={() => {
                            setModalOpen(true);
                            setActIndex(index);
                          }}
                        >
                          로드맵
                          <img
                            src={roadmap}
                            alt="로드맵"
                            className="roadmap-icon"
                          />
                        </button>
                      </div>
                      <div className="zone-container">
                        <div className="edu-date">
                          <span className="edu-date-text-header">
                            모집 기간 : 
                          </span>
                          <div className="edu-date-text">
                            {course.sessionApplicationStartDate
                              ? course.sessionApplicationStartDate.slice(0, 10)
                              : ""}{" "}
                            ~{" "}
                            {course.sessionApplicationEndDate
                              ? course.sessionApplicationEndDate.slice(0, 10)
                              : ""}
                          </div>
                        </div>
                        <div className="edu-date">
                          <span className="edu-imp-text-header">교육 기간 : </span>
                          <div className="edu-imp-text">
                            {course.sessionCourseStartDate} ~{" "}
                            {course.sessionCourseEndDate}
                          </div>
                        </div>
                        <div className="apply-zone">
                          <div className="apply-cnt">
                            <div className="apply-header">모집 인원 : </div>
                            <div className="apply-text">
                              {course.sessionRecruitedNumber} 명
                            </div>
                          </div>
                        </div>
                        <div className="edu-explain-zone">
                          {course.courseContent}
                        </div>
                      </div>
                      <div className="applyBtn-container">
                        <button
                          className="applyBtn"
                          type="button"
                          onClick={handleAppDirect}
                        >
                          모집 바로가기
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="container2">
                  <div className="roadmap-zone">
                    <button onClick={() => toggleAccordion(index)}>
                      {isAccordionOpen === index
                        ? "▲ 로드맵 닫기"
                        : "▼ 로드맵보기"}
                    </button>
                    {isAccordionOpen === index && (
                      <div className="accordion-content">
                        <img
                          src={
                            isMobileView || isTabletView
                              ? course.courseRoadmapMobileImage
                              : course.courseRoadmapWebImage
                          }
                          alt="로드맵"
                          className="roadmap-image"
                        />
                      </div>
                    )}
                  </div>
                </div> */}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default SwiperComponent;
