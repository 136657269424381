import React, { useEffect, useState } from "react";
import "admin/AdminPage.css";
import "admin/story/Story.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { call } from "service/ApiService";

function ModifyVideoStory(props) {
  const { videoStoryId } = useParams();
  const [wThumbnail, setWThumbnail] = useState(""); // 썸네일
  const [mThumbnail, setMThumbnail] = useState(""); // 썸네일
  const [videoStory, setVideoStory] = useState({});
  const location = useLocation();
  const {categories, category, currentPage } = location.state || {};
  const navigate = useNavigate();

  useEffect(()=>{
    call("/api/v1/admin/video-story/"+videoStoryId, 'GET')
    .then(response=>{
      setWThumbnail("/uploads/"+response.videoStoryImage);
      setMThumbnail("/uploads/"+response.videoStoryMobileImage);
      setVideoStory(response);
    })
  }, []);

  useEffect(()=>{

  }, [videoStory]);

  // 썸네일 이미지 처리 함수
  const handleWThumbnailChange = (e) => {
    const file = e.target.files[0]; // 업로드 된 이미지
    if (file.size !== 0) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // 데이터url로 변환
      reader.onloadend = () => {
        setVideoStory({
          ...videoStory,
          videoStoryImage: file, // 변환된 이미지 URL을 eyetechImage에 저장
        });
        setWThumbnail(reader.result); // 웹이미지 썸네일 저장
      };
    }
  };

  const handleMThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file.size !== 0) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setVideoStory({
          ...videoStory,
          videoStoryMobileImage: file, // 변환된 이미지 URL을 eyetechImage에 저장
        });
        setMThumbnail(reader.result); // 모바일이미지 썸네일 저장
      };
    }
  };

  // 카테고리 변경 함수
  const handleCategoryChange = (e) => {
    setVideoStory({
      ...videoStory,
      videoStoryCategoryId: e.target.selectedOptions[0].getAttribute('data-key'),
      videoStoryCategoryName: e.target.value
    });
  };

  // 에디터 내용 가져오는 함수
  const handleSubmit = () => {
    const formData = new FormData();

    // videoStory 객체의 모든 키-값 쌍을 FormData에 추가
    Object.entries(videoStory).forEach(([key, value]) => {
      if (key === 'videoStoryImage' && !(value instanceof File)) {
        const emptyBlob = new Blob([], { type: 'image/jpeg' }); // JPEG 타입의 빈 Blob
        const thumbnailFile = new File([emptyBlob], value != null ? value : 'empty'); // 빈 파일
        formData.append(key, thumbnailFile);
      } else if (key === 'videoStoryMobileImage' && !(value instanceof File)) {
        const emptyBlob = new Blob([], { type: 'image/jpeg' }); // JPEG 타입의 빈 Blob
        const thumbnailFile = new File([emptyBlob], value != null ? value : 'empty'); // 빈 파일
        formData.append(key, thumbnailFile);
      } else if (key === 'videoStoryYoutubeId' && value === null) {
        
      }  else {
        formData.append(key, value);
      }
    });

    call('/api/v1/admin/video-story/'+videoStoryId, 'PUT', formData, 'multipart/form-data')
    .then(response => {
      alert(response.message);
      const param = category !== "" ? `category=${category}&page=${currentPage}` :  `page=${currentPage}`;
      navigate('/admin/story/videostorylist?' + param);
    })
    .catch(error=>{
      alert(error.message);
    })
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">Story{" > "}영상 스토리</div>
        <div className="main-content">
          <div className="title">영상 스토리 수정</div>
          <div className="subtitle">현재 영상 스토리 탭에 등록되어 있는 글을 수정합니다</div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">카테고리</div>
              <select
                className="drop-down-menu"
                value={videoStory.videoStoryCategoryName}
                onChange={handleCategoryChange}
              >
                <option key={0} value="" data-key={0}>선택하세요</option>
                {categories.map((category) => (
                  <option key={category.categoryDetailId} value={category.categoryDetailName} data-key={category.categoryDetailId}>
                    {category.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">메인에 고정</div>
              <div className="checkbox">
                <input type="checkbox" className="sticky-check" checked={videoStory.videoStoryIsMain}
                  onChange={(e) => setVideoStory({ ...videoStory, videoStoryIsMain: e.target.checked })}/>
              </div>
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">제목</div>
              <input className="long-title" 
                value={videoStory.videoStoryTitle}
                id="videoStoryTitle"
                onChange={(e) => {
                  if (e.target.value.length > 255) {
                    alert("제목은 최대 255자입니다.");
                    document.getElementById("videoStoryTitle").focus();  // id로 요소를 찾아 포커스
                    return;
                  }
                  setVideoStory({ ...videoStory, videoStoryTitle: e.target.value })
                }}/>
            </div>
          </div>
          <hr />
          <div className="input-image-zone">
            <div className="content-header">
              썸네일 이미지
              <div className="caption">
                없을 경우 유튜브 영상의<br/>썸네일로 대체됩니다
              </div>
            </div>
            <div className="image-container">
              <div className="img-tag">웹이미지 <div className="caption">높이: 230</div></div>
              <input
                type="file"
                className="add-web-image"
                onChange={handleWThumbnailChange}
              />
              {wThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={wThumbnail}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
              <div className="img-tag">모바일 이미지 <div className="caption">높이: 200</div></div>
              <input
                type="file"
                className="add-mobile-image"
                onChange={handleMThumbnailChange}
              />
              {mThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={mThumbnail}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">영상 ID</div>
              <input className="long-title" value={videoStory.videoStoryYoutubeId ? videoStory.videoStoryYoutubeId : ""}
                onChange={(e) => setVideoStory({ ...videoStory, videoStoryYoutubeId: e.target.value })}/>
            </div>
          </div>
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header"></div>
              {videoStory.videoStoryYoutubeId && (
                <div className="image-preview-box">
                  <p>유튜브 썸네일 이미지</p>
                  <img
                    src={"https://img.youtube.com/vi/"+ videoStory.videoStoryYoutubeId +"/mqdefault.jpg"}
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <hr/>
          <div className="text-zone">
            <div className="content-header">영상 설명</div>
            <textarea
              className="textbox"
              placeholder="문구를 작성해주세요."
              value={videoStory.videoStoryContent}
              onChange={(e) => setVideoStory({ ...videoStory, videoStoryContent: e.target.value })}
            ></textarea>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              수정
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyVideoStory;
