import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const scrollToTop = () => {
            // body 스타일 초기화
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
            
            // 강제로 스크롤을 최상단으로 이동
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'  // 즉시 이동
            });
        };

        // 페이지 로드 후 즉시 실행
        scrollToTop();

        // cleanup 함수
        return () => {
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.width = '';
        };
    }, [pathname]);

    return null;
}