import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/toastui-editor.css';
import { useEffect, useState } from 'react';
import { call } from 'service/ApiService';
import { extractImageNames } from './story/ExtractImageNames';

function TuiEditor({ content, wThumbnail, mThumbnail, editorRef, setDeleteImages }) {
    const [previousImages, setPreviousImages] = useState();
    const [updatedContent, setUpdatedContent] = useState();

    useEffect(() => {
        const editorInstance = editorRef.current.getInstance();
        setPreviousImages(editorInstance.getMarkdown());
        setUpdatedContent(editorInstance.getMarkdown());
    }, []);
    
    useEffect(() => {
        const editorInstance = editorRef.current.getInstance();
    
        // 이미지 삭제를 위한 이벤트 리스너
        editorInstance.on('change', () => {
            setUpdatedContent(editorInstance.getMarkdown());
        })
    }, [editorRef]);

    useEffect(() => {
        const currentImages = extractImageNames(updatedContent);
    
        if (previousImages) {
            const imagesToDelete = previousImages.filter(url => 
                !currentImages.includes(url) && 
                ![wThumbnail, mThumbnail].includes(url)
            );
            
            // 삭제할 이미지들을 setDeleteImages를 통해 상위 컴포넌트에 전달
            if (imagesToDelete.length > 0) {
                setDeleteImages(prev => [...new Set([...prev, ...imagesToDelete])]);
            }
        }
    
        // 이전 이미지 상태 업데이트
        setPreviousImages(currentImages);
    }, [updatedContent]);

    const imageHandler = async (image, callback) => {
        // image는 업로드된 이미지 파일, callback은 URL을 전달하는 함수
        const formData = new FormData();
        formData.append('file', image);
        let imageName = "";
        await call('/api/v1/file/upload/image', 'POST', formData, 'multipart/form-data')
        .then(response=>{
            imageName = response.message;
            callback("/uploads/"+imageName, ''); // 이미지 URL로 콜백 호출
        })
        .catch(error=>alert(error.status, error.message));
    };

    return (
        <Editor
        initialValue={content ?? ' '}
        ref={editorRef}
        previewStyle="vertical"
        height="400px"
        initialEditType="markdown"
        useCommandShortcut={true}
        hooks={{ addImageBlobHook: imageHandler }}
        />
    );
}

export default TuiEditor;