import React, { useState, useEffect } from "react";
import { call } from "service/ApiService";
import "admin/AdminPage.css";
import "admin/jobTraining/JobTraining.css";
import { useNavigate } from "react-router-dom";

function AddJobTraining(props) {
  const navigate = useNavigate();
  const [menu, setMenu] = useState([]); // 카테고리 배열
  const [wThumbnail, setWThumbnail] = useState(""); // 썸네일
  const [listThumbnail, setListThumbnail] = useState(""); // 목록 썸네일 
  // const [mThumbnail, setMThumbnail] = useState(""); // 썸네일
  const [category, setCategory] = useState(4); // 과정 카테고리
  const [visible, setVisible] = useState(""); // 보기 카테고리
  const [pVisible, setPVisible] = useState("공개"); // 포폴보기 카테고리
  const [cFile, setCFile] = useState(""); //커리큘럼 첨부파일
  const [webExplainImg, setWebExplainImg] = useState(""); //웹포트폴리오설명
  //const [mobileExplainImg, setMobileExplainImg] = useState(""); //모바일포트폴리오설명
  const [webRoadmapImg, setWebRoadmapImg] = useState(""); //로드맵
  const [mobileRoadmapImg, setMobileRoadmapImg] = useState(""); //로드맵
  //const [projectThumbnail, setProjectThumbnail] = useState(""); //프로젝트 썸네일
  const [courseTag, setCourseTag] = useState(""); // 태그 상태
  const [content, setContent] = useState(""); // 내용 상태

  // 카테고리 불러오기
  useEffect(() => {
    call("/api/v1/category/select?categoryName=직무", "GET")
      .then((response) => {
        setMenu(response || []);
      })
      .catch((error) => alert("카테고리 조회에 실패했습니다"));
  }, []);

  // 카테고리 선택 변경
  const handleVisibleChange = (e) => {
    const selectedCategoryId = e.target.value;
    setCategory(selectedCategoryId); // 카테고리 ID 저장
    setVisible(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // 폼이 기본적으로 제출되지 않도록 함

    const formData = new FormData();

    const courseTitle = document.querySelector(".long-title").value;
    if (!courseTitle) {
      alert("강좌명을 입력해주세요.");
      return;
    }

    // 일반 텍스트 데이터 추가
    formData.append("courseDivisionType", "직무훈련과정");
    formData.append("courseCategoryId", category);
    formData.append("courseName", courseTitle);
    formData.append(
      "courseIsProjectPublic",
      pVisible === "공개" ? true : false
    );
    formData.append("courseSummary", courseTag);
    formData.append("courseContent", content);

    // 커리큘럼 첨부 파일 추가
    if (cFile) formData.append("courseFile", cFile);

    // 썸네일 이미지 추가
    if (wThumbnail) formData.append("courseThumbnailImage", wThumbnail);
    if (listThumbnail) formData.append("courseListThumbnailImage", listThumbnail);

    // 로드맵 이미지 추가
    if (webRoadmapImg) formData.append("courseRoadMapWebImage", webRoadmapImg);
    if (mobileRoadmapImg)
      formData.append("courseRoadMapMobileImage", mobileRoadmapImg);

    // 컨텐츠 이미지 추가
    imageInputs.forEach((input, index) => {
      formData.append(`contents[${index}].courseContentName`, input.title);
      if (input.webImage)
        formData.append(
          `contents[${index}].courseContentImage1`,
          input.webImage
        );
      if (input.mobileImage)
        formData.append(
          `contents[${index}].courseContentImage2`,
          input.mobileImage
        );
    });

    // 포트폴리오 섹터 이미지 추가 (최상위 레벨)
    if (webExplainImg instanceof File) {
      formData.append("courseProjectSectorImage", webExplainImg);
    }

    // 포트폴리오 이미지 추가
    portfolioInputs.forEach((input, index) => {
      formData.append(`projects[${index}].courseProjectLink`, input.url);
      if (input.webImage)
        formData.append(
          `projects[${index}].courseProjectWebImage`,
          input.webImage
        );
      if (input.mobileImage)
        formData.append(
          `projects[${index}].courseProjectMobileImage`,
          input.mobileImage
        );
    });
    
    // form-data를 서버에 POST 요청으로 전송
    try {
      const response = await call(
        "/api/v1/admin/training-course",
        "POST",
        formData,
        "multipart/form-data"
      );
      if (response.message) {
        alert("훈련과정이 성공적으로 등록되었습니다.");
        navigate("/admin/jobtraining/traininglist");
      } else {
        alert("등록에 실패했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  // 포트폴리오 설명 이미지 처리 함수
  const handleWExplainImgChange = (e) => {
    const file = e.target.files[0]; // 업로드 된 이미지
    if (file) {
      setWebExplainImg(file); // 파일 객체 저장
    }
  };

  // 로드맵 이미지 처리 함수
  const handleWRoadmapImgChange = (e) => {
    const file = e.target.files[0]; // 업로드 된 이미지
    if (file) {
      setWebRoadmapImg(file); // 파일 객체 저장
    }
  };
  //이하동문
  const handleMRoadmapImgChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMobileRoadmapImg(file);
    }
  };

  //첨부파일 추가
  const handleAddCFile = (e) => {
    setCFile(e.target.files[0]);
  };

  // 컨텐츠 이미지 상태 배열로 관리
  const [imageInputs, setImageInputs] = useState([
    { title: "", webImage: "", mobileImage: "" },
  ]);
  // 포트폴리오 이미지 상태 배열로 관리
  const [portfolioInputs, setPortfolioInputs] = useState([
    { url: "", webImage: "", mobileImage: "" },
  ]);

  // 썸네일 이미지 처리 함수
  const handleWThumbnailChange = (e) => {
    const file = e.target.files[0]; // 업로드 된 이미지
    if (file) {
      setWThumbnail(file); // 파일 객체 저장 (미리보기는 필요 시 추가)
    }
  };

  // 컨텐츠 이미지 추가 함수
  const addImageInput = () => {
    setImageInputs([
      ...imageInputs,
      { title: "", webImage: "", mobileImage: "" },
    ]);
  };

  // 컨텐츠 이미지 삭제 함수
  const removeImageInput = (index) => {
    const newImageInputs = [...imageInputs];
    newImageInputs.splice(index, 1); // 해당 인덱스의 항목만 삭제
    setImageInputs(newImageInputs);
  };

  // 포트폴리오 이미지 추가 함수
  const addPortfolioImageInput = () => {
    setPortfolioInputs([
      ...portfolioInputs,
      { url: "", webImage: "", mobileImage: "" },
    ]);
  };

  // 포트폴리오 이미지 삭제 함수
  const removePortfolioImageInput = (index) => {
    const newPortfolioInputs = [...portfolioInputs];
    newPortfolioInputs.splice(index, 1); // 해당 인덱스의 항목만 삭제
    setPortfolioInputs(newPortfolioInputs);
  };

  // 카테고리 변경 함수
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handlePVisibieChange = (e) => {
    setPVisible(e.target.value);
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">
          취업 훈련 과정{" > "}취업 훈련 목록{" > "}취업 훈련 등록
        </div>
        <div className="main-content">
          <div className="title">훈련과정 등록</div>
          <div className="option-zone">
            <div className="option-content">
              <div className="option-subtitle">과정 카테고리</div>
              <select
                className="drop-down-menu"
                value={category}
                onChange={handleVisibleChange}
              >
                {menu.map((item) => (
                  <option
                    key={item.categoryDetailId}
                    value={item.categoryDetailId}
                  >
                    {item.categoryDetailName}
                  </option>
                ))}
              </select>
            </div>
            <div className="option-content">
              <div className="option-subtitle">포트폴리오 공개여부</div>
              <div className="radio-button">
                <label>
                  <input
                    type="radio"
                    value="공개"
                    checked={pVisible === "공개"}
                    onChange={handlePVisibieChange}
                  />
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    value="비공개"
                    checked={pVisible === "비공개"}
                    onChange={handlePVisibieChange}
                  />
                  비공개
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">강좌명</div>
              <input className="long-title" maxLength={255} />
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">
                태그
                <div className="caption">
                  메인페이지에만
                  <br />
                  표기됩니다
                  <br />[ , ]로 구분
                </div>
              </div>
              <input
                className="long-title"
                value={courseTag}
                onChange={(e) => setCourseTag(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="option-zone">
            <div className="option-content">
              <div className="content-header">
                과정 설명글
                <div className="caption">
                  메인페이지에만
                  <br />
                  표기됩니다
                </div>
              </div>
              <textarea
                className="long-title"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
          <hr />
          <div className="portfolio-image-zone">
            <div className="content-header">
              로드맵 이미지
              <div className="caption">
                메인페이지에만
                <br />
                표기됩니다
              </div>
            </div>
            <div className="image-container">
              <div className="img-tag">웹이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={(e) => setWebRoadmapImg(e.target.files[0])} // 파일 객체 저장
              />
              {webRoadmapImg && (
                <div className="image-preview-box">
                  <img
                    src={URL.createObjectURL(webRoadmapImg)} // URL.createObjectURL 사용
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
              <div className="img-tag">모바일 이미지</div>
              <input
                type="file"
                className="add-mobile-image"
                onChange={(e) => setMobileRoadmapImg(e.target.files[0])} // 파일 객체 저장
              />
              {mobileRoadmapImg && (
                <div className="image-preview-box">
                  <img
                    src={URL.createObjectURL(mobileRoadmapImg)} // URL.createObjectURL 사용
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="input-image-zone">
            <div className="content-header">
              썸네일 이미지<div className="caption">1:1비율 권장</div>
            </div>
            <div className="image-container">
              <div className="img-tag">썸네일 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={(e) => setWThumbnail(e.target.files[0])} // 파일 객체 저장
              />
              {wThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={URL.createObjectURL(wThumbnail)} // URL.createObjectURL 사용
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="input-image-zone">
            <div className="content-header">목록 썸네일 <br /> 이미지</div>
            <div className="image-container">
              <div className="img-tag">목록 썸네일 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={(e) => setListThumbnail(e.target.files[0])} // 파일 객체 저장
              />
              {listThumbnail && (
                <div className="image-preview-box">
                  <img
                    src={URL.createObjectURL(listThumbnail)} // URL.createObjectURL 사용
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="image-content">
            <div className="edu-image-zone">
              {imageInputs.map((input, index) => (
                <div key={index}>
                  <div className="input-image-zone">
                    <div className="content-header">
                      컨텐츠 제목 {index + 1}
                    </div>
                    <div className="input-zone-container">
                      <div className="image-container">
                        <input
                          className="content-title"
                          value={input.title}
                          onChange={(e) => {
                            const newInputs = [...imageInputs];
                            newInputs[index].title = e.target.value;
                            setImageInputs(newInputs);
                          }}
                        />
                        <div className="img-tag">
                          웹이미지<div className="caption">가로 850px</div>
                        </div>
                        <input
                          type="file"
                          className="add-web-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...imageInputs];
                              newInputs[index].webImage = file; // 파일 객체 저장
                              setImageInputs(newInputs);
                            }
                          }}
                        />
                        {input.webImage && (
                          <div className="image-preview-box">
                            <img
                              src={URL.createObjectURL(input.webImage)} // URL.createObjectURL 사용
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                        <div className="img-tag">
                          모바일 이미지<div className="caption">가로 300px</div>
                        </div>
                        <input
                          type="file"
                          className="add-mobile-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...imageInputs];
                              newInputs[index].mobileImage = file; // 파일 객체 저장
                              setImageInputs(newInputs);
                            }
                          }}
                        />
                        {input.mobileImage && (
                          <div className="image-preview-box">
                            <img
                              src={URL.createObjectURL(input.mobileImage)} // URL.createObjectURL 사용
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                      </div>
                      <button
                        className="delete-image-btn"
                        onClick={() => removeImageInput(index)}
                      >
                        - 삭제하기
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <button className="add-image-btn" onClick={addImageInput}>
                + 추가하기
              </button>
            </div>
          </div>
          <div className="portfolio-image-zone">
            <div className="content-header">
              커리큘럼
              <br />
              첨부파일
              <div className="caption">
                제목이 '커리큘럼'인
                <br />
                경우 다운로드
                <br />
                버튼이 표기됩니다.
              </div>
            </div>
            <div className="image-container">
              <div className="img-tag">커리큘럼 첨부파일</div>
              <input
                type="file"
                className="add-web-image"
                onChange={handleAddCFile}
              />
            </div>
          </div>

          <div className="portfolio-image-zone">
            <div className="content-header">
              포트폴리오
              <br /> 설명이미지
            </div>
            <div className="image-container">
              <div className="img-tag">썸네일 이미지</div>
              <input
                type="file"
                className="add-web-image"
                onChange={handleWExplainImgChange}
              />
              {webExplainImg && (
                <div className="image-preview-box">
                  <img
                    src={URL.createObjectURL(webExplainImg)} // URL.createObjectURL 사용
                    alt="미리보기"
                    className="image-preview"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="image-content">
            <div className="edu-image-zone">
              {portfolioInputs.map((input, index) => (
                <div key={index}>
                  <div className="input-image-zone">
                    <div className="content-header">
                      포트폴리오
                      <br />
                      링크{index + 1}
                    </div>
                    <div className="input-zone-container">
                      <div className="image-container">
                        <input
                          className="content-title"
                          value={input.url}
                          onChange={(e) => {
                            const newInputs = [...portfolioInputs];
                            newInputs[index].url = e.target.value;
                            setPortfolioInputs(newInputs);
                          }}
                        />
                        <div className="img-tag">웹이미지</div>
                        <input
                          type="file"
                          className="add-web-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...portfolioInputs];
                              newInputs[index].webImage = file; // 파일 객체 저장
                              setPortfolioInputs(newInputs);
                            }
                          }}
                        />
                        {input.webImage && (
                          <div className="image-preview-box">
                            <img
                              src={URL.createObjectURL(input.webImage)} // URL.createObjectURL 사용
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                        <div className="img-tag">모바일 이미지</div>
                        <input
                          type="file"
                          className="add-mobile-image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const newInputs = [...portfolioInputs];
                              newInputs[index].mobileImage = file; // 파일 객체 저장
                              setPortfolioInputs(newInputs);
                            }
                          }}
                        />
                        {input.mobileImage && (
                          <div className="image-preview-box">
                            <img
                              src={URL.createObjectURL(input.mobileImage)} // URL.createObjectURL 사용
                              alt="미리보기"
                              className="image-preview"
                            />
                          </div>
                        )}
                      </div>
                      <button
                        className="delete-image-btn"
                        onClick={() => removePortfolioImageInput(index)}
                      >
                        - 삭제하기
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <button
                className="add-image-btn"
                onClick={addPortfolioImageInput}
              >
                + 추가하기
              </button>
            </div>
          </div>
          <div className="button-container">
            <button
              type="submit"
              className="submitBtn"
              onClick={handleFormSubmit}
            >
              등록
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddJobTraining;
