import React from "react";
import "user/main/EduComponent.css";
import SwiperComponent from "user/main/swiper/SwiperComponent";

function EduComponent({ recommendedCourseText, trainingCourse, setModalOpen, setActIndex, eduId }) {


  return (
    <div className="recommend-edu-zone">
      {/* <div className="recommend-text-zone">
        <div className="edu-title">추천 교육과정</div>
        <div className="edu-recommend-text">{recommendedCourseText}</div>
      </div> */}
      <SwiperComponent trainingCourse={trainingCourse} setModalOpen={setModalOpen} setActIndex={setActIndex} eduId={eduId}/>
    </div>
  );
}

export default EduComponent;
