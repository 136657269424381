import defaultImg from "images/defaultImg.png";
import { useEffect, useState } from 'react';
import { call } from 'service/ApiService';
import "user/commons/Banner.css";

function Banner({category}) {
    const [bannerWebImage, setBannerWebImage] = useState("");
    const [bannerMobileImage, setbannerMobileImage] = useState("");

    useEffect(()=>{
        call(`/api/v1/banner/${category}`,"GET").then((response)=>{
            setBannerWebImage(response.bannerWebImage);
            setbannerMobileImage(response.bannerMobileImage);
        }).catch();
    },[]);

    const handleImgError =(e)=>{
        e.target.src = defaultImg;
    }

    return (
        <div>
            <img className="bannerWebImage" src={`/uploads/${bannerWebImage}`} alt="상단배너웹용" onError={handleImgError}/>
            <img className="bannerMobileImage" src={`/uploads/${bannerMobileImage}`} alt="상단배너모바일용" onError={handleImgError}/>
        </div>
    );
}

export default Banner;