import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { call } from "service/ApiService";
import AgreementBox from "user/register/component/AgreementBox";
import InputBox from "user/register/component/InputBox";
import RegisterBtn from "user/register/component/RegisterBtn";
import "user/register/jobCareer/InputResume.css";
import { useMember } from "../RegisterPage";
import Loading from "user/commons/Loading";

function InputResume() {
    const {userInfo, setUserInfo, handleChange, setIsError} = useMember();
    const navigate = useNavigate();
    const {courseType, sessionId} = useParams();

    // 상태 관리
    const [emailList, setEmailList] = useState([]); // 이메일 도메인 목록
    const [isNextEnabled, setIsNextEnabled] = useState(false); // 다음 버튼 활성화 상태
    const [checkList, setCheckList] = useState([]); // 동의 항목 체크 리스트
    const [requiredErrorMsg, setRequiredErrorMsg] = useState({}); // 에러 메시지
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태

    // 필수 입력 필드 설정
    const requiredFields = ["jobName", "jobPhoneNumber", "jobEamil"];
    const inputRefs = useRef({}); // 입력 필드 참조
    
    // 필드 라벨 매핑
    const fieldLabels = {
        jobName: "이름은",
        jobPhoneNumber: "연락처는",
        jobEamil: "이메일은",
    };

    // 수강신청 폼 제출 처리
    const handleSubmitCareer = async () => {
        if(isNextEnabled){
            try {
                setIsLoading(true);
                await call("/api/v1/job-training", 'POST', userInfo);
                navigate(`/${courseType}/registration/success/${sessionId}`);
            } catch (error) {
                alert("수강신청에 실패했습니다. 다시 시도해주세요.");
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        } else {
            // 필수 입력 필드 검증
            const firstEmptyField = requiredFields.find(field => !userInfo[field]);
            setRequiredErrorMsg({});
            if (firstEmptyField) {
                inputRefs.current[firstEmptyField]?.focus();
                setRequiredErrorMsg({[firstEmptyField]:`${fieldLabels[firstEmptyField]} 필수 입력 항목입니다.`});
            }
        }
    };

    // 이메일 유효성 검사
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // 필수 입력 필드 및 동의 항목 검사
    const checkRequiredFields = () => {
        // 모든 필수 필드가 채워져 있는지 확인
        const allFieldsFilled = requiredFields.every(field => {
            if (field === 'jobEamil') {
                return userInfo[field] && isValidEmail(userInfo[field]);
            }
            return userInfo[field]?.trim();
        });

        // 모든 동의 항목이 체크되어 있는지 확인
        const allAgreementsChecked = [
            "jobIsConsentCondition", 
            "jobIsConsentInformation", 
            "jobIsConsentProvide"
        ].every(key => checkList.includes(key));

        setIsNextEnabled(allFieldsFilled && allAgreementsChecked);
    };

    // 이메일 도메인 목록 가져오기
    useEffect(() => {
        const fetchEmailList = async () => {
            try {
                const response = await call('/api/v1/category/select?categoryName=이메일', 'GET');
                setEmailList(response);
            } catch (error) {
                setIsError(true);
            }
        };
        fetchEmailList();
    }, [setIsError]);

    // 필수 입력 필드 상태 변경 감지
    useEffect(() => {
        checkRequiredFields();
    }, [userInfo, checkList]);

    // useEffect 추가
    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isLoading]);

    useEffect(() => {
        setUserInfo(prev => ({ ...prev, sessionId:{sessionId} }));      

    }, []);

    return (
        <div className='info-container'>
            {/* 로딩 오버레이 */}
            {isLoading && 
                <div className='loading-overlay'>
                    <Loading/>
                </div>
            }
            <div className="info-section">
                {/* 제목 섹션 */}
                <div className='info-title'>
                    <p className='info-title-text'>기본 정보</p>
                    <p><span className='star'>*</span> 표기된 항목은 필수입력 항목입니다.</p> 
                </div>

                {/* 입력 필드 섹션 */}
                <InputBox 
                    inputLabel="이름" 
                    inputId="korean_name" 
                    inputName="jobName" 
                    isRequired={true} 
                    half 
                    handleChange={handleChange}
                    ref={el => inputRefs.current.jobName = el}
                    errorMsg={requiredErrorMsg.jobName}
                />
                <InputBox 
                    inputLabel="연락처" 
                    inputId="phone_number" 
                    inputName="jobPhoneNumber"
                    type="tel" 
                    isRequired={true} 
                    half 
                    handleChange={handleChange}
                    ref={el => inputRefs.current.jobPhoneNumber = el}
                    errorMsg={requiredErrorMsg.jobPhoneNumber}
                />
                <InputBox 
                    inputLabel="이메일" 
                    inputId="email" 
                    inputName="jobEamil" 
                    type="email" 
                    isRequired 
                    list={emailList}
                    ref={el => inputRefs.current.jobEamil = el}
                    errorMsg={requiredErrorMsg.jobEamil}
                />
                <InputBox 
                    inputLabel="회사명" 
                    inputName="jobCompany" 
                    handleChange={handleChange}
                />
                <InputBox 
                    inputLabel="요구사항" 
                    inputName="jobRequire" 
                    type="textarea" 
                    handleChange={handleChange}
                />
            </div>

            {/* 동의 항목 섹션 */}
            <AgreementBox 
                checkList={checkList} 
                setCheckList={setCheckList} 
                category={courseType}
                sessionId={sessionId}
            />

            {/* 버튼 섹션 */}
            <RegisterBtn 
                prev={`/${courseType}/detail/${sessionId}`}  
                handleSubmit={handleSubmitCareer} 
                isNextEnabled={isNextEnabled}
                next="신청"
            /> 
        </div>   
    );
}

export default InputResume;