import { useNavigate } from 'react-router-dom';
import 'user/register/component/RegisterBtn.css';

function RegisterBtn({ prev, handleSubmit, isNextEnabled, next }) {
    const navigate = useNavigate();

    const handleGoPrevPage = () => {
        if (prev) {
            navigate(prev);
        }
    };

    return (
        <div className='register-button'>
            <button 
                type="button"
                className='cancel-btn'
                onClick={handleGoPrevPage}
                aria-label="이전 페이지로 이동"
            >
            취소
            </button>
            <button 
                type="button"
                className={`next-btn ${!isNextEnabled ? 'disabled' : ''}`}
                onClick={handleSubmit}
                aria-label={`${next} 버튼`}
            >
                {next}
            </button>
        </div>
    );
}

export default RegisterBtn;