import React, { useState, useRef, useEffect } from 'react';
import "user/withfirstzone/commons/FirstzoneContents.css";
import defaultImg from "images/defaultImg.png";
import Loading from "user/commons/Loading";
import { call } from 'service/ApiService';
import { Viewer } from '@toast-ui/react-editor';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function FirstzoneContents({ selectedMenu, categoryDetails, selectedCategory, setIsError }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const mapRef = useRef(null); // map 컨테이너 참조

    const [firstzone, setFirstzone] = useState([]);
    const [firstmap, setFirstmap] = useState([]);
    const [isFooterLoaded, setIsFooterLoaded] = useState(false);
    const [contentlist, setContentlist] = useState([]); // 카테고리 담은 변수

    const [isCategoryLoaded, setIsCategoryLoaded] = useState(false); // 카테고리가 로드 되었는가?
    const [isFirstzoneLoading, setIsFirstzoneLoading] = useState(true);
    const [isFirstmapLoading, setIsFirstmapLoading] = useState(true);
    const [isContentLoading, setIsContentLoading] = useState(false);

    // 문의사항 등록은 여러 데이터를(총 4개) 보내야 하기에 초기 값은 배열로 하기
    const [inquiryData, setInquiryData] = useState({
        questionName: '',
        questionPhoneNumber: '',
        questionEmail: '',
        questionContent: ''
    });

    const handleInquiryDataChange = (e) => {
        // 여러 데이터를 보내야 하기에 ...inquiryData로 이전꺼도 넣어둬야함
        // 만약 ...inquiryData를 안쓰면 가장 마지막에 입력한 데이터만 들어가짐
        setInquiryData({...inquiryData,[e.target.name]:e.target.value});
    };

    // 문의사항에 연락처 input은 숫자만 입력하는 것과 자동 하이픈 입력이 필요하기에 만듦
    const phoneNumberInquiryDataChange = (e) => {
        const input = e.target.value.replace(/[^0-9]/g, ''); // 숫자만 남기기
        let formattedNumber = input;
        // 입력된 숫자에 따라 하이픈 추가
        if (input.length > 3 && input.length <= 7) {
            formattedNumber = `${input.slice(0, 3)}-${input.slice(3)}`;
        } else if (input.length > 7) {
            formattedNumber = `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7)}`;
        }
        
        setInquiryData({
            ...inquiryData,
            questionPhoneNumber: formattedNumber, // 포맷된 번호 저장
        });
    }

    const onErrorImg = (e) => {
        e.target.src = defaultImg;
    }

    // 카테고리가 로드되었는지 확인
    useEffect(() => {
        if (categoryDetails.length > 0) {
            setIsCategoryLoaded(true);
        }
    }, [categoryDetails]);
    

    // 윈도우 크기 변경 시 모바일인지 데스크톱인지 상태 업데이트
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // Firstzone 콘텐츠 불러오기
    useEffect(() => {
        setIsFirstzoneLoading(true);
        call('/api/v1/metadata/first-zone', 'GET')
            .then((response) => {
                // console.log("Firstzone 데이터:", response);
                setFirstzone(response);
                setIsFirstzoneLoading(false);
            })
            .catch((error) => {
                // console.error('first-zone 조회에 실패했습니다:', error);
                setIsFirstzoneLoading(false);
                setIsError(true);
            });
    }, []);

    // Firstmap 데이터 불러오기
    useEffect(() => {
        setIsFirstmapLoading(true);
        call('/api/v1/metadata/first-map', 'GET')
            .then((response) => {
                // console.log("Firstmap 데이터:", response);
                setFirstmap(response);
                setIsFirstmapLoading(false);
    
                // 첫 번째 API 호출이 완료된 후에 두 번째 API 호출을 시작합니다.
                return call('/api/v1/metadata/footer', 'GET');
            })
            .then((footerResponse) => {
                // console.log("지도에 필요한 Footer 데이터:", footerResponse);
                // 기존 상태를 유지하고, 새로운 데이터를 추가함
                setFirstmap(prevFirstmap => ({
                    ...prevFirstmap,
                    ...footerResponse
                }));
                setIsFooterLoaded(true);
            })
            .catch((error) => {
                // console.error('first-map 조회에 실패했습니다:', error);
                setIsFirstmapLoading(false);
                setIsFooterLoaded(true); // 오류 처리 시에도 Footer 로딩 상태를 true로 설정
                setIsError(true);
            });
    }, []);
    

    // 교육장소 이미지들 불러와 변수에 담는 것이므로 이미지 불러오는 함수보다 먼저있으면 에러남 또한, footer call함수보다 아래에 있어야함
    const images = firstmap.educationEnvironmentImages || [];
    


    // 메뉴 및 카테고리로 분류하여 글 목록 가져오는 함수
    useEffect(() => {
        if (!isCategoryLoaded) return; // 카테고리 로드가 완료되지 않으면 실행하지 않음
    
        // 퍼스트존과 퍼스트맵에서는 call함수가 필요없음
        if (selectedMenu === 'firstzone' || selectedMenu === 'firstmap') {
            return;
        }
    
        // 공지사항과 자주묻는질문 글 불러오기 함수(fetchContent)
        fetchContent(selectedMenu); // fetchContent 호출
    }, [selectedCategory, selectedMenu, isCategoryLoaded]);


    useEffect(() => {
        // console.log("업데이트된 contentlist:", contentlist);
    }, [contentlist]);


    useEffect(() => {
        if (selectedMenu !== 'firstmap' || !mapRef.current || !firstmap.address) return;
    
        const script = document.createElement('script');
        script.src = "https://dapi.kakao.com/v2/maps/sdk.js?appkey=9b6cdfc3b6065294d3230279d68a5b1f&libraries=services";
        script.async = true;
    
        script.onload = () => {
            if (window.kakao && window.kakao.maps) {
                const container = mapRef.current;
                const options = {
                    center: new window.kakao.maps.LatLng(37.566826, 126.9786567),
                    level: 3,
                };
                const map = new window.kakao.maps.Map(container, options);
                const geocoder = new window.kakao.maps.services.Geocoder();
    
                geocoder.addressSearch(firstmap.address, function (result, status) {
                    if (status === window.kakao.maps.services.Status.OK) {
                        const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                        map.setCenter(coords);
                        new window.kakao.maps.Marker({
                            map: map,
                            position: coords,
                        });
                    } else {
                        // console.error('Address search failed:', status);
                    }
                });
            } else {
                // console.error('Kakao Maps library is not available');
            }
        };
    
        script.onerror = () => {
            // console.error('Failed to load the Kakao map script');
        };
    
        document.head.appendChild(script);
    
        return () => {
            document.head.removeChild(script);
        };
    }, [selectedMenu, firstmap.address, mapRef]);
    

    // 공지사항 여닫이
    const [openNotices, setOpenNotices] = useState({});
    const [openFaqs, setOpenFaqs] = useState({}); // FAQ에 대한 별도 상태 추가

    const toggleNotice = (id) => {
        setOpenNotices(prevState => ({
            ...prevState,
            [id]: !prevState[id] // 공지사항의 id에 따라 열림/닫힘 상태를 토글
        }));
    };

    const toggleFaq = (id) => {
        setOpenFaqs(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    // 페이지 변경 함수에서 fetchContent 호출
    const handleNoticePageChange = (page) => {
        fetchContent('notice', page - 1); // 페이지 번호를 조정하여 호출
    };

    const handleFaqPageChange = (page) => {
        fetchContent('faq', page - 1); // 페이지 번호를 조정하여 호출
    };

    const fetchContent = (menu, page = 0) => {
        // 카테고리가 로딩완료되지 않으면 실행안함
        if (!isCategoryLoaded) return;
    
        setIsContentLoading(true);
        const selectedCategoryDetail = categoryDetails.find(detail => detail.categoryDetailName === selectedCategory);
        const categoryDetailId = selectedCategoryDetail ? selectedCategoryDetail.categoryDetailId : null;
    
        let apiUrl = menu === 'notice' ? '/api/v1/notice' : '/api/v1/faq';
        const categoryParam = categoryDetailId ? `${categoryDetailId}` : '';
    
        // 페이지가 0부터 시작하므로 page를 직접 사용함, size가 한 페이지에 보여줄 목록 개수를 의미함
        call(`${apiUrl}?category=${categoryParam}&keyword=&page=${page}&size=10`, 'GET')
            .then((response) => {
                // console.log(`${menu} 위드퍼스트존에서의 공지사항 및 faq 데이터:`, response);
                setContentlist(response);
                setIsContentLoading(false);
            })
            .catch((error) => {
                // console.error(`${menu} 데이터 조회 실패:`, error);
                setIsContentLoading(false);
                setIsError(true);
            });
    };

    // 문의사항 등록하기
    const handleInquirysubmit = () => {
        const { questionName, questionPhoneNumber, questionEmail, questionContent } = inquiryData || {};
    
        // 모든 입력란이 채워져 있는지 확인
        if (questionName && questionPhoneNumber && questionEmail && questionContent) {
            call('/api/v1/question', 'POST', inquiryData)
                .then(() => {
                    setInquiryData({
                        questionName: '',
                        questionPhoneNumber: '',
                        questionEmail: '',
                        questionContent: ''
                    });
                    alert("문의사항을 등록하였습니다.");
                })
                .catch(() => {
                    alert("문의사항 등록에 실패하였습니다. 다시 시도해주세요.");
                    // 문의사항 등록폼에는 에러페이지 띄우는게 필요 없을 듯 함
                    // setIsError(true);
                });
        } else {
            alert("모든 항목을 입력해주세요.");
        }
    };
    

    // 페이지네이션 버튼 렌더링 (화살표도 포함)
    // 페이지를 총 10페이지씩 보여주는 코드
    const renderPagination = (totalPages, currentPage, handlePageChange) => {
        if (contentlist.content && contentlist.content.length === 0) {
            return null;
        }

        const pagesPerBlock = 10; // 페이지 블록 당 표시할 페이지 수
        const currentBlock = Math.floor((currentPage - 1) / pagesPerBlock); // 현재 페이지 블록

        const startPage = currentBlock * pagesPerBlock + 1;
        const endPage = Math.min(startPage + pagesPerBlock - 1, totalPages);

        return (
                <div className="pagination">
                    {/* 왼쪽 더블 화살표 버튼 (페이지 블록 이동) */}
                    <button
                        className="page-button page-button-block"
                        onClick={() => handlePageChange(startPage - pagesPerBlock)}
                        disabled={startPage === 1}
                    >
                        &#171; {/* 왼쪽 더블 화살표 */}
                    </button>

                    {/* 왼쪽 화살표 버튼 (한 페이지 이동) */}
                    <button
                        className="page-button"
                        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
                        disabled={currentPage === 1}
                    >
                        &#9664; {/* 좌측 화살표 */}
                    </button>

                    {/* 현재 블록 내 페이지 번호 */}
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                        <button
                            key={startPage + index}
                            className={`page-number ${currentPage === startPage + index ? 'active' : ''}`}
                            onClick={() => handlePageChange(startPage + index)}
                        >
                            {startPage + index}
                        </button>
                    ))}

                    {/* 오른쪽 화살표 버튼 (한 페이지 이동) */}
                    <button
                        className="page-button"
                        onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}
                        disabled={currentPage === totalPages || totalPages === 0}
                    >
                        &#9654; {/* 우측 화살표 */}
                    </button>

                    {/* 오른쪽 더블 화살표 버튼 (페이지 블록 이동) */}
                    <button
                        className="page-button page-button-block"
                        onClick={() => handlePageChange(endPage + 1)}
                        disabled={endPage >= totalPages}
                    >
                        &#187; {/* 오른쪽 더블 화살표 */}
                    </button>
                </div>
            );
        };

    return (
        <div className='firstzone-contents-container'>
            {/* 퍼스트존 */}
            {selectedMenu === 'firstzone' && firstzone && (
                <div className='firstzone-contents-list-container'>
                    {isFirstzoneLoading ? (
                        <Loading />
                    ) : (
                        <div className='firstzone-contents-list-container'>
                    <img 
                    className='firstzone-contents-img1' 
                    src={
                        isMobile 
                        ? `/uploads/${firstzone.introImage?.mobileImage || defaultImg}`
                        : `/uploads/${firstzone.introImage?.webImage || defaultImg}`
                    } 
                    alt='퍼스트존 이미지1'
                    onError={onErrorImg}
                    />

                    <img 
                    className='firstzone-contents-img2' 
                    src={
                        isMobile 
                        ? `/uploads/${firstzone.thumbnailImage?.mobileImage || defaultImg}`
                        : `/uploads/${firstzone.thumbnailImage?.webImage || defaultImg}`
                    } 
                    alt='퍼스트존 이미지2'
                    onError={onErrorImg}
                    />

                    <img 
                    className='firstzone-contents-img3' 
                    src={
                        isMobile 
                        ? `/uploads/${firstzone.infoImage?.mobileImage || defaultImg}`
                        : `/uploads/${firstzone.infoImage?.webImage || defaultImg}`
                    } 
                    alt='퍼스트존 이미지3'
                    onError={onErrorImg}
                    />

                    {/* <p>OUR CLIENT</p> */}

                    <img 
                    className='firstzone-contents-img4 firstzone-contents-ourclient' 
                    src={
                        isMobile 
                        ? `/uploads/${firstzone.clientImage?.mobileImage || defaultImg}`
                        : `/uploads/${firstzone.clientImage?.webImage || defaultImg}`
                    } 
                    alt='퍼스트존 이미지4'
                    onError={onErrorImg}
                    />
                    </div>
                )}
                </div>
            )}


            {/* 퍼스트맵 */}
            {selectedMenu === 'firstmap' && (
            <div className='firstmap-total-contents-list-container'>
                {isFirstmapLoading ? (
                    <Loading/>
                ) : firstmap.educationEnvironmentImages && firstmap.educationEnvironmentImages.length > 0 ? (
                    <>
                        <p className='firstmap-img-intro-title'>교육 장소</p>
                        <div className="firstmap-contents-list-container">
                            {/* <FirstzoneSlider 
                                firstmap={firstmap} 
                                isMobile={isMobile} 
                                onErrorImg={onErrorImg} 
                                defaultImg={defaultImg} 
                            /> */}
                          
                            <Swiper
                                modules={[Pagination, Navigation]}// Navigation 모듈 추가
                                navigation // 내비게이션 활성화
                                spaceBetween={2} // 슬라이드 간의 간격
                                slidesPerView={1} // 한 번에 보여줄 슬라이드 수
                                loop={true}
                                pagination={{ clickable: true }}
                                className="slider"
                            >
                               
                                {firstmap.educationEnvironmentImages.map((item,index)=>(
                                    <SwiperSlide key={index}>
                                         <img className="firstmap-contents-img"
                                            src={
                                                isMobile
                                                    ? `/uploads/${item.mobileImage || defaultImg}`
                                                    : `/uploads/${item.webImage || defaultImg}`
                                            }
                                            alt={`이미지 ${index + 1}`}
                                            onError={onErrorImg}
                                        />
                                    </SwiperSlide>
                                ))}
                                
                            </Swiper>
                        </div>

                        {/* building-location-title */}
                        <p className='firstmap-find-intro-title'>찾아오시는 길</p>
                        <div className='building-location-container'>
                            <div
                                id='map'
                                ref={mapRef}  // ref를 사용해 map 컨테이너 참조
                                className='building-location-map'
                            ></div>
                            <img
                            className="building-location-introduce"
                            src={
                                isMobile
                                ? `/uploads/${firstmap.transportationImage?.mobileImage || defaultImg}`
                                : `/uploads/${firstmap.transportationImage?.webImage || defaultImg}`
                            }
                            alt="교통 이미지"
                            onError={onErrorImg}
                            />
                        </div>
                        
                    </>
                ) : (
                    <p>교육 장소 정보가 없습니다.</p>
                )}
            </div>
        )}

            

            {/* 공지사항 */}
            {selectedMenu === 'notice' && (
            <div className='notice-container'>
                <div className='list-margin'></div>
                <hr className='first-category-bottom-hr'></hr>

                {isContentLoading ? (
                        <Loading />
                    ) : 
                contentlist.content && contentlist.content.length > 0 ? (
                    contentlist.content
                        .filter((item) => 
                            // 선택된 카테고리가 있을 때 필터링, 선택된 카테고리가 없다면 모든 항목을 표시
                            categoryDetails?.categoryDetailId ? item.noticeCategoryId === categoryDetails.categoryDetailId : true
                        )
                        .map((item) => (
                            <div key={item.noticeId} className='notice-list'>
                                <div
                                    className='notice-list-header'
                                    onClick={() => toggleNotice(item.noticeId)}
                                    // style={{
                                    //     borderBottom: openNotices[item.noticeId] ? '1px solid var(--color-lightLineGray)' : 'none',
                                    //     borderBottomLeftRadius: openNotices[item.noticeId] ? '0px' : '8px',
                                    //     borderBottomRightRadius: openNotices[item.noticeId] ? '0px' : '8px',
                                    // }}
                                >
                                    <span
                                        className={`notice-list-title ${openNotices[item.noticeId] ? 'open-title' : ''}`}
                                    >
                                        {item.noticeTitle}
                                    </span>
                                    <div className='notice-list-toggle-button'>
                                        <span className={openNotices[item.noticeId] ? "notice-list-toggle-open" : "notice-list-toggle-close"} >+</span>
                                    </div>
                                </div>

                                {openNotices[item.noticeId] && (
                                    <div className='notice-list-content'>
                                        <div>
                                            <Viewer initialValue= {item.noticeContent}/>
                                        </div>
                                        <span>
                                            {new Date(item.noticeCreateDate).toISOString().slice(0, 10)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))
                ) : (
                    <p className='no-contents-message'>공지사항이 없습니다.</p>
                )}
                
                {/* 공지사항 페이지네이션 */}
                {renderPagination(contentlist.totalPages, contentlist.pageNumber + 1, handleNoticePageChange)}
            </div>
        )}



            {/* 자주 묻는 질문 (FAQ) */}
            {selectedMenu === 'faq' && (
            <div className='faq-container'>
                <div className='list-margin'></div>
                <hr className='first-category-bottom-hr'></hr>

                <div className='faq-content-total-container'>
                    <div className='faq-list-total-container'>
                    {isContentLoading ? (
                        <Loading />
                    ) : 
                    contentlist && contentlist.content && contentlist.content.length > 0 ? (
                        contentlist.content
                            .filter((item) => 
                                // 선택된 카테고리와 일치하는 항목 필터링, 선택된 카테고리가 없으면 전체 표시
                                categoryDetails?.categoryDetailId ? item.faqCategoryId === categoryDetails.categoryDetailId : true
                            )
                            .map((item) => (
                                    <div
                                    key={item.faqId}
                                    className='faq-list'
                                    >
                                        <div
                                        className='faq-list-header'
                                        onClick={() => toggleFaq(item.faqId)}
                                        // style={{
                                        //     borderBottom: openFaqs[item.faqId] ? '1px solid var(--color-lightLineGray)' : 'none',
                                        //     borderBottomLeftRadius: openFaqs[item.faqId] ? '0px' : '8px',
                                        //     borderBottomRightRadius: openFaqs[item.faqId] ? '0px' : '8px',
                                        // }}
                                        >
                                            <span
                                                className={`faq-list-title ${openFaqs[item.faqId] ? 'open-title' : ''}`}
                                            >
                                                {item.faqTitle}
                                            </span>
                                            <div className='faq-list-toggle-button'>
                                                <span className={openFaqs[item.faqId] ? "faq-list-toggle-open" : "faq-list-toggle-close"} >+</span>
                                            </div>
                                        </div>

                                        {openFaqs[item.faqId] && (
                                            <div className="faq-content">
                                                <Viewer initialValue= {item.faqContent}/>
                                            </div>
                                        )}
                                    </div>
                            ))
                    ) : (
                        <p className='no-contents-message'>FAQ가 없습니다.</p>
                    )}
                    {/* FAQ 페이지네이션 */}
                    {renderPagination(contentlist.totalPages, contentlist.pageNumber + 1, handleFaqPageChange)}
                    </div>
                    {/* 문의사항 div */}
                    <div className='question-total-container'>
                        <p className='question-title-introduce'>문의사항</p>
                        <hr />

                        <div className='question-user-container'>
                            <p className='question-user-name'>이름</p>
                            <input
                                value={inquiryData.questionName}
                                onChange={handleInquiryDataChange}
                                name='questionName'
                            />
                        </div>

                        <div className='question-user-container'>
                            <p className='question-user-number'>연락처</p>
                            <input
                                value={inquiryData.questionPhoneNumber}
                                onChange={phoneNumberInquiryDataChange}
                                maxLength={13}
                                name='questionPhoneNumber'
                            />
                        </div>

                        <div className='question-user-container'>
                            <p className='question-user-email'>이메일</p>
                            <input
                                value={inquiryData.questionEmail}
                                onChange={handleInquiryDataChange}
                                name='questionEmail'
                            />
                        </div>

                        <div className='question-content-container'>
                            <textarea
                                value={inquiryData.questionContent}
                                className='question-content'
                                onChange={handleInquiryDataChange}
                                name='questionContent'
                            />
                        </div>

                        <p className='question-btn' onClick={handleInquirysubmit}>문의하기</p>
                    </div>

                </div>

            </div>
        )}

        </div>
    );
}

export default FirstzoneContents;
