import React, { useEffect, useRef, useState } from "react";
import "admin/AdminPage.css";
import "admin/story/Story.css";
import "admin/withFirstzone/WithFirstzone.css";
import "@toast-ui/editor/dist/toastui-editor.css"; // Toast UI 기본 스타일 불러오기
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { call } from "service/ApiService";

function QnaAnswer(props) {
  const { qnaId } = useParams();
  const [qna, setQna] = useState({});
  const location = useLocation();
  const {category, currentPage } = location.state || {};
  const navigate = useNavigate();

  useEffect(()=>{
    call("/api/v1/admin/question/"+qnaId, 'GET')
    .then(response=>{
      setQna(response);
    })
  }, []);

  useEffect(()=>{

  }, [qna]);

  // 에디터 내용 가져오는 함수
  const handleSubmit = () => {
    const data = {
      questionId: qnaId,
      questionAnswer: qna.questionAnswer
    }
    call('/api/v1/admin/question', 'POST', data)
    .then(response => {
      alert(response.message);
      const param = category !== "" ? `category=${category}&page=${currentPage}` :  `page=${currentPage}`;
      navigate('/admin/withfirstzone/qnalist?'+param);
    })
    .catch(error=>{
      alert(error.message);
    })
  };

  return (
    <div className="app">
      <div className="content-box">
        <div className="pageName">With 퍼스트존 {" > "} 문의사항</div>
        <div className="main-content">
          <div className="title">문의사항 답변하기</div>
          <div className="subtitle">답변은 이메일을 통해 보내집니다.</div>
          <div className="info-zone">
            <div className="user-info">
              <div className="info">이름</div>
              <div className="info">{qna.questionName}</div>
            </div>
            <div className="user-info">
              <div className="info">전화번호</div>
              <div className="info">{qna.questionPhoneNumber}</div>
            </div>
            <div className="user-info">
              <div className="info">이메일주소</div>
              <div className="info">{qna.questionEmail}</div>
            </div>
          </div>
          <hr />
          <div className="text-zone">
            <div className="content-header">문의사항</div>
            <textarea
              className="textbox"
              placeholder="문구를 작성해주세요."
              value={qna.questionContent}
              disabled
              style={{ resize: "none" }}
            ></textarea>
          </div>
          <div className="text-zone">
            <div className="content-header">답변하기</div>
            <textarea
              className="textbox"
              placeholder="문구를 작성해주세요."
              value={qna.questionAnswer}
              style={{ resize: "none" }}
              onChange={(e) => setQna({ ...qna, questionAnswer: e.target.value })}
            ></textarea>
          </div>
          <div className="button-container">
            <button type="submit" className="submitBtn" onClick={handleSubmit}>
              제출
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QnaAnswer;
