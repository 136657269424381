import React from "react";
import "user/main/StoryComponent.css";
import { useNavigate } from "react-router-dom";
import MobileStoryComponent from "./swiper/MobileStoryComponent";
import { marked } from "marked";

marked.use({
  renderer: {
    image() {
      return ""; // 이미지 태그 비활성화
    },
    br() {
      return ""; // <br> 태그를 제거하여 줄바꿈을 무시
    },
  },
});

function StoryComponent({
  storyImage,
  storyText,
  mainITech,
  mainVideoStory,
  mainBlog,
}) {
  const navi = useNavigate();

  const handleMoreStory = () => {
    navi("/story");
  };
  const handleMoveEyeTech = () => {
    navi(`/content/${mainITech.eyetechId}?menu=itech`);
  };
  const handleMoveVideoStory = () => {
    navi(`/content/${mainVideoStory.videoStoryId}?menu=videostory`, {
      state: mainVideoStory.videoStoryId,
    });
  };
  const handleMoveBlog = () => {
    navi(`/content/${mainBlog.blogId}?menu=blog`);
  };

  const cleanMarkdown = (content) => {
    if (!content) return ""; // 내용이 없으면 빈 문자열 반환

    // 줄바꿈(\r\n, \n)을 공백으로 대체
    let cleanedContent = content.replace(/(\r\n|\n|\r)/g, " ");

    // 마크다운 태그 제거
    cleanedContent = cleanedContent
      .replace(/(\*\*|__)(.*?)\1/g, "$2") // 굵은 글씨 제거
      .replace(/#{1,6} /g, "") // 헤딩 제거
      .replace(/!\[.*?\]\(.*?\)/g, "") // 이미지 태그 제거
      .replace(/\[.*?\]\(.*?\)/g, "") // 링크 태그 제거
      .replace(/`{1,3}.*?`{1,3}/g, "") // 코드 블럭 제거
      .replace(/(\|.*\|[\r\n]*)+/g, "") // 테이블 제거
      .replace(/[*_~]/g, "") //마크다운제거
      .replace(/\*/g, "") // '*' 제거
      .replace(/</g, "") // '<' 제거
      .replace(/>/g, "") // '>' 제거
      .replace(/\[/g, "") // '[' 제거
      .replace(/\]/g, "") // ']' 제거
      .trim(); // 앞뒤 공백 제거

    return cleanedContent;
  };

  const stripHtml = (content) => {
    if (!content) return "";
    const parser = new DOMParser();
    const doc = parser.parseFromString(marked(content), "text/html");
    return doc.body.innerText;
  };

  return (
    <div className="story-zone">
      {/* 웹화면 */}
      <div className="story-header"></div>
      <div className="story-content">
        <div
          className="story-intro"
          style={{
            backgroundImage: storyImage
              ? `url(/uploads/${storyImage})`
              : "url(/path/to/default/image.jpg)",
          }}
        >
          <div className="intro-text">{storyText}</div>
          <div className="more-story" onClick={handleMoreStory}>
            + 스토리 더보기
          </div>
        </div>
        <div className="story-post">
          <div className="post-content" onClick={handleMoveEyeTech}>
            <div className="post-txt">
              <img
                src={`/uploads/${mainITech.eyetechImage}`}
                alt="사진"
                className="post-img"
              />
              <div className="posting">
                <div className="post-title">{mainITech.eyetechTitle}</div>
                <div className="post">
                  {stripHtml(mainITech.eyetechContent)}
                </div>
              </div>
            </div>
            <div className="postDay">
              아이테크 |{" "}
              {mainITech?.eyetechCreateDate
                ? mainITech.eyetechCreateDate.slice(0, 10)
                : ""}
            </div>
          </div>
          <hr />
          <div
            className="post-content"
            onClick={handleMoveVideoStory}
            mainVideoStory={mainVideoStory}
          >
            <div className="post-txt">
              <img
                src={
                  mainVideoStory.videoStoryImage
                    ? `/uploads/${mainVideoStory.videoStoryImage}`
                    : `https://img.youtube.com/vi/${mainVideoStory.videoStoryYoutubeId}/mqdefault.jpg`
                }
                alt="미리보기"
                className="post-img"
              />
              <div className="posting">
                <div className="post-title">
                  {mainVideoStory.videoStoryTitle}
                </div>
                <div className="post">
                  {cleanMarkdown(mainVideoStory.videoStoryContent)}
                </div>
              </div>
            </div>
            <div className="postDay">
              영상스토리 |{" "}
              {mainVideoStory.videoStoryCreateDate
                ? mainVideoStory.videoStoryCreateDate.slice(0, 10)
                : ""}
            </div>
          </div>
          <hr />
          <div className="post-content" onClick={handleMoveBlog}>
            <div className="post-txt">
              <img
                src={`/uploads/${mainBlog.blogImage}`}
                alt="사진"
                className="post-img"
              />
              <div className="posting">
                <div className="post-title">{mainBlog.blogTitle}</div>
                <div className="post">{stripHtml(mainBlog.blogContent)}</div>
              </div>
            </div>
            <div className="postDay">
              블로그 |{" "}
              {mainBlog.blogCreationDate
                ? mainBlog.blogCreationDate.slice(0, 10)
                : ""}
            </div>
          </div>
        </div>
      </div>
      {/* 모바일화면 */}
      <div className="mobile-story-zone">
        <div className="mobile-story-header">
          <div className="mobile-story-title">Story</div>
        </div>
        <MobileStoryComponent
          mainITech={mainITech}
          mainVideoStory={mainVideoStory}
          mainBlog={mainBlog}
        />
      </div>
    </div>
  );
}

export default StoryComponent;
