import React from 'react';
import { useNavigate } from 'react-router-dom';
import "user/commons/ErrorPage.css";
import Header from 'user/header/Header';

function ErrorPage404() {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/');  // 메인 페이지로 돌아가기
  };

  return (
    <div className="error-page-container">
      <Header></Header>
      <div className="error-page-content">
        <p className='error-page-error-name'>404</p>
        <p className="error-page-title">페이지를 찾을 수 없습니다.</p>
        <p className="error-page-message">해당 URL은 존재하지 않습니다. 다른 페이지로 이동해주세요.</p>
        <button className="error-page-button" onClick={goToHomePage}>메인으로 돌아가기</button>
      </div>
    </div>
  );
}

export default ErrorPage404;
