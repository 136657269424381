import React from 'react';
import { useNavigate } from 'react-router-dom';
import "user/commons/ErrorPage.css";
import Header from 'user/header/Header';

function ErrorPageApi() {
  const navigate = useNavigate();  // 페이지 이동을 위한 hook

  const goToHomePage = () => {
    navigate('/');  // 메인 페이지로 이동
  };

  return (
    <div className="error-page-container">
      <Header></Header>
      <div className="error-page-content">
        <p className="error-page-title">오류가 발생했습니다.</p>
        <p className="error-page-message">잠시 후 다시 시도해주세요.</p>
        <button className="error-page-button" onClick={goToHomePage}>홈으로 돌아가기</button>
      </div>
    </div>
  );
}

export default ErrorPageApi;
