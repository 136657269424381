import React from 'react';
import Header from 'user/header/Header';
import Footer from 'user/footer/Footer';
import "user/PageLayout.css"

function PageLayout({ children, isScrolled, isMain, setIsError }) {
    return (
        <div className="page-layout">
            <Header isScrolled={isScrolled} isMain={isMain}/>
            <div className="page-content">
                {children}
            </div>
            <Footer setIsError={setIsError}/>
        </div>
    );
}
export default PageLayout;