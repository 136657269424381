import { useState, forwardRef, useEffect } from "react";
import AddressSearch from "user/commons/AddressSearch";
import DatePicker from "react-datepicker";
import { ko } from 'date-fns/locale';
import { useMember } from "user/register/RegisterPage";
import "react-datepicker/dist/react-datepicker.css";
import "user/register/component/InputBox.css";

const InputBox = forwardRef(({ inputLabel, inputName, inputId, type, isRequired, handleChange, value, list, half,errorMsg }, ref) => {
    const { userInfo, setUserInfo } = useMember();
    const [address, setAddress] = useState('');
    const [emailId, setEmailId] = useState('');
    const [selectedEmail, setSelectedEmail] = useState('');
    const [isCustomEmailDomain, setIsCustomEmailDomain] = useState(true);
    const [selectedEducation, setSelectedEducation] = useState('선택');
    const [detailAddress, setDetailAddress] = useState('');
    const regExp = /[~!@#$%";'^,&*()_+|</>=>`?:{[}]/g;

    // 이름 입력 필터링(특수문자, 숫자 제한)
    const handleInputNameChange = (e) => {
        const value = e.target.value.replace(regExp, '').replace(/[0-9]/gi, '');
        e.target.value = value;
        handleChange(e);

    };

    const handleEducationChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedEducation(selectedValue);
        handleChange(e);
    };

    const handlePhoneInput = (e) => {
        let formattedNumber = e.target.value
            .replace(/[^0-9]/g, "")
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/, "$1-$2-$3")
            .replace(/(-{1,2})$/, "");
        e.target.value = formattedNumber;
        handleChange(e);
    };

    const handleChangeEmailDomain = (e) => {
        const newDomain = e.target.value;
        setSelectedEmail(newDomain);
        setUserInfo({ ...userInfo, [inputName]: `${emailId}@${newDomain}` });
    };

    const handleSelectChange = (e) => {
        const selectedDomain = e.target.value;
        setIsCustomEmailDomain(selectedDomain === "직접 입력");
        setSelectedEmail(selectedDomain === "직접 입력" ? '' : selectedDomain);
    
        if (selectedDomain !== "직접 입력") {
            setUserInfo({ ...userInfo, [inputName]: `${emailId}@${selectedDomain}` });
        } else {
            setUserInfo({ ...userInfo, [inputName]: `${emailId}@` });
        }
    };

    const handleChangeEmail = (e) => {
        const newEmailId = e.target.value;
        setEmailId(newEmailId);
        setUserInfo({ ...userInfo, [inputName]: `${newEmailId}@${selectedEmail}` });
    };

    const handleGetFullAddress = (e) => {
        const newDetailAddress = e.target.value;
        setDetailAddress(newDetailAddress);
        
        const fullAddress = address && newDetailAddress 
            ? `${address} ${newDetailAddress}`.trim() 
            : null;
        
        setUserInfo({ 
            ...userInfo, 
            [inputName]: fullAddress 
        });
    };

    const getInputType = (inputType) => {
        switch (inputType) {
            // case "text":
            //     return (
            //         <div className='input-text'>
            //             <input
            //                 name={inputName}
            //                 required={isRequired}
            //                 type="text"
            //                 value={value}
            //                 readOnly
            //                 ref={ref}
            //             />
            //         </div>
            //     );
            case "date":
                return (
                    <div ref={ref}>
                        <DatePicker
                            id="date-input"
                            name={inputName}
                            selected={userInfo[inputName] ? new Date(userInfo[inputName]) : null}
                            onChange={(date) => {
                                const event = {
                                    target: {
                                        name: inputName,
                                        value: date ? date.toISOString().split('T')[0] : ''
                                    }
                                };
                                handleChange(event);
                            }}
                            required={isRequired}
                            maxDate={new Date()}
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="연도.월.일"
                            className="date-picker-input"
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={50}
                            autoComplete="off"
                            onFocus={(e) => e.target.blur()}
                        />
                    </div>
                );
            case "radio":
                return (
                    <div className='input-radio-content'>
                        <input
                            id={`${inputId}-male`}
                            name={inputName}
                            onChange={handleChange}
                            required={isRequired}
                            type="radio"
                            value="남성"
                            ref={ref}
                        />
                        <label htmlFor={`${inputId}-male`} className='label-radio'>남</label>
                        
                        <input
                            id={`${inputId}-female`}
                            name={inputName}
                            onChange={handleChange}
                            required={isRequired}
                            type="radio"
                            value="여성"
                            ref={ref}
                        />
                        <label htmlFor={`${inputId}-female`} className='label-radio'>여</label>
                    </div>
                );
            case "tel":
                return (
                    <>
                        <input
                            id={inputId}
                            name={inputName}
                            onChange={handlePhoneInput}
                            required={isRequired}
                            type="text"
                            maxLength={13}
                            ref={ref}
                        />
                    </>
                );
            case "graduation":
                return (
                    <>
                        <select 
                            name={inputName} 
                            onChange={handleEducationChange} 
                            required={isRequired}
                            className="input-graduation"
                            ref={ref}>
                            <option value="">선택</option>
                            {list.map((education, index) => (
                                <option key={index} value={education.categoryDetailName}>
                                    {education.categoryDetailName}
                                </option>
                            ))}
                        </select>
                        {selectedEducation !== "초대졸" && selectedEducation !== "선택" && (
                            <>
                                <input
                                    id={inputId}
                                    name="employSchoolName"
                                    onChange={handleChange}
                                    required={isRequired}
                                    type="text"
                                    placeholder="학교명"
                                    className="input-graduation"
                                />
                                <input
                                    id={inputId}
                                    name="employMajor"
                                    onChange={handleChange}
                                    required={isRequired}
                                    type="text"
                                    placeholder="전공"
                                    className="input-graduation"
                                />
                                <p className="graduation-date">졸업년월</p>
                                <DatePicker
                                    id="graduation-date-input"
                                    name="employGraduationDate"
                                    selected={userInfo.employGraduationDate ? new Date(userInfo.employGraduationDate) : null}
                                    onChange={(date) => {
                                        const event = {
                                            target: {
                                                name: "employGraduationDate",
                                                value: date ? date.toISOString().split('T')[0] : ''
                                            }
                                        };
                                        handleChange(event);
                                    }}
                                    locale={ko}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="연도.월.일"
                                    className="date-picker-input"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={50}
                                    autoComplete="off"
                                />
                            </>
                        )}
                    </>
                );
            case "address":
                return (
                    <>
                        <AddressSearch setAddress={setAddress} />
                        <input
                            id={inputId}
                            name={inputName}
                            value={address}
                            required={isRequired}
                            type="text"
                            placeholder="주소"
                            readOnly
                            className="input-address"
                            ref={ref}
                        />
                        <input
                            id={inputId}
                            name={inputName}
                            value={detailAddress}
                            onChange={handleGetFullAddress}
                            required={isRequired}
                            type="text"
                            placeholder="상세 주소"
                            className="input-address"
                        />
                    </>
                );
            case 'email':
                return (
                    <div className='input-email-content'>
                        <input
                            id="email-id-input"
                            name={`${inputName}-id`}
                            required={isRequired}
                            type="text"
                            value={emailId}
                            onChange={handleChangeEmail}
                            className="input-email"
                            ref={ref}
                        />
                        <p className="at-sign">@</p>
                        <input
                            id="email-domain-input"
                            name={`${inputName}-domain`}
                            value={selectedEmail}
                            onChange={handleChangeEmailDomain}
                            readOnly={!isCustomEmailDomain}
                            type="text"
                            className="input-email"
                        />
                        <select 
                            className="select-email" 
                            name={`${inputName}-select`} 
                            value={selectedEmail || "직접 입력"} 
                            onChange={handleSelectChange}
                        >
                            <option value="직접 입력">직접 입력</option>
                            {list.map((email, index) => (
                                <option key={index} value={email.categoryDetailName}>
                                    {email.categoryDetailName}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            case 'textarea':
                return (
                    <>
                        <textarea
                            name={inputName}
                            onChange={handleChange}
                            value={value}
                            className="textarea-input"
                            rows={5}
                            maxLength={500}
                        />
                    </>
                );
            case 'select':
                return <>
                            <select name={inputName} onChange={handleChange} ref={ref}>
                                <option value="">선택</option>
                                {list.map((reason,index)=>(
                                    <option key={index} value={reason.categoryDetailName}>
                                        {reason.categoryDetailName}
                                    </option>
                                ))}
                            </select>
                        </>
            default:
                return (
                    <>
                        <input
                            id={inputId}
                            name={inputName}
                            onChange={inputName === 'jobName' || inputName === "employName" ? handleInputNameChange : handleChange}
                            required={isRequired}
                            type={type}
                            ref={ref}
                        />
                    </>
                );
        }
    };

    useEffect(() => {
        if (type === 'address') {
            const fullAddress = address && detailAddress 
                ? `${address} ${detailAddress}`.trim() 
                : null;
                
            setUserInfo(prev => ({
                ...prev,
                [inputName]: fullAddress
            }));
        }
    }, [address, detailAddress]);

    return (
        <div className={`info-content ${half ? 'half' : ''}`}>
            <label htmlFor={inputId}>
                {inputLabel}
                {isRequired ? <span className='star'>*</span> : ""}
            </label> 
            <div className="input-content">
                {getInputType(type)}
                <p className="errorMsg">{errorMsg}</p>
            </div>
            
           
        </div>
    );
});

export default InputBox;
