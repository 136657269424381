export const extractImageNames = (data) => {
    if (!data) return [];
    const regex = /\/uploads\/([^"\s)]+)/g;  // /uploads/ 뒤에 오는 파일명 추출
    const matches = [];
    let match;

    while ((match = regex.exec(data)) !== null) {
        matches.push(match[1]);  // 첫 번째 캡처 그룹(파일명)만 추가
    }

    return matches;
};

