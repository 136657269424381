import React, { useEffect, useState } from 'react';
import { call } from 'service/ApiService';
import "user/story/commons/DetailCategory.css";

function DetailCategoryStory({ selectedMenu, setSelectedCategory, selectedCategory, setCategoryDetails, categoryName, setIsError }) {
    const [categoryDetailsState, setCategoryDetailsState] = useState([]); // 두 번째 API 호출 결과를 저장할 상태 변수
    const [isLoading, setIsLoading] = useState(true);  // 로딩 상태 추가

    const renderCategories = () => {
        if (isLoading) {
            return <span>카테고리 로딩 중...</span>;
        }
        
        if (categoryDetailsState.length === 0) {
            return <span>카테고리 없음</span>;
        }

        return (
            <>
                <span
                    className={selectedCategory === '전체' ? 'active-category' : ''}
                    onClick={() => setSelectedCategory('전체')}
                >
                    전체
                </span>

                {categoryDetailsState.map((category) => (
                    <span
                        key={category.categoryDetailId}
                        className={selectedCategory === category.categoryDetailName ? 'active-category' : ''}
                        onClick={() => setSelectedCategory(category.categoryDetailName)}
                    >
                        {category.categoryDetailName}
                    </span>
                ))}
            </>
        );
    };

    // 선택된 categoryName으로 API 호출
    useEffect(() => {
        setIsLoading(true);  // API 호출 전 로딩 시작
            call(`/api/v1/category/select?categoryName=${categoryName}`, 'GET').then((response) => {
                // console.log('API 호출 응답:', response);
                setCategoryDetailsState(response);

                if (typeof setCategoryDetails === 'function') {
                    setCategoryDetails(response);
                } else {
                    // console.log('setCategoryDetails가 함수가 아닙니다.');
                }
                setIsLoading(false);  // API 호출 후 로딩 종료
            }).catch((error) => {
                // console.error('카테고리 조회 실패:', error.message);
                // alert('카테고리 조회에 실패했습니다.');
                setIsLoading(false);  // 오류 발생 시 로딩 종료
                // 오류 발생 시 에러 여부 값 true됨
                // setIsError(true);
                
            });
    }, [categoryName]);
    
    return (
        <div className='detailcategory-container'>
            <div className={selectedMenu === 'notice' || selectedMenu === 'faq' ? 'w-detailcategory-list-container' : 'detailcategory-list-container'}>
                {renderCategories()}
            </div>
        </div>
    );
}

export default DetailCategoryStory;